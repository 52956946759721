import { ApiResponse } from "../../types/api";
import { School } from "../../types/school";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";

export const schoolClient = () => Axios(`${getApiUrl()}/school`);

export async function createNewSchool(values: School) {
  return await Axios(`${getApiUrl()}/school`).post("/create", values);
}

export async function updateSchool(values: School) {
  return await schoolClient().put("/update", values);
}

export async function deleteSchool(values: School) {
  return await schoolClient().delete("/delete", {
    params: { school_id: values.school_id },
  });
}

export async function fetchSchools() {
  return schoolClient().get<ApiResponse<School[]>>("/get/all");
}

export async function fetchSchoolsByCountry(country: string) {
  return schoolClient().get<ApiResponse<School[]>>(`${country}/get/all`);
}
