import { useAppSelector } from "../store/hooks";
import { selectSchools } from "../store/selectors/schoolSelectors";
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Toolbar,
} from "@mui/material";
import styles from "./../styles/Form.module.scss";
import { TableBar } from "./styled/AppBar";
import { ErrorMessage, Field, Formik } from "formik";
import { yupValidationBSG } from "../utils/yupValidation";
import { StyledButton } from "./styled/Button";
import { StyledTextField } from "./styled/TextField";
import { Person } from "@mui/icons-material";
import { StyledFormControl, menuStyles } from "./styled/StyledFormSelect";
import { School } from "../types/school";
import { generateStudents } from "../services/api/users.api";
import { getTitle } from "../utils/getSchoolName";
import AlertDialog from "./Alert";
import { handleApiError } from "../utils/handleApiError";
import { useState } from "react";

const GenerateStudentsForm = ({
  handleClose,
}: {
  handleClose?: () => void;
}) => {
  const schools = useAppSelector(selectSchools);
  const [error, setError] = useState<string | undefined>();

  const createCsv = (usernames: string[], school: string) => {
    let csvContent = "usernames,passwords\n";

    usernames.forEach((username) => {
      csvContent += `${username},${username}\n`;
    });

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "students_" + getTitle(schools, school) + ".csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Container className={styles.formContainer} maxWidth='lg'>
      <Paper elevation={2} className={styles.paper} sx={{ overflow: "hidden" }}>
        <TableBar position='relative'>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>{`Generate Bulk Students`}</h2>
          </Toolbar>
        </TableBar>
        <Box className={styles.box}>
          <Formik
            initialValues={{
              numOfStudents: 5,
              school: schools[0].school_id!,
            }}
            onSubmit={(values, actions) => {
              // values = { ...values, schools: [values.schools] };
              console.log(values);
              generateStudents(values.school, values.numOfStudents)
                .then((res) => {
                  if (res.status === 200) {
                    const usernames = res.data.data[0]["Created locally"];
                    console.log(usernames);
                    createCsv(usernames, values.school);
                    handleClose && handleClose();
                  }
                })
                .catch((e) => {
                  handleApiError(e, setError);
                });
            }}
            validationSchema={yupValidationBSG}
          >
            {({
              values,
              errors,
              touched,
              setValues,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className={styles.form}
                onSubmit={handleSubmit}
                autoComplete='off'
              >
                <Grid container rowSpacing={1.5} columnSpacing={3}>
                  <Grid container item xs={12}>
                    <Field
                      as={StyledTextField}
                      required
                      autoComplete='off'
                      id={"numOfStudents"}
                      name={"numOfStudents"}
                      label={"Choose the number of students to generate"}
                      fullWidth
                      margin='dense'
                      variant='filled'
                      helperText={<ErrorMessage name={"numOfStudents"} />}
                      error={
                        errors["numOfStudents"] && touched["numOfStudents"]
                      }
                      // inputProps={{ maxLength: 8, minLength: 8 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <StyledFormControl variant='filled' sx={{ width: "100%" }}>
                      <InputLabel id='school'>School</InputLabel>
                      <Field
                        as={Select}
                        labelId='shool'
                        id='school'
                        name='school'
                        value={values.school}
                        onChange={(e: { target: { value: string } }) => {
                          handleChange(e);
                        }}
                        renderValue={(selected: string) =>
                          getTitle(schools, selected)
                        }
                        MenuProps={menuStyles}
                      >
                        {schools.map((school, index) => {
                          return (
                            <MenuItem key={index} value={school.school_id!}>
                              <ListItemText primary={school.title} />
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </StyledFormControl>
                  </Grid>
                  <Grid container item xs={12} justifyContent={"center"}>
                    <StyledButton
                      className={styles.button}
                      variant='contained'
                      type='submit'
                    >
                      Generate
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default GenerateStudentsForm;
