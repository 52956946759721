import { School } from "./school";
import { TableProps } from "./table";

export interface ChartConfig {
  type: string;
  title: string;
  data: any;
}

type ToolbarCallback = (arg: any) => void;

export interface ToolbarConfig {
  datepicker: boolean;
  datepickerConfig?:{
    maxInterval?: 30, 
  } 
  country: boolean;
  countryConfig?: {
    countries: string[];
    required: boolean;
  };
  schools?: School[],
  queryCB: ToolbarCallback;
}

export type TableWidget<T, K extends keyof T> = {
  toolbarConfig: ToolbarConfig;
  tableConfig: TableProps<T, K>;
};

export type ChartWidget = {
  toolbarConfig: ToolbarConfig;
  chartConfig: ChartConfig;
};

type WidgetType<T, K extends keyof T> = ChartWidget | TableWidget<T, K>;

export function isChart(widget: WidgetType<any, any>): widget is ChartWidget {
  return (widget as ChartWidget).chartConfig !== undefined;
}

