import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s4q1 = {
  type: "radiogroup",
  name: "q1s4q1",
  title: {
    default:
      "Has your child ever had an itchy rash which was coming and going for at least 6 months?",
    gr: "Είχε ποτέ το παιδί σας εξάνθημα με φαγούρα που να έρχεται και να φεύγει, για τουλάχιστον 6 μήνες;",
    fr: "Votre enfant a-t-il/elle déjà eu une éruption cutanée avec démangeaisons qui allait et venait pendant au moins 6 mois ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ქავილით მიმდინარე გამონაყარი, რომელიც პერიოდულად ჩნდებოდა და გაივლიდა ხოლმე სულ მცირე 6 თვის განმავლობაში?",
    fi: "Onko lapsellanne koskaan ollut kutisevaa ihottumaa, joka on tullut ja mennyt vähintään 6 kk ajan? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q2 = {
  type: "radiogroup",
  name: "q1s4q2",
  visibleIf: "{q1s4q1} == 'yes' ",
  title: {
    default:
      "Has your child had this itchy rash at any time in the past 12 months?",
    gr: "Είχε ποτέ το παιδί σας αυτό το εξάνθημα με φαγούρα τους τελευταίους 12 μήνες;",
    fr: "Votre enfant a-t-il/elle déjà eu cette éruption cutanée avec démangeaisons au cours des 12 derniers mois ? ",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ქავილით მიმდინარე გამონაყარი ბოლო 12 თვის განმავლობაში?",
    fi: "Onko lapsellanne ollut tätä kutisevaa ihottumaa jossain kohtaa viimeisen 12 kk aikana? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q3 = {
  type: "radiogroup",
  name: "q1s4q3",
  visibleIf: "{q1s4q1} == 'yes' and {q1s4q2} == 'yes' ",
  title: {
    default:
      "Has this itchy rash at any time affected any of the following places: the folds of the elbows, behind the knees, in front of the ankles, under the buttocks, or around the neck, ears or eyes?",
    gr: "Εμφανίστηκε ποτέ αυτό το εξάνθημα με φαγούρα σε κάποια από τα παρακάτω σημεία: στους αγκώνες, πίσω από τα γόνατα, στους αστραγάλους, κάτω από τους γλουτούς, γύρω από το λαιμό, τα αυτιά ή τα μάτια;",
    fr: "Les démangeaisons ont-elles déjà affecté l’un des endroits suivants : les plis des coudes, derrière les genoux, devant les chevilles, sous les fesses, autour du cou, des oreilles ou des yeux ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ქავილით მიმდინარე გამონაყარი ქვემოთ ჩამოთვლილ რომელიმე უბანში: იდაყვის სახსრის ნაკეცებში, მუხლის უკან, კოჭის წინა ზედაპირზე, დუნდულოების ქვეშ ან კისრის, ყურების ან თვალების გარშემო?",
    fi: "Onko tämä kutiava ihottuma milloinkaan vaikuttanut johonkin seuraavista paikoista: kyynärpäät, polvien takana, nilkkojen edessä, pakaroiden alla tai kaulan, korvien tai silmien ympärillä? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s4q4 = {
  type: "radiogroup",
  name: "q1s4q4",
  visibleIf: "{q1s4q1} == 'yes' and {q1s4q2} == 'yes' ",
  title: {
    default:
      "At what age did this itchy rash first occur?",
    gr: "Σε ποια ηλικία πρωτοεμφανίστηκε αυτό το εξάνθημα με τη φαγούρα; ",
    fr: "A quel âge ces démangeaisons sont-elles apparues ? ",
    de: "",
    ka: "რა ასაკში გაჩნდა პირველად გამონაყარი? ",
    fi: "Minkä ikäisenä tämä kutiava ihottuma esiintyi ensimmäistä kertaa?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "<2",
      text: {
        default: "Under 2 years",
        gr: "Κάτω των 2 ετών",
        fr: "Avant l’âge de 2 ans",
        de: "",
        ka: "2 წლამდე",
        fi: "Alle 2 v",
      },
    },
    {
      value: "2-4",
      text: {
        default: "Age 2-4 years",
        gr: "2-4 ετών",
        fr: "À l’âge de 2-4 ans",
        de: "",
        ka: "2-4 წლის ასაკში",
        fi: "2-4 v",
      },
    },
    {
      value: ">5",
      text: {
        default: "Age 5 or more ",
        gr: "5 ετών και άνω",
        fr: "Après l’âge de 5 ans",
        de: "",
        ka: "5 წლის ასაკში ან უფრო გვიან",
        fi: "5 v tai vanhempi",
      },
    },
  ],
};

const s4q5 = {
  type: "radiogroup",
  name: "q1s4q5",
  visibleIf: "{q1s4q1} == 'yes' and {q1s4q2} == 'yes' ",
  title: {
    default:
      "Has this rash cleared completely at any time during the past 12 months?",
    gr: "Υπάρχει κάποιο διάστημα κατά τους τελευταίους 12 μήνες κατά το οποίο το εξάνθημα εξαφανίστηκε εντελώς;",
    fr: "Cette éruption a-t-elle complètement disparu à un moment quelconque au cours des 12 derniers mois ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში ალაგებულა თუ არა ეს გამონაყარი მთლიანად?",
    fi: "Onko tämä ihottuma hävinnyt kokonaan milloinkaan viimeisen 12 kk aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};


const s4q6 = {
  type: "radiogroup",
  name: "q1s4q6",
  visibleIf: "{q1s4q1} == 'yes' and {q1s4q2} == 'yes' ",
  title: {
    default:
      "In the past 12 months, how often, on average, has your child been kept awake at night by this itchy rash?",
    gr: "Τους τελευταίους 12 μήνες, πόσο συχνά, κατά μέσο όρο, έμεινε ξύπνιο το παιδί σας τη νύχτα εξαιτίας της φαγούρας από το εξάνθημα",
    fr: "Au cours des 12 derniers mois, combien de fois, en moyenne, votre enfant a-t-il/elle été empêché de dormir à cause de ces démangeaisons ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში საშუალოდ რა სიხშირით იღვიძებდა ღამით თქვენი ბავშვი ქავილით მიმდინარე გამონაყარის გამო?",
    fi: "Kuinka usein keskimäärin viimeisen 12 kk aikana tämä kutiava ihottuma on valvottanut lastanne yöllä?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never in the past 12 months",
        gr: "Ποτέ τους τελευταίους 12 μήνες",
        fr: "Jamais au cours des 12 derniers mois",
        de: "",
        ka: "არც ერთხელ ბოლო 12 თვეში",
        fi: "Ei koskaan viimeisen 12 kk aikana",
      },
    },
    {
      value: "less_than_one_pw",
      text: {
        default: "Less than one night per week",
        gr: "Λιγότερο από μία νύχτα την εβδομάδα",
        fr: "Moins d’une nuit par semaine",
        de: "",
        ka: "უფრო იშვიათად, ვიდრე კვირაში ერთხელ",
        fi: "Vähemmän kuin kerran viikossa",
      },
    },
    {
      value: "more_than_one_pw",
      text: {
        default: "One or more nights per week",
        gr: "Μία ή περισσότερες νύχτες την εβδομάδα",
        fr: "Une ou plusieurs nuits par semaine",
        de: "",
        ka: "კვირაში ერთ ღამეს ან უფრო ხშირად",
        fi: "Kerran viikossa tai useammin",
      },
    },
  ],
};

const s4q7 = {
  type: "radiogroup",
  name: "q1s4q7",
  title: {
    default:
      "Has your child ever had eczema?",
    gr: "Είχε ποτέ το παιδί σας έκζεμα;",
    fr: "Votre enfant a-t-il/elle déjà souffert d’eczéma?",
    de: "",
    ka: "ჰქონია თუა არა ოდესმე თქვენს ბავშვს ეგზემა?",
    fi: "Onko lapsellanne koskaan ollut atooppista ihottumaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};


export const elementsS4 = [s4q1, s4q2, s4q3, s4q4, s4q5, s4q6, s4q7];
