import { Permissions } from "../types/permissions";
import { Role } from "../types/user";

const all: Role[] = ["inlecom", "admin", "operator", "teacher", "student"];

export const permissions: Permissions = {
  pages: {
    login: {
      access: all,
      create: [],
      update: [],
      delete: [],
    },
    dashboard: {
      access: ["inlecom", "admin", "operator", "teacher"],
      create: [],
      update: [],
      delete: [],
    },
    users: {
      access: ["inlecom", "admin"],
      create: ["inlecom", "admin"],
      update: ["inlecom", "admin"],
      delete: ["inlecom", "admin"],
    },
    players: {
      access: ["inlecom", "admin", "operator"],
      create: ["inlecom", "admin"],
      update: ["inlecom", "admin", "operator"],
      delete: ["inlecom", "admin", "operator"],
    },
    schools: {
      access: ["inlecom", "admin", "operator"],
      create: ["inlecom", "admin", "operator"],
      update: ["inlecom", "admin", "operator"],
      delete: ["inlecom", "admin", "operator"],
    },
    analytics: {
      access: ["inlecom", "admin", "operator"],
      create: [],
      update: [],
      delete: [],
    },
    questionnaires: {
      access: ["inlecom", "admin", "operator", "student"],
      create: ["student"],
      update: [],
      delete: [],
      export: ["inlecom", "admin", "operator"],
    },
    tutorials: {
      access: ["inlecom", "admin", "operator", "student"],
      create: [],
      update: [],
      delete: [],
    },
    generator: {
      access: ["inlecom", "admin", "student"],
      create: [],
      update: [],
      delete: [],
    },
  },
};

export const redirectPaths = {
  inlecom: "/",
  admin: "/",
  operator: "/",
  teacher: "/",
  student: "/dashboard/questionnaires",
};

// export const checkPermissions = (permissionPath: Role[], role: Role) => {
//   if(role && permissionPath.includes(role)){

//   }
// }
