import { PermissionControl } from "../../../components/Permission";
import SurveyHandler from "../../../components/surveyComponents/SurveyHandler";
import { getRole } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";
import { useParams } from "react-router-dom";

const QuestionnairesDraftPage = () => {
  const params = useParams();
  console.log(params);

  return (
    <PermissionControl
      permissionPath={permissions.pages.questionnaires.access}
      role={getRole()}
    >
      {params.id && params.student && (
        <SurveyHandler
          id={params.id}
          student={params.student}
          draft_id={params.draft_id}
        />
      )}
    </PermissionControl>
  );
};

export default QuestionnairesDraftPage;
