import { createAsyncThunk } from "@reduxjs/toolkit";
import { School } from "../../types/school";
import { SCHOOL_ACTIONS } from "../actions/schoolActions";
import { fetchSchools } from "../../services/api/schools.api";

export const getSchools = createAsyncThunk<School[]>(
  SCHOOL_ACTIONS.GET_SCHOOLS,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await fetchSchools();

      return responseData.data;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
