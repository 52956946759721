"use client";
import { useState, useEffect, SyntheticEvent, useCallback } from "react";
import SearchBar from "../../../components/SearchBar";
import { QuestionRow, Questionnaire } from "../../../types/questionnaire";
import { Column } from "../../../types/table";
import { TableBar } from "../../../components/styled/AppBar";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Container,
  debounce,
  IconButton,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTable from "../../../components/Table/CustomTable";
import {
  fetchApiData,
  fetchExportedData,
} from "../../../services/api/questionnaires.api";
import { StyledButton } from "../../../components/styled/Button";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { getUsers } from "../../../store/effects/userEffects";
import { selectStudents } from "../../../store/selectors/userSelector";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import Loading from "../../../components/Loading";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import DownloadIcon from "@mui/icons-material/Download";
import StudentExportForm from "../../../components/Forms/StudentExportForm";
import { Search, Clear } from "@mui/icons-material";
import { StyledTextField } from "../../../components/styled/TextField";
import { findStudentsByUsername } from "../../../services/api/users.api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Student } from "../../../types/user";
import ReplayIcon from "@mui/icons-material/Replay";

type QuestionnaireTable = Questionnaire & {
  code: string | null;
};

const DashboardPage = () => {
  const dispatch = useAppThunkDispatch();
  const [isLoading, setLoading] = useState(false);
  const students = useAppSelector(selectStudents);
  const [student, setStudent] = useState<Student | null>(null);
  const [rows, setRows] = useState<QuestionnaireTable[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rpp, setRpp] = useState(5);
  const [totalRows, setTotalRows] = useState<number | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  // const [data, setData] = useState<Questionnaire[]>([]);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchData(currentPage, rpp);
  }, []);

  const handlePaginationOptions = (page: number, rpp: number) => {
    setCurrentPage(page);
    setRpp(rpp);
    console.log(page, rpp);
    fetchData(page, rpp);
  };

  const fetchData = (page: number, rpp: number, student?: string) => {
    console.log("Here ", student);
    setLoading(true);
    fetchApiData(rpp, page, student)
      .then((res) => {
        console.log(res.data.data.questionnaires);
        setRows(ProcessRows(res.data.data.questionnaires));
        setTotalRows(res.data.data.total_count);
      })
      .catch((e) => {
        handleApiError(e, setError);
      })
      .finally(() => setLoading(false));
  };

  const ProcessRows = (data: Questionnaire[]): QuestionnaireTable[] => {
    return data.map((row) => {
      row.last_updated = new Date(
        parseFloat(row.last_updated) * 1000
      ).toLocaleString();

      const code =
        students.find((student) => student._id === row.student)?.offline_code ??
        null;

      const rows: QuestionRow[] = [];

      row.questions.map((question) => {
        let translation = " ";

        const search = question.translations.find(
          (translation) => translation.language === "EN"
        )?.text;

        if (search !== undefined) {
          translation = search;
        }

        let choice = " ";
        if (question.choices !== null) {
          const search = question.choices
            .find((choice) => choice.is_selected === true)
            ?.translations.find(
              (translation) => translation.language === "EN"
            )?.text;
          if (search !== undefined) {
            choice = search;
          }
        } else {
          if (question.value !== null && question.value !== undefined) {
            choice = question.value;
          }
        }

        rows.push({ translation: translation, choice: choice });
      });

      const columns: Column<QuestionRow, keyof QuestionRow>[] = [
        { name: "Questions", key: "translation" },
        { name: "Answers", key: "choice" },
      ];

      const toolbar = (): JSX.Element => {
        return (
          <Typography variant='h6' gutterBottom component='div'>
            Q&A
          </Typography>
        );
      };

      const collapsible = { rows: rows, columns: columns, toolbar: toolbar };

      return { ...row, code, collapsible };
    });
  };

  const columns: Column<QuestionnaireTable, keyof QuestionnaireTable>[] = [
    { name: "USER", key: "student" },
    // { name: "QUESTIONNAIRE", key: "questionnaire_name" },
    // { name: "UNIQUE ID", key: "code" },
    { name: "TIME", key: "last_updated" },
  ];

  const handleStudentChange = (
    event: SyntheticEvent<Element, Event>,
    value: Student | null
  ) => {
    setStudent(value);
    if (value && (event.type === "click" || event.type === "keydown")) {
      fetchData(currentPage, rpp, value._id);
    }
  };

  const handleRevertClick = () => {
    setStudent(null);
    fetchData(currentPage, rpp);
  };

  const AnalyticsTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "10px", md: "20px" },
              marginRight: "10px",
            }}
          >
            <h2>{`Student Questionnaires`}</h2>
            <Autocomplete
              size='small'
              fullWidth
              sx={styles.autocomplete}
              options={students}
              value={student}
              getOptionLabel={(option) => option._id!}
              renderOption={(props, option) => (
                <li {...props} key={option._id!}>
                  {option._id!}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label='Filter by Student' />
              )}
              onChange={handleStudentChange}
            />
            {student && (
              <Tooltip title={`Show All Students`} placement='bottom'>
                <ReplayIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleRevertClick}
                />
              </Tooltip>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            {rows.length > 0 && (
              <Tooltip
                title={`Download Students' Questionnaires`}
                placement='bottom'
              >
                <StyledButton
                  sx={{ marginRight: "0px" }}
                  variant='contained'
                  onClick={handleOpen}
                >
                  <DownloadIcon />
                </StyledButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  // if (isLoading) return <Loading />;
  // console.log(isLoading);

  return (
    <Container max-width='xl'>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <div>
          <StudentExportForm modal={handleClose} />
        </div>
      </Modal>
      <PermissionControl
        permissionPath={permissions.pages.analytics.access}
        role={getRole()}
      >
        {/* <SearchBar students={students} fetchData={fetchData}/> */}
        {!isLoading ? (
          <CustomTable
            rows={rows}
            columns={columns}
            toolbar={AnalyticsTableToolbar}
            currentPage={currentPage}
            rpp={rpp}
            totalRows={totalRows}
            handlePaginationOptions={handlePaginationOptions}
          />
        ) : (
          <>
            {/* <Box marginTop={1}>{AnalyticsTableToolbar()}</Box> */}
            <Loading />
          </>
        )}
      </PermissionControl>
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                error && setError(undefined);
                alert && setAlert(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

const width = {
  xs: "150px",
  sm: "200px",
  md: "250px",
  lg: "250px",
  xl: "300px",
};

const styles = {
  autocomplete: {
    display: "flex",
    flexWrap: "nowrap",
    width: width,
    minWidth: "150px",
    maxWidth: "300px",
    "& .Mui-focused": {
      backgroundColor: "rgb(239, 239, 253)",
    },
  },
  chip: {
    maxWidth: "100px",
  },
};

export default DashboardPage;
