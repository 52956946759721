import { School } from "../../types/school";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectSchools = (state: RootState) => state.schools.schools;
export const selectSchoolsLoading = (state: RootState) => state.schools.loading;
export const selectActiveSchool = (state: RootState) => state.schools.activeSchool;

const selectSchoolById = createSelector(
  [(state) => state.schools, (state, id) => id],
  (schools, id) => schools.filter((school: School) => school.school_id === id)
);

