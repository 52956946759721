import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s5q1 = {
  type: "matrixdropdown",
  name: "q4s5q1",
  isRequired: isRequired,
  title: {
    default:
      "In the past 12 months, has your child ever had an illness or trouble caused by eating a food or foods or even a diagnosis of food allergy in the following food groups?",
    gr: "Τους τελευταίους 12 μήνες, είχε ποτέ το παιδί σας κάποια ασθένεια ή πρόβλημα που προκλήθηκε από την κατανάλωση κάποιας τροφής ή διάγνωση τροφικής αλλεργίας από τις παρακάτω ομάδες τροφών;",
    fr: "Au cours des 12 derniers mois, votre enfant a-t-il déjà été malade ou a eu un souci lié à la consommation d’un ou plusieurs aliments ou même un diagnostic d’allergie alimentaire dans les groupes d’aliments suivants ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში,, თქვენს შვილს  როდესმე თუ ჰქონია დაავადება ან სიმპტომი რომელიც გამოწვეული იყო საკვების მიღებით ან თუ აღენიშნება დიაგნოსტირებული კვებითი ალერგია ქვემოთ მოყვანილ  საკვების ჯგუფებზე?",
    fi: " Onko lapsellasi viimeisten 12 kuukauden aikana koskaan ollut ruoan tai elintarvikkeiden syömisen aiheuttamaa sairautta tai ongelmia tai ruoka-allergian diagnoosi seuraavissa ruokaryhmissä?",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    {
      name: "yes_or_no",
      title: {
        default: "Yes/No",
        gr: "Ναι/Όχι",
        fr: "Oui/Non",
        de: "",
        ka: "კი/არა",
        fi: "Kyllä/Ei",
      },
      choices: [yes, no],
      cellType: "radiogroup",
      colCount: 2,
      isRequired: isRequired,
    },
  ],
  rows: [
    {
      value: "milk",
      text: {
        default: "Milk",
        gr: "Γάλα",
        fr: "Lait",
        de: "",
        ka: "რძე",
        fi: "Maito",
      },
    },
    {
      value: "egg",
      text: {
        default: "Egg",
        gr: "Αυγό",
        fr: "Œuf",
        de: "",
        ka: "კვერცხი",
        fi: "Muna",
      },
    },
    {
      value: "wheat",
      text: {
        default: "Wheat",
        gr: "Σιτηρά",
        fr: "Blé",
        de: "",
        ka: "ხორბალი",
        fi: "Vehnä",
      },
    },
    {
      value: "soy",
      text: {
        default: "Soy",
        gr: "Σόγια",
        fr: "Soja",
        de: "",
        ka: "სოიო",
        fi: "Soija",
      },
    },
    {
      value: "fish",
      text: {
        default: "Fish",
        gr: "Ψάρι",
        fr: "Poissons",
        de: "",
        ka: "თევზი",
        fi: "Kala",
      },
    },
    {
      value: "tree_nuts",
      text: {
        default: "Tree nuts",
        gr: "Ξηροί καρποί",
        fr: "Fruits à coque",
        de: "",
        ka: "თხილეული",
        fi: "Pähkinät",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλο",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muu",
      },
    },
  ],
};

const s5q2base = {
  type: "checkbox",
  name: "q4s5q2",
  visibleIf:
    "{q4s5q1.milk.yes_or_no} == 'yes' or {q4s5q1.egg.yes_or_no} == 'yes' or {q4s5q1.wheat.yes_or_no} == 'yes' or {q4s5q1.soy.yes_or_no} == 'yes' or {q4s5q1.fish.yes_or_no} == 'yes' or {q4s5q1.tree_nuts.yes_or_no} == 'yes' or {q4s5q1.other.yes_or_no} == 'yes'",
  title: {
    default: "Did this illness or trouble include:",
    gr: "Αυτή η ασθένεια ή το πρόβλημα περιλάμβανε:",
    fr: "Ces symptômes ou soucis incluaient-ils:",
    de: "",
    ka: "მოიცავდა თუ არა საკვების მიღებასთან  დაკავშირებული პრობლემები  ქვემოთ მოყვანილ რომელიმე სიმპტომს ?",
    fi: "Sisältyikö tähän sairauteen tai vaivaan:",
  },
  description: selectMultiple,
  choices: [
    {
      text: {
        default:
          "Itching/Pruritus (hard continuous scratching leading to excoriations)",
        gr: "Φαγούρα/ Κνησμό (Έντονο συνεχόμενο ξύσιμο που οδηγεί σε πληγές)",
        fr: "Prurit (grattage dur et continu entraînant des excoriations)",
        de: "",
        ka: "ქავილი ( ძლიერი მუდმივი ქავილი ექსკორიაციების-ნაკაწრების წარმოქმნით)",
        fi: "Kutinaa (kova jatkuva raapiminen, joka johtaa kouristuksiin)",
      },
      value: "pruritus",
    },
    {
      text: {
        default: "Urticaria (more than 2 hives)",
        gr: "Κνίδωση-Εξάνθημα (πάνω από δύο βλάβες)",
        fr: "Urticaire (plus de 2 papules)",
        de: "",
        ka: "ურტიკარია (ჭინჭრის ციების 2-ზე მეტი ელემენტით)",
        fi: "Urtikaria (yli 2 nokkosihottumaa)",
      },
      value: "urticaria",
    },
    {
      text: {
        default: "Swelling on lip and/or face",
        gr: "Οίδημα-Πρήξιμο χειλέων ή/και προσώπου ",
        fr: "Gonflement des lèvres et/ou du visage",
        de: "",
        ka: "ტუჩების და/ან სახის შეშუპება",
        fi: "Huulten ja/tai kasvojen turvotus",
      },
      value: "swelling",
    },
    {
      text: {
        default: "Worsening of eczema",
        gr: "Επιδείνωση εκζέματος",
        fr: "Aggravation de l’eczéma",
        de: "",
        ka: "ეგზემის გამწვავება",
        fi: "Ihottuman paheneminen",
      },
      value: "worsening_eczema",
    },
    {
      text: {
        default: "Frequent sniffing and/or bursts of sneezing",
        gr: "Συχνό ρουθούνισμα ή/και εξάρσεις φτερνίσματος",
        fr: "Reniflements et/ou éternuements fréquents",
        de: "",
        ka: "ინტენსიური ყნოსვა/ცემინება ",
        fi: " Toistuva niistely ja/tai aivastelupurskeet",
      },
      value: "sniffing",
    },
    {
      text: {
        default: "Cough/Wheezing",
        gr: "Βήχα/Σφύριγμα-Βράσιμο στο στήθος",
        fr: "Toux/respiration sifflante",
        de: "",
        ka: "ხველა/ხიხინი",
        fi: "Yskä/hengityksen vinkuminen",
      },
      value: "cough_wheezing",
    },
    {
      text: {
        default: "Vomiting (Emesis)",
        gr: "Εμετός",
        fr: "Vomissements",
        de: "",
        ka: "ღებინება",
        fi: "Oksentelu (Emesis)",
      },
      value: "emesis",
    },
    {
      text: {
        default: "Diarrhea",
        gr: "Διάρροια",
        fr: "Diarrhée",
        de: "",
        ka: "დიარეა",
        fi: "Ripuli",
      },
      value: "diarrhea",
    },
    {
      text: {
        default:
          "Change in mental status (unexplained drowsiness or agitation)",
        gr: "Αλλαγή στη διανοητική κατάσταση (ανεξήγητη υπνηλία ή διέγερση)",
        fr: "Modification de l’état de conscience (somnolence ou agitation inexpliquée)",
        de: "",
        ka: "მენტალური სტატუსის ცვლილება  (აუხსნელი  მოთენთვა ან გაღიზიანება)",
        fi: "Psyykkisen tilan muutos (selittämätön uneliaisuus tai levottomuus)",
      },
      value: "change_mental_status",
    },
    {
      text: {
        default: "Loss of consciousness",
        gr: "Απώλεια συνείδησης",
        fr: "Perte de conscience",
        de: "",
        ka: "გონების დაკარგვა",
        fi: "Tajunnan menetys",
      },
      value: "loss_consciousness",
    },
  ],
  isRequired: isRequired,
  colCount: 1,
  showOtherItem: true,
  separateSpecialChoices: true,
};

const s5q2milk = {
  ...s5q2base,
  visibleIf: "{q4s5q1.milk.yes_or_no} == 'yes'",
  name: "s5q2milk",
};
const s5q2egg = {
  ...s5q2base,
  visibleIf: "{q4s5q1.egg.yes_or_no} == 'yes'",
  name: "s5q2milk",
};
const s5q2wheat = {
  ...s5q2base,
  visibleIf: "{q4s5q1.wheat.yes_or_no} == 'yes'",
  name: "s5q2egg",
};
const s5q2soy = {
  ...s5q2base,
  visibleIf: "{q4s5q1.soy.yes_or_no} == 'yes'",
  name: "s5q2soy",
};
const s5q2fish = {
  ...s5q2base,
  visibleIf: "{q4s5q1.fish.yes_or_no} == 'yes'",
  name: "s5q2fish",
};
const s5q2nuts = {
  ...s5q2base,
  visibleIf: "{q4s5q1.tree_nuts.yes_or_no} == 'yes'",
  name: "s5q2tree_nuts",
};
const s5q2other = {
  ...s5q2base,
  visibleIf: "{q4s5q1.other.yes_or_no} == 'yes'",
  name: "s5q2other",
};

const s5q3base = {
  type: "radiogroup",
  name: "q4s5q3",
  visibleIf:
    "{q4s5q1.milk.yes_or_no} == 'yes' or {q4s5q1.egg.yes_or_no} == 'yes' or {q4s5q1.wheat.yes_or_no} == 'yes' or {q4s5q1.soy.yes_or_no} == 'yes' or {q4s5q1.fish.yes_or_no} == 'yes' or {q4s5q1.tree_nuts.yes_or_no} == 'yes' or {q4s5q1.other.yes_or_no} == 'yes'",
  colCount: 3,
  title: {
    default: "How soon after eating did the symptoms occur?",
    gr: "Πόσο σύντομα αφού έφαγε την τροφή εμφανίστηκαν τα συμπτώματα;",
    fr: "Combien de temps après avoir mangé les symptômes sont-ils apparus ?",
    de: "",
    ka: "რამდენად სწრაფად განვითარდა სიმპტომები საკვების მიღების შემდეგ?",
    fi: "Kuinka pian syömisen jälkeen oireet ilmenivät?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "Only within 2 hours",
        gr: "Μόνο εντός 2 ωρών",
        fr: "Dans les 2 heures exclusivement",
        de: "",
        ka: "2 საათში",
        fi: "vain 2 tunnin sisällä",
      },
      value: "within_2h",
    },
    {
      text: {
        default: "Only after 2 hours",
        gr: "Μόνο μετά από 2 ώρες",
        fr: "Après 2 heures exclusivement",
        de: "",
        ka: "2 საათის შემდეგ",
        fi: "vasta 2 tunnin kuluttua",
      },
      value: "after_2h",
    },
    {
      text: {
        default: "both",
        gr: "Και τα δύο",
        fr: "Les deux",
        de: "",
        ka: "ორივე ფორმით",
        fi: "molemmat",
      },
      value: "both",
    },
  ],
};

const s5q3milk = {
  ...s5q3base,
  visibleIf: "{q4s5q1.milk.yes_or_no} == 'yes'",
  name: "s5q3milk",
};
const s5q3egg = {
  ...s5q3base,
  visibleIf: "{q4s5q1.egg.yes_or_no} == 'yes'",
  name: "s5q3milk",
};
const s5q3wheat = {
  ...s5q3base,
  visibleIf: "{q4s5q1.wheat.yes_or_no} == 'yes'",
  name: "s5q3egg",
};
const s5q3soy = {
  ...s5q3base,
  visibleIf: "{q4s5q1.soy.yes_or_no} == 'yes'",
  name: "s5q3soy",
};
const s5q3fish = {
  ...s5q3base,
  visibleIf: "{q4s5q1.fish.yes_or_no} == 'yes'",
  name: "s5q3fish",
};
const s5q3nuts = {
  ...s5q3base,
  visibleIf: "{q4s5q1.tree_nuts.yes_or_no} == 'yes'",
  name: "s5q3tree_nuts",
};
const s5q3other = {
  ...s5q3base,
  visibleIf: "{q4s5q1.other.yes_or_no} == 'yes'",
  name: "s5q3other",
};

const s5q4base = {
  type: "radiogroup",
  name: "q4s5q4",
  visibleIf:
    "{q4s5q1.milk.yes_or_no} == 'yes' or {q4s5q1.egg.yes_or_no} == 'yes' or {q4s5q1.wheat.yes_or_no} == 'yes' or {q4s5q1.soy.yes_or_no} == 'yes' or {q4s5q1.fish.yes_or_no} == 'yes' or {q4s5q1.tree_nuts.yes_or_no} == 'yes' or {q4s5q1.other.yes_or_no} == 'yes'",
  title: {
    default:
      "Has your child been diagnosed by a doctor as having an allergy to this food?",
    gr: "Έχει ποτέ διαγνωστεί από γιατρό ότι το παιδί σας έχει αλλεργία σε αυτήν την τροφή;",
    fr: "Votre enfant a-t-il été diagnostiqué par un médecin comme ayant une allergie à cet aliment?",
    de: "",
    ka: "ექიმის მიერ დაუდგინდა თუ არა კვებითი ალერგია თქვენს შვილს ამ საკვების მიმართ?",
    fi: "Onko lääkäri diagnosoinut lapsellesi allergian tälle ruoalle? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s5q4milk = {
  ...s5q4base,
  visibleIf: "{q4s5q1.milk.yes_or_no} == 'yes'",
  name: "s5q4milk",
};
const s5q4egg = {
  ...s5q4base,
  visibleIf: "{q4s5q1.egg.yes_or_no} == 'yes'",
  name: "s5q4milk",
};
const s5q4wheat = {
  ...s5q4base,
  visibleIf: "{q4s5q1.wheat.yes_or_no} == 'yes'",
  name: "s5q4egg",
};
const s5q4soy = {
  ...s5q4base,
  visibleIf: "{q4s5q1.soy.yes_or_no} == 'yes'",
  name: "s5q4soy",
};
const s5q4fish = {
  ...s5q4base,
  visibleIf: "{q4s5q1.fish.yes_or_no} == 'yes'",
  name: "s5q4fish",
};
const s5q4nuts = {
  ...s5q4base,
  visibleIf: "{q4s5q1.tree_nuts.yes_or_no} == 'yes'",
  name: "s5q4tree_nuts",
};
const s5q4other = {
  ...s5q4base,
  visibleIf: "{q4s5q1.other.yes_or_no} == 'yes'",
  name: "s5q4other",
};

const s5q5base = {
  type: "radiogroup",
  name: "q4s5q5",
  visibleIf:
    "{q4s5q1.milk.yes_or_no} == 'yes' or {q4s5q1.egg.yes_or_no} == 'yes' or {q4s5q1.wheat.yes_or_no} == 'yes' or {q4s5q1.soy.yes_or_no} == 'yes' or {q4s5q1.fish.yes_or_no} == 'yes' or {q4s5q1.tree_nuts.yes_or_no} == 'yes' or {q4s5q1.other.yes_or_no} == 'yes'",
  title: {
    default: "Is your child now able to eat this food without symptoms?",
    gr: "Το παιδί σας μπορεί να φάει αυτή την τροφή τώρα χωρίς συμπτώματα;",
    fr: "Votre enfant est-il maintenant capable de manger cet aliment sans symptômes ?",
    de: "",
    ka: "ამჟამად იღებს თუ არა თქვენი შვილი ამ საკვებს ალერგიული სიმპტომების გამოვლენის გარეშე? ",
    fi: "Voiko lapsesi nyt syödä tätä ruokaa ilman oireita? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s5q5milk = {
  ...s5q5base,
  visibleIf: "{q4s5q1.milk.yes_or_no} == 'yes'",
  name: "s5q5milk",
};
const s5q5egg = {
  ...s5q5base,
  visibleIf: "{q4s5q1.egg.yes_or_no} == 'yes'",
  name: "s5q5milk",
};
const s5q5wheat = {
  ...s5q5base,
  visibleIf: "{q4s5q1.wheat.yes_or_no} == 'yes'",
  name: "s5q5egg",
};
const s5q5soy = {
  ...s5q5base,
  visibleIf: "{q4s5q1.soy.yes_or_no} == 'yes'",
  name: "s5q5soy",
};
const s5q5fish = {
  ...s5q5base,
  visibleIf: "{q4s5q1.fish.yes_or_no} == 'yes'",
  name: "s5q5fish",
};
const s5q5nuts = {
  ...s5q5base,
  visibleIf: "{q4s5q1.tree_nuts.yes_or_no} == 'yes'",
  name: "s5q5tree_nuts",
};
const s5q5other = {
  ...s5q5base,
  visibleIf: "{q4s5q1.other.yes_or_no} == 'yes'",
  name: "s5q5other",
};

const s5dmilk = {
  type: "html",
  name: "subheadingMilk",
  visibleIf: "{q4s5q1.milk.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Milk</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Γάλα</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Lait</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> რძე</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Maito</b> </p> ",
  },
};

const s5degg = {
  type: "html",
  name: "subheadingEgg",
  visibleIf: "{q4s5q1.egg.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Egg</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Αυγό</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Œuf</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> კვერცხი</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Muna</b> </p> ",
  },
};

const s5dwheat = {
  type: "html",
  name: "subheadingWheat",
  visibleIf: "{q4s5q1.wheat.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Wheat</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Σιτηρά</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Blé</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> ხორბალი</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Vehnä</b> </p> ",
  },
};

const s5dsoy = {
  type: "html",
  name: "subheadingSoy",
  visibleIf: "{q4s5q1.soy.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Soy</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Σόγια</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Soja</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> სოიო</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Soija</b> </p> ",
  },
};

const s5dfish = {
  type: "html",
  name: "subheadingFish",
  visibleIf: "{q4s5q1.fish.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Fish</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Ψάρι</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Poissons</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> თევზი</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Kala</b> </p> ",
  },
};

const s5dnuts = {
  type: "html",
  name: "subheadingNuts",
  visibleIf: "{q4s5q1.tree_nuts.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Tree nuts</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Ξηροί καρποί</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Fruits à coque</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> თხილეული</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Pähkinät</b> </p> ",
  },
};

const s5dother = {
  type: "html",
  name: "subheadingOther",
  visibleIf: "{q4s5q1.other.yes_or_no} == 'yes'",
  html: {
    default:
      "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Other</b> </p> ",
    gr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Άλλο</b> </p> ",
    fr: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Autres</b> </p> ",
    de: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> </b> </p> ",
    ka: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> სხვა</b> </p> ",
    fi: "  <p style='font-size: 20px'>&nbsp; Answer the following questions for: <b> Muu</b> </p> ",
  },
};

export const elementsS5 = [
  s5q1,
  s5dmilk,
  s5q2milk,
  s5q3milk,
  s5q4milk,
  s5q5milk,
  s5degg,
  s5q2egg,
  s5q3egg,
  s5q4egg,
  s5q5egg,
  s5dwheat,
  s5q2wheat,
  s5q3wheat,
  s5q4wheat,
  s5q5wheat,
  s5dsoy,
  s5q2soy,
  s5q3soy,
  s5q4soy,
  s5q5soy,
  s5dfish,
  s5q2fish,
  s5q3fish,
  s5q4fish,
  s5q5fish,
  s5dnuts,
  s5q2nuts,
  s5q3nuts,
  s5q4nuts,
  s5q5nuts,
  s5dother,
  s5q2other,
  s5q3other,
  s5q4other,
  s5q5other,
];
