import a1 from "../../assets/tutorial/settings/pwdA.png";
import a2 from "../../assets/tutorial/settings/pwdA2.png";
import p1 from "../../assets/tutorial/settings/pwdP.png";
import p2 from "../../assets/tutorial/settings/pwdP1.png";

export const settingsTutorialData = [
  {
    imageSrc: a1,
    subtitle: "Overview",
    description: [
      "You can access the password change page by clicking the icon at the top of the navigation bar.",
    ],
  },
  {
    imageSrc: a2,
    description: [
      "On this page, you can set a new, more secure password for your account.",
    ],
  },
];

export const settingsTutorialDataStudents = [
  {
    imageSrc: p1,
    subtitle: "Overview",
    description: [
      "You can access the password change page by clicking the icon at the top of the navigation bar.",
    ],
  },
  {
    imageSrc: p2,
    description: [
      "On this page, you can set a new, more secure password for your account.",
    ],
  },
];
