import { Sensor } from "../../types/sensor";
import { SENSORS_ACTIONS } from "./../actions/sensorAcrions";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSensors = createAsyncThunk<Sensor[]>(
  SENSORS_ACTIONS.GET_SENSOSRS,
  async (params, thunkAPI) => {
    try {
      const response = (await fetch("/api/sensors")).json();
      return response;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
