import { isRequired } from "../../consts/required";
import { yes, no } from "../../consts/yes_no";

const s7q1 = {
  type: "radiogroup",
  name: "q7s7q1",
  title: {
    default: "Does your child have allergic rhinitis? ",
    gr: "Έχει το παιδί σας συμπτώματα αλλεργικής ρινίτιδας",
    fr: "Votre enfant a-t-il une rhinite allergique ?",
    de: "",
    ka: "აქვს თუ არა თქვენს შვილს ალერგიული რინიტი?",
    fi: "Onko lapsellasi allerginen nuha?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s7q1a = {
  type: "radiogroup",
  name: "q7s7q1a",
  colCount: 2,
  visibleIf: "{q7s7q1} == 'yes'",
  title: {
    default: "Symptoms were present:",
    gr: "Είχε συμπτώματα :",
    fr: "Présence de symptômes :",
    de: "",
    ka: "სიმპტომები აღენიშნება:",
    fi: "Oireita esiintyi:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4days/week",
        gr: "<4ημέρες/εβδομάδα",
        fr: "<4 jours/semaine",
        de: "",
        ka: "<4 დღე /კვირაში",
        fi: "<4 päivää/viikko",
      },
      value: "less_than_4d_pw",
    },
    {
      text: {
        default: ">4 days/week",
        gr: ">4 ημέρες/εβδομάδα",
        fr: " >4 jours/semaine",
        de: "",
        ka: ">4 დღე/კვირაში",
        fi: ">4 päivää/viikko",
      },
      value: "more_than_4d_pw",
    },
  ],
};

const s7q1b = {
  type: "radiogroup",
  name: "q7s7q1b",
  visibleIf: "{q7s7q1} == 'yes'",
  colCount: 2,
  title: {
    default: "Symptoms were present:",
    gr: "Είχε συμπτώματα:",
    fr: "Présence de symptômes :",
    de: "",
    ka: "სიმპტომები აღენიშნებოდა:",
    fi: "Oireita esiintyi:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4 consecutive weeks",
        gr: "<4 συνεχόμενες εβδομάδες",
        fr: "<4 semaines consécutives",
        de: "",
        ka: "<4 კვირა ზედიზედ",
        fi: "<4 peräkkäistä viikkoa",
      },
      value: "less_than_4_cw",
    },
    {
      text: {
        default: ">4 consecutive weeks",
        gr: ">4 συνεχόμενες εβδομάδες",
        fr: ">4 semaines consécutives",
        de: "",
        ka: ">4 კვირა ზედიზედ",
        fi: ">4 peräkkäistä viikkoa",
      },
      value: "more_than_4_cw",
    },
  ],
};

const s7q2 = {
  type: "radiogroup",
  name: "q7s7q2",
  visibleIf: "{q7s7q1} == 'yes'",
  title: {
    default:
      "Has your child received any treatment for allergic rhinitis, in the last 3 months:",
    gr: "Έχει λάβει το παιδί σας οποιαδήποτε θεραπεία για αλλεργική ρινίτιδα, τους τελευταίους 3 μήνες:",
    fr: "Votre enfant a-t-il reçu des traitements pour la rhinite allergique aux cours des dernier 3 mois ?",
    de: "",
    ka: "ბოლო 3 თვის განმავლობაში ხომ არ მიუღია თქვენს შვილს ალერგიული რინიტის სამკურნალო საშუალებები:",
    fi: "Onko lapsesi saanut hoitoa allergiseen nuhaan viimeisten 3 kuukauden aikana:",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s7q2a = {
  type: "multipletext",
  name: "q7s7q2a",
  visibleIf: "{q7s7q1} == 'yes' and {q7s7q2} == 'yes'",
  title: {
    default: "If yes, on average how many days has your child received:",
    gr: "Αν ναι, πόσες μέρες κατά μέσο όρο έλαβε το παιδί σας:",
    fr: "Si oui, combien de jour a-t-il/elle été traité(e) par",
    de: "",
    ka: "ანტიჰისტამინური პრეპარატები [დღე]",
    fi: "Jos kyllä, kuinka monta päivää lapsesi on saanut hoitoa:",
  },
  isRequired: isRequired,
  items: [
    {
      name: "oral_antistamines",
      title: {
        default: "Oral antihistamines [days]",
        gr: "Αντιισταμινικά από το στόμα (σιρόπι, χάπια) [μέρες]",
        fr: "Des antihistaminiques (jours)",
        de: "",
        ka: "ანტიჰისტამინური პრეპარატები [დღე]",
        fi: "Suun kautta otettavat antihistamiinit [päivää]",
      },
      min: 1,
      max: 90,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 90",
          minValue: 1,
          maxValue: 90,
        },
      ],
      inputType: "number",
    },
    {
      name: "nasal_sprays",
      title: {
        default: "Nasal sprays (Intranasal corticosteroids) [days]",
        gr: "Σπρεί για τη μύτη (ενδορινικά κορτικοστεροειδή) [μέρες]",
        fr: "Des sprays nasaux (corticoïdes nasaux) [jours]",
        de: "",
        ka: "ნაზალური სპრეი (ინტრანაზალური კორტიკოსტეროიდები) [დღე]",
        fi: "Nenäsumutteet (intranasaaliset kortikosteroidit) [päivää]",
      },
      min: 1,
      max: 90,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 90",
          minValue: 1,
          maxValue: 90,
        },
      ],
      inputType: "number",
    },
  ],
};

const s7q3 = {
  type: "radiogroup",
  name: "q7s7q3",
  title: {
    default: " Is your child receiving Allergen Immunotherapy?",
    gr: "Λαμβάνει το παιδί σας ανοσοθεραπεία; ",
    fr: "Votre enfant est-il en cours de traitement par une immunothérapie allergénique (désensibilisation) ? ",
    de: "",
    ka: "ხომ არ იღებს თქვენი შვილი იმუნოთერაპიას?",
    fi: "Saako lapsesi allergeeni-immunoterapiaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s7q1} == 'yes'",
};

const s7q3a = {
  name: "q7s7q3a",
  type: "text",
  title: {
    default: "If yes, how long?",
    gr: "Εάν ναι, πόσο καιρό; ",
    fr: "Si oui, depuis combien de temps ? ",
    de: "",
    ka: "თუ კი, რამდენი ხანი?",
    fi: "Jos kyllä, kuinka kauan?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 5,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 5",
      minValue: 1,
      maxValue: 5,
    },
  ],
  visibleIf: "{q7s7q1} == 'yes' and {q7s7q3} == 'yes'",
};

export const elementsS7 = [s7q1, s7q1a, s7q1b, s7q2, s7q2a, s7q3, s7q3a];
