import { Container } from "@mui/material";
import Chart, {
  ChartTypeRegistry,
} from "chart.js/auto";
import React, {
  memo,
  useEffect,
  useRef,
} from "react";
import styles from "../styles/Chart.module.scss";
import { Colors } from 'chart.js';

Chart.register(Colors);

const ChartComp = ({
  type,
  title,
  data,
}: {
  type: string;
  title: string;
  data: {
    datasets: any[];
  };
}) => {
  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;

    // console.log(type);

    const chartStatus = Chart.getChart("chart");
    if (chartStatus !== undefined) {
      chartStatus.destroy();
    }

    const chart = new Chart(ctx!, {
      type: type as keyof ChartTypeRegistry,
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
        },
        scales: {
          x: {
            stacked: data.datasets.length>1 ? true : false,
          },
          y: {
            stacked: data.datasets.length>1 ? true : false,
          }
        }
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <Container max-width="xl">
      <div className={styles.chart}>
        <canvas id="myChart" ref={canvas}></canvas>
      </div>
    </Container>
  );
};

export default memo(ChartComp);
