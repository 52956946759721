import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s5q1 = {
  type: "radiogroup",
  name: "q1s5q1",
  title: {
    default:
      "Has your child ever had food allergy? ",
    gr: "Τους τελευταίους 12 μήνες, έχει διαγνώστει το παιδί σας με τροφική αλλεργία από γιατρό;",
    fr: "Au cours des derniers 12 mois, votre enfant a-t-il/elle présenté une allergie alimentaire diagnostiquée par un médecin?",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე ჰქონია თუ არა თქვენს შვილს ექიმის მიერ დიაგნოსტირებული კვებითი ალერგია?",
    fi: "Viimeisen 12 kk aikana, onko lapsellanne ollut ruoka-allergiaa, joka on lääkärin diagnosoima?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

export const elementsS5 = [s5q1];
