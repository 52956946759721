import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ThemeProvider,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledButton } from "../styled/Button";
import { Search, Settings } from "@mui/icons-material";
import {
  StyledFormControl,
  menuStyles,
} from "../styled/StyledFormSelect";
import { School } from "../../types/school";
import dayjs, { Dayjs } from "dayjs";
import { ToolbarConfig } from "../../types/widget";
import { theme } from "../../consts/theme";
import { getTitle } from "../../utils/getSchoolName";
import { fetchSchoolsByCountry } from "../../services/api/schools.api";

const WidgetToolbar = ({ config }: { config: ToolbarConfig }) => {
  const COUNTRIES = config.countryConfig?.countries || [];
  const schools = config.schools;
  const school_ids = config.schools
    ? config.schools.map((school) => school.school_id)
    : [];
  const [school_options, setSchoolOptions] = useState(school_ids);
  const [country, setCountry] = useState(
    config.countryConfig !== undefined && config.countryConfig.required
      ? "Greece"
      : ""
  );
  const [selectedSchool, setSchool] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().add(-1, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [show, setShow] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   if (COUNTRIES.length === 1) {
  //     fetchSchoolsByCountry(COUNTRIES[0]).then((data) =>
  //       setSchoolOptions(data.data.data.map((school) => school.school_id))
  //     );
  //   }
  // });

  useEffect(() => {
    // console.log(schools);
    setSchoolOptions(school_ids);
  }, [schools]);

  const handleSubmit = () => {
    const params = {
      country,
      school_id: selectedSchool,
      start_date: startDate?.format("DD/MM/YYYY").toString(),
      end_date: endDate?.format("DD/MM/YYYY").toString(),
    };

    if (error === null) {
      // console.log("Error: ", error);
      config.queryCB(params);
      setShow(false);
    }
  };

  const handleClick = () => {
    setShow(true);
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    const country = e.target.value as string;
    setCountry(country);
    if (country === "") {
      setSchoolOptions(school_ids);
    } else {
      fetchSchoolsByCountry(country).then((data) =>
        setSchoolOptions(data.data.data.map((school) => school.school_id))
      );
    }
  };

  return !show ? (
    <Container sx={styles.searchBar} maxWidth={false}>
      <StyledButton variant="contained" onClick={handleClick}>
        <Settings fontSize="small" />
      </StyledButton>
    </Container>
  ) : (
    <Container maxWidth={false}>
      <Grid container sx={styles.searchBar}>
        {config.datepicker && (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                format="DD/MM/YYYY"
                defaultValue={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                disableFuture
                maxDate={endDate}
                onError={(newError) => setError(newError)}
                slotProps={{
                  textField: { size: "small", sx: { width: width } },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                format="DD/MM/YYYY"
                defaultValue={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                disableFuture
                minDate={startDate}
                maxDate={
                  config.datepickerConfig?.maxInterval !== undefined
                    ? startDate?.add(
                        config.datepickerConfig?.maxInterval,
                        "day"
                      )
                    : null
                }
                onError={(newError) => setError(newError)}
                slotProps={{
                  textField: { size: "small", sx: { width: width } },
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        )}
        {config.country && COUNTRIES.length > 1 && (
          <StyledFormControl
            variant="outlined"
            size="small"
            sx={{ width: width }}
          >
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              value={country}
              onChange={(e) => handleCountryChange(e)}
              input={<OutlinedInput label="Country" />}
              renderValue={(selected: string) => selected}
              MenuProps={menuStyles}
            >
              {COUNTRIES.map((country, index) => {
                return (
                  <MenuItem key={index} value={country}>
                    <ListItemText primary={country} />
                  </MenuItem>
                );
              })}
              {!(
                config.countryConfig !== undefined &&
                config.countryConfig.required
              ) && (
                <MenuItem value={""}>
                  <ListItemText primary={"No Option"} />
                </MenuItem>
              )}
            </Select>
          </StyledFormControl>
        )}
        {schools && (
          <StyledFormControl
            variant="outlined"
            size="small"
            sx={{ width: width }}
          >
            <InputLabel id="school">School</InputLabel>
            <Select
              labelId="school"
              value={selectedSchool}
              onChange={(e) => setSchool(e.target.value as string)}
              input={<OutlinedInput label="Schools" />}
              MenuProps={menuStyles}
              renderValue={(selected: string) => getTitle(schools, selected)}
            >
              {school_options.map((school, index) => {
                return (
                  <MenuItem key={index} value={school}>
                    <ListItemText primary={getTitle(schools, school!)} />
                  </MenuItem>
                );
              })}
              <MenuItem value={""}>
                <ListItemText primary={"No Option"} />
              </MenuItem>
            </Select>
          </StyledFormControl>
        )}
        <StyledButton variant="contained" onClick={handleSubmit}>
          <Search fontSize="small" />
        </StyledButton>
      </Grid>
    </Container>
  );
};

const width = {
  xs: "150px",
  sm: "180px",
  md: "200px",
  lg: "150px",
  xl: "200px",
};

const styles = {
  searchBar: {
    display: "flex",
    flexWrap: { sm: "wrap", md: "nowrap", lg: "wrap", xl: "nowrap" },
    alignItems: "center",
    justifyContent: {
      xs: "flex-end",
      sm: "flex-end",
      md: "flex-end",
      lg: "flex-end",
      xl: "flex-end",
    },
    gap: "10px",
    paddingBottom: "10px",
  },
};
export default WidgetToolbar;
