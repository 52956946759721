import { isRequired } from "../../consts/required";
import { yes, no, dontknow, yes_no } from "../../consts/yes_no";

const s1q1 = {
  type: "radiogroup",
  name: "q7s4q1",
  title: {
    default: "Does your child have recurrent wheeze/asthma? ",
    gr: "Έχει το παιδί σας επαναλαμβανόμενο συριγμό/άσθμα;",
    fr: "Votre enfant souffre-t-il de respiration sifflante/d’asthme récurrent ?",
    de: "",
    ka: "აქვს თუ არა თქვენს შვილს განმეორებითი ხიხინი/ასთმა?",
    fi: "Onko lapsellasi toistuvaa hengityksen vinkumista/astmaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q2 = {
  type: "radiogroup",
  name: "q7s4q2",
  title: {
    default:
      "Has your child had any episodes/times when his/her asthma symptoms were worse than usual?",
    gr: "Είχε το παιδί σας επεισόδια όπου τα συμπτώματα του άσθματος ήταν χειρότερα από ότι συνήθως;",
    fr: "Votre enfant a-t-il/elle eu des épisodes/moments où ses symptômes d’asthme étaient pires que d’habitude ? ",
    de: "",
    ka: "ჰქონდა თუ არა თქვენს შვილს ისეთი ეპიზოდი (ეპიზოდები), როცა ასთმის სიმპტომები ჩვეულებრივზე მეტად გამწვავდა?",
    fi: "Onko lapsellasi ollut jaksoja/kertoja, jolloin hänen astmaoireensa olivat tavallista pahempia?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1q2a = {
  type: "text",
  name: "q7s4q2a",
  title: {
    default: "How many times did that happen?",
    gr: "Πόσες φορές συνέβη αυτό;",
    fr: "Combien de fois cela s’est-il produit ?",
    de: "",
    ka: "რამდენჯერ მოხდა ეს?",
    fi: "Kuinka monta kertaa?",
  },
  inputType: "number",
  min: 1,
  isRequired: isRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 52",
      minValue: 1,
      maxValue: 52,
    },
  ],
  visibleIf: "{q7s4q1} == 'yes' and  {q7s4q2} == 'yes' ",
};

const s1q2b = {
  type: "checkbox",
  name: "q7s4q2b",
  visibleIf: "{q7s4q1} == 'yes' and  {q7s4q2} == 'yes' ",
  title: {
    default: "What do you think caused this?",
    gr: "Τι νομιζετε ότι το προκάλεσε;",
    fr: "D’après vous, qu’est-ce qui a causé cela ?",
    de: "",
    ka: "თქვენი აზრით, ქვემოთ მოყვანილთაგან რომელი იყო ამის მიზეზი?",
    fi: "Mikä mielestäsi aiheutti tämän? ",
  },
  colCount: 4,
  choices: [
    {
      text: {
        default: "Allergen",
        gr: "Αλλεργία",
        fr: "Allergène",
        de: "",
        ka: "ალერგენები",
        fi: "Allergeeni",
      },
      value: "allergen",
    },
    {
      text: {
        default: "Common cold",
        gr: "Ιώσεις",
        fr: "Rhume",
        de: "",
        ka: "გაციება",
        fi: "Nuha",
      },
      value: "cold",
    },
    {
      text: {
        default: "Exercise",
        gr: "Άσκηση",
        fr: "Exercice",
        de: "",
        ka: "დატვირთვა",
        fi: "Liikunta",
      },
      value: "exercise",
    },
  ],
  isRequired: isRequired,
  showOtherItem: true,
  separateSpecialChoices: true,
};

const s1q3 = {
  type: "radiogroup",
  name: "q7s4q3",
  title: {
    default: "Has the child had any emergency visits for their asthma?",
    gr: "Είχε το παιδί σας λόγω του άσθματος επίσκεψη σε τμήμα επειγόντων περιστατικών ;",
    fr: "L’enfant a-t-il/elle eu des visites d’urgence pour son asthme ? ",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს გადაუდებელი დახმარება ასთმის გამო?",
    fi: "Onko lapsella ollut päivystyskäyntejä astman vuoksi?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1q4 = {
  type: "radiogroup",
  name: "q7s4q4",
  title: {
    default: "Has the child been hospitalized for their asthma?",
    gr: "Έχει νοσηλευτεί το παιδί σας λόγω του άσθματος;",
    fr: "L’enfant a-t-il été hospitalisé pour son asthme?",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს ჰოსპიტალიზაცია ასთმის გამო?",
    fi: "Onko lapsi joutunut sairaalaan astmansa vuoksi?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1d1 = {
  type: "html",
  name: "subheading",
  html: {
    default:
      "<p>What treatment has your child used for his/her asthma, in the last 3 months:</p>",
    gr: "<p>Ποια φάρμακα λαμβάνει το παιδί σας για το άσθμα τους τελευταίους 3μήνες:</p>",
    fr: "<p>Quel traitement votre enfant a-t-il utilisé pour son asthme, des dernier 3 mois :</p>",
    de: "<p></p>",
    ka: "<p>ბოლო 3 თვის განმავლობაში, რა სახის მკურნალობა უტარდება თქვენს შვილს ასთმის გამო:</p>",
    fi: "<p>Mitä hoitoa lapsesi on käyttänyt astmaansa viimeisten 3 kuukauden aikana:</p>",
  },
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1q5 = {
  type: "radiogroup",
  name: "q7s4q5",
  title: {
    default: "Relievers /rescue treatment (Aerolin/Ventolin):",
    gr: "Θεραπεία Ανακουφιστική/Διάσωσης (Aerolin)",
    fr: "Traitement de secours/crise (Ventoline, Airomir, Ventilastin)",
    de: "",
    ka: "სიმპტომების სწრაფად მოხნისთვის განკუთვნილ საშუალებებს (აეროლინი/ვენტოლონი)",
    fi: "Lievittäjät / pelastushoito (Aerolin/Ventolin):",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1q5a = {
  type: "radiogroup",
  name: "q7s4q5a",
  visibleIf: "{q7s4q1} == 'yes' and  {q7s4q5} == 'yes' ",
  colCount: 2,
  title: {
    default: "If yes how often:",
    gr: "Εάν ναι πόσο συχνά:",
    fr: "Si oui, avec quelle fréquence",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“ , რამდენად ხშირად იყენებთ ამ საშუალებებს?",
    fi: "Jos kyllä, kuinka usein:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 days/week",
        gr: "≤2 ημέρες/εβδομάδα",
        fr: "≤2 jours/semaine",
        de: "",
        ka: "≤2 დღე/კვირაში",
        fi: "≤2 päivää/viikko",
      },
      value: "less_than_2d_pw",
    },
    {
      text: {
        default: ">2days/week ",
        gr: ">2 ημέρες/εβδομάδα",
        fr: ">2 jours/semaine",
        de: "",
        ka: ">2დღე/კვირაში",
        fi: ">2 päivää/viikko",
      },
      value: "more_than_2d_pw",
    },
  ],
};

const s1q6 = {
  type: "radiogroup",
  name: "q7s4q6",
  title: {
    default: "Daily controller medication:",
    gr: "Καθημερινή θεραπεία ελέγχου/προληπτική",
    fr: "Traitement de fond",
    de: "",
    ka: "სიმპტომების კონტროლისთვის განკუთვნილ ყოველდღიურ საშუალებებს? ",
    fi: "Päivittäinen ohjaava/ennaltaehkäisevä lääkitys",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes' ",
};

const s1q7 = {
  type: "radiogroup",
  name: "q7s4q7",
  title: {
    default: " Is your child receiving Allergen Immunotherapy?",
    gr: "Λαμβάνει το παιδί σας ανοσοθεραπεία; ",
    fr: "Votre enfant est-il en cours de traitement par une immunothérapie allergénique (désensibilisation) ? ",
    de: "",
    ka: "ხომ არ იღებს თქვენი შვილი იმუნოთერაპიას?",
    fi: "Saako lapsesi allergeeni-immunoterapiaa? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s4q1} == 'yes'",
};

const s1q7a = {
  name: "q7s4q7a",
  type: "text",
  title: {
    default: "If yes, how long?",
    gr: "Εάν ναι, πόσο καιρό; ",
    fr: "Si oui, depuis combien de temps ? ",
    de: "",
    ka: "თუ კი, რამდენი ხანი?",
    fi: "Jos kyllä, kuinka kauan?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 5,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 5",
      minValue: 1,
      maxValue: 5,
    },
  ],
  visibleIf: "{q7s4q1} == 'yes' and {q7s4q7} == 'yes' ",
};

export const elementsS4 = [
  s1q1,
  s1q2,
  s1q2a,
  s1q2b,
  s1q3,
  s1q4,
  s1d1,
  s1q5,
  s1q5a,
  s1q6,
  s1q7,
  s1q7a,
];
