import { memo } from "react";
import Widget from "./Widget";
import { TableDashProps } from "../../types/dashboard";
import { Column } from "../../types/table";
import { QueryParams, StudentsNoAnswerTable } from "../../types/queries";
import { useAppThunkDispatch } from "../../store/hooks";
import { getNoAnswersStudentsTable } from "../../store/effects/reportingEffects";
import { TableBar } from "../styled/AppBar";
import { Toolbar } from "@mui/material";
import { TableWidget } from "../../types/widget";

const TableDash: React.FC<TableDashProps> = memo((props) => {
  const dispatch = useAppThunkDispatch();

  const widgetTableCallBack = (params: QueryParams) => {
    // console.log("From Table: ", params);
    dispatch(getNoAnswersStudentsTable(params));
  };

  const columns: Column<StudentsNoAnswerTable, keyof StudentsNoAnswerTable>[] =
  [
    // { name: "ID", key: "_id" },
    { name: "USERNAME", key: "username" },
    { name: "UNIQUE ID", key: "offline_code" },
    { name: "SCHOOL", key: "school" },
    { name: "COUNTRY", key: "country" },
  ];

const StudentsTableToolbar = (): JSX.Element => {
  return (
    <TableBar position="relative">
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>{`Students that haven't answered questionnaires`}</h2>
      </Toolbar>
    </TableBar>
  );
};

  const tableConfig: TableWidget<
    StudentsNoAnswerTable,
    keyof StudentsNoAnswerTable
  > = {
    toolbarConfig: {
      datepicker: true,
      country: true,
      countryConfig: {
        countries: props.countries,
        required: false,
      },
      schools: props.schools,
      queryCB: widgetTableCallBack,
    },
    tableConfig: {
      rows: props.data,
      columns: columns,
      toolbar: StudentsTableToolbar,
    },
  };

  return <Widget config={tableConfig} />;
});

export default TableDash;