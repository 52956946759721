import school1 from "../../assets/tutorial/schools/school1.png";
import createSchool from "../../assets/tutorial/schools/createSchool.png";

export const schoolsTutorialData = [
  {
    imageSrc: school1,
    subtitle: "Overview",
    description: [
      "This page displays a map showing the locations of all the schools and a table containing all the schools, following a similar structure to the users page.",
      "Each row has three dots that reveal options for editing or deleting a school.",
      "At the top right of the table, you'll find the create school button.",
    ],
  },
  {
    imageSrc: createSchool,
    subtitle: "Create School",
    description: [
      "Here, you can create a school by entering the name, location and country of the school.",
      "You may also add a description and a sensor box id if there is one placed at the school.",
    ],
  },
];
