import { Box, styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";


export const StyledLayoutBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100vw',
  height: variables.layout_box_height,
  backgroundColor: variables.color_primary_hover,
  zIndex: 0,
  top: 0
  }),
);