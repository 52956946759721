import { getApiUrl } from "../../consts/api";
import { ApiResponse, AuthApi } from "../../types/api";
import { Axios } from "../api/axios";

export const authClient = () => Axios(`${getApiUrl()}/auth`);

export async function getJwt(usr: string, psw: string) {
  // var loginForm: any = new FormData();
  // loginForm.append("username", username);
  // loginForm.append("password", password);

  const params = {usr, psw};

  return await authClient().get<ApiResponse<AuthApi>>("/keycloak/jwt", { params });
}

export async function sendRefreshToken(refresh_token: string) {
  const params = {refresh_token : JSON.parse(refresh_token)};
  return await authClient().get<ApiResponse<AuthApi>>("/keycloak/logout", { params });
}

// getMe = (userId: string) => {
//   return this.instance
//     .get(`/users/${userId}`, {
//       headers: getAuthorizationHeader(),
//     })
//     .then((res) => {
//       return res.data;
//     });
// };
