import { elementsS1 } from "./partA/section1";
import { elementsS2 } from "./partB/section2";
import { elementsS3 } from "./partB/section3";
import { elementsS4 } from "./partB/section4";
import { elementsS5 } from "./partB/section5";
import { elementsS6 } from "./partC/section6";
import { elementsS7 } from "./partC/section7";

export const questionnaire1_old = {
  id: "1",
  preview: "/q1preview.png",
  answersNum: 1,
  checkErrorsMode: "onValueChanged",
  active: {
    Greece: {
      from: "01/11/2023",
      to: "01/11/2024",
    },
  },
  title: {
    default: "(Deprecated) Baseline questionnaire",
    gr: "Βασικό ερωτηματολόγιο",
    fr: "Questionnaire initial",
    de: "",
    ka: "საბაზისო კითხვარი",
    fi: "Peruskyselylomake",
  },
  showQuestionNumbers: "on",
  description: {
    default: "Baseline questionnaire for total cohort",
    gr: "Βασικό ερωτηματολόγιο για τη συνολική κοόρτη",
    fr: "Questionnaire initial",
    de: "",
    ka: "კოჰორტული კვლევის საბაზისო კითხვარი",
    fi: "Peruskyselylomake",
  },
  pages: [
    {
      name: "section1",
      title: {
        default: "General health and family history",
        gr: "Γενικές Ερωτήσεις",
        fr: "Santé générale et antécédents familiaux",
        de: "",
        ka: "ზოგადი მონაცემები ჯანმრთელობისა და დაავადებათა ოჯახური ისტორიის შესახებ",
        fi: "Yleinen terveydentila ja sukuhistoria",
      },
      elements: elementsS1,
    },
    {
      name: "section2",
      title: {
        default: "Respiratory and atopy history",
        gr: "Αναπνευστικό και ατοπικό ιστορικό",
        fr: "Antécédents respiratoires et atopiques",
        de: "",
        ka: "რესპირაციულიდა ატოპიური დაავადებებათა ისტორია",
        fi: "Hengitysteiden ja atopian historia",
      },
      description: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
      elements: elementsS2,
    },
    {
      name: "section3",
      title: {
        default: "Respiratory and atopy history",
        gr: "Αναπνευστικό και ατοπικό ιστορικό",
        fr: "Antécédents respiratoires et atopiques",
        de: "",
        ka: "რესპირაციულიდა ატოპიური დაავადებებათა ისტორია ",
        fi: "Hengitysteiden ja atopian historia",
      },
      description: {
        default: "Rhinitis",
        gr: "Ρινίτιδα",
        fr: "Rhinite",
        de: "",
        ka: "რინიტი",
        fi: "Nuha",
      },
      elements: elementsS3,
    },
    {
      name: "section4",
      title: {
        default: "Respiratory and atopy history",
        gr: "Αναπνευστικό και ατοπικό ιστορικό",
        fr: "Antécédents respiratoires et atopiques",
        de: "",
        ka: "რესპირაციულიდა ატოპიური დაავადებებათა ისტორია",
        fi: "Hengitysteiden ja atopian historia",
      },
      description: {
        default: "Eczema",
        gr: "Έκζεμα",
        fr: "Eczéma",
        de: "",
        ka: "ეგზემა",
        fi: "Ihottuma",
      },
      elements: elementsS4,
    },
    {
      name: "section5",
      title: {
        default: "Respiratory and atopy history",
        gr: "Αναπνευστικό και ατοπικό ιστορικό",
        fr: "Antécédents respiratoires et atopiques",
        de: "",
        ka: "რესპირაციულიდა ატოპიური დაავადებებათა ისტორია",
        fi: "Hengitysteiden ja atopian historia",
      },
      description: {
        default: "Food Allergy",
        gr: "Τροφική αλλεργία",
        fr: "Allergies alimentaires",
        de: "",
        ka: "კვებითი ალერგია",
        fi: "Ruoka-allergia",
      },
      elements: elementsS5,
    },
    {
      name: "section6",
      title: {
        default: "Exposures ",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default:
          "Child’s room/home. In this section we ask a number of questions on your child’s home. For each question, please provide answers for the home in which your child lives at present, and for the home in which your child lived during the first year of life. (In case you have moved, please choose the home in which your child spent most of his or her time during the first year of life). Please make sure that you tick both columns! ",
        gr: "Το δωμάτιο/σπίτι του παιδιού. Σε αυτήν την ενότητα θέλουμε να απαντήσετε μια σειρά ερωτήσεων σχετικά με το σπίτι του παιδιού σας. Για κάθε ερώτηση, παρακαλώ να δώσετε απαντήσεις για το σπίτι στο οποίο διαμένει σήμερα και για το σπίτι στο οποίο διέμενε κατά τη διάρκεια του πρώτου χρόνου της ζωής του. (Σε περίπτωση που έχετε μετακομίσει, παρακαλώ επιλέξτε το σπίτι στο οποίο το παιδί σας πέρασε τον περισσότερο χρόνο κατά τον πρώτο χρόνο της ζωής του). Παρακαλούμε σιγουρευτείτε ότι έχετε σημειώσει τις απαντήσεις σας και στις δύο στήλες!",
        fr: "Chambre/domicile de l’enfant. Dans cette section, nous posons un certain nombre de questions sur le foyer de votre enfant. Pour chaque question, veuillez répondre pour le foyer dans lequel votre enfant vit actuellement et pour le foyer dans lequel il a vécu au cours de sa première année de vie. (Si vous avez déménagé, veuillez choisir le domicile dans lequel votre enfant a passé la majeure partie de son temps au cours de sa première année de vie). Veillez à cocher les deux colonnes!",
        de: "",
        ka: "ბავშვის ოთახი/სახლი. ამ ნაწილში წარმოდგენილი კითხვები  თქვენი შვილის  საცხოვრებელი სახლის შესახებ. გთხოვთ, გვიპასუხოთ კითხვებზე, რომლებიც ეხება იმ საცხოვრებელ სახლს, სადაც თქვენი შვილი ამჟამად ცხოვრობს და ასევე, იცხოვრა დაბადებიდან პირველი წლის განმავლობაში (იმ შემთხვევაში, თუ თქვენ შეიცვალეთ საცხოვრებელი, გთხოვთ პასუხებისთვის აირჩიოთ ის სახლი, სადაც ბავშვმა გაატარა მეტი დრო დაბადებიდან  პირველ წელს). გთხოვთ,მიაქციოთყურადღება, რომ მონიშნული იყოს ორივე სვეტი!",
        fi: "Tässä osiossa kysymme useita kysymyksiä lapsesi kodista. Vastatkaa kuhunkin kysymykseen siitä kodista, jossa lapsenne asuu tällä hetkellä, ja siitä kodista, jossa lapsenne asui ensimmäisen elinvuotensa aikana. (Jos olette muuttaneet, valitkaa se koti, jossa lapsenne vietti suurimman osan ajastaan ensimmäisen elinvuotensa aikana). Varmistaa, että rastitatte molempiin sarakkeisiin!",
      },
      elements: elementsS6,
    },
    {
      name: "section7",
      title: {
        default: "Exposures",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s home building",
        gr: "Το κτίριο του σπιτιού που μένει το παιδί σας",
        fr: "Construction de la maison de l’enfant",
        de: "",
        ka: "ბავშვის საცხოვრებელი შენობა",
        fi: "Lapsen kotirakennus",
      },
      elements: elementsS7,
    },
  ],
};
