import SchoolForm from "../../../components/Forms/SchoolForm";
import { PermissionControl } from "../../../components/Permission";
import { getRole } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";

const CreateNewSchoolsPage = () => {
  return (
    <PermissionControl
      permissionPath={permissions.pages.schools.create}
      role={getRole()}
    >
      <SchoolForm />
    </PermissionControl>
  );
};

export default CreateNewSchoolsPage;
