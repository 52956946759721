import * as React from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import variables from "../styles/variables.module.scss";
import { alpha, lighten } from "@mui/material/styles";

interface Props {
  message: string;
  show?: boolean;
  vertical?: "top" | "bottom";
  horizontal?: "center" | "left" | "right";
  style?: React.CSSProperties;
}

const CustomSnackbar: React.FC<Props> = ({
  message,
  show = false,
  vertical = "top",
  horizontal = "center",
  style = {},
}) => {
  const [open, setOpen] = React.useState(show);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        sx={{ padding: 4 }}
        style={style}
      >
        <Alert
          onClose={handleClose}
          severity='info'
          variant='filled'
          sx={{
            width: "100%",
            fontWeight: 800,
            fontSize: "1rem",
            textAlign: "justify",
            backgroundColor: `${alpha(
              lighten(variables.color_primary, 0.2),
              0.9
            )}`,
            "& .MuiAlert-icon": {
              fontSize: "2rem",
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
