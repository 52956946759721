import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { selectUsers } from "../../../store/selectors/userSelector";
import { getActiveUser } from "../../../store/actions/userActions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../../../components/Forms/UserForm";

const UserFormPage = () => {
  const dispatch = useAppThunkDispatch();
  const data = useAppSelector(selectUsers);
  const { id } = useParams();

  console.log(id);

  const activeUser = data.find((user) => user._id === id?.toString());

  activeUser && dispatch(getActiveUser(activeUser));

  console.log(activeUser);

  return (
    <PermissionControl
      permissionPath={permissions.pages.users.update}
      role={getRole()}
    >
      <Form />
    </PermissionControl>
  );
};
export default UserFormPage;
