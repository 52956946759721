import { selectMultiple } from "../../consts/description";
import { isNotRequired, isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s7q1 = {
  type: "checkbox",
  name: "q1s7q1",
  colCount: 3,
  title: {
    default: "Local air pollution sources",
    gr: "Τοπικές πηγές ατμοσφαιρικής ρύπανσης",
    fr: "Sources locales (actuelles) de pollution de l’air ",
    de: "",
    ka: "ჰაერის  დაბინძურების ადგილობრივი წყაროები",
    fi: "Paikalliset ilman epäpuhtauslähteet",
  },
  description: selectMultiple,
  isRequired: isNotRequired,
  choices: [
    {
      value: "traffic_related",
      text: {
        default: "Traffic related ",
        gr: "Αυξημένη κυκλοφορία οχημάτων",
        fr: "Liées au trafic",
        de: "",
        ka: "საგზაო მოძრაობა",
        fi: "Liikenteeseen liittyvät",
      },
    },
    {
      value: "industrial_commercial",
      text: {
        default: "Industrial/commercial",
        gr: "Βιομηχανικές/Εμπορικές",
        fr: "Industrielles/commerciales",
        de: "",
        ka: "ინდუსტრიულ/კომერციული უბნები",
        fi: "Teollisuus/kaupallinen",
      },
    },
    {
      value: "agriculture",
      text: {
        default: "Agriculture ",
        gr: "Γεωργικές",
        fr: "Agriculture",
        de: "",
        ka: "აგრარული წარმოება",
        fi: "Maatalous",
      },
    },
    {
      value: "car_parking",
      text: {
        default: "Car parking",
        gr: "Χώροι στάθμευσης αυτοκινήτων",
        fr: "Parking",
        de: "",
        ka: "მანქანების პარკირება",
        fi: "Autojen pysäköinti",
      },
    },
    {
      value: "forested_areas",
      text: {
        default: "Forested areas",
        gr: "Δασικές εκτάσεις",
        fr: "Zones forestières",
        de: "",
        ka: "ტყის მასივები",
        fi: "Metsäalueet",
      },
    },
    {
      value: "waste_disposal",
      text: {
        default: "Landfill, waste disposal",
        gr: "Απορρίμματα/σκουπίδια",
        fr: "Décharge, élimination des déchets",
        de: "",
        ka: "ნაგავსაყრელები",
        fi: "Kaatopaikka",
      },
    },
    {
      value: "hvac",
      text: {
        default: "Cooling towers, HVAC",
        gr: "Πύργοι ψύξης, HVAC",
        fr: "Tours de refroidissement, HVAC",
        de: "",
        ka: "გაგრილების დანადგარები",
        fi: "Jäähdytystornit, ilmastointi",
      },
    },
    {
      value: "animal_husbandry",
      text: {
        default: "Animal husbandry",
        gr: "Εκτροφεία ζώων",
        fr: "Élevage",
        de: "",
        ka: "მეცხოველეობის ფერმები",
        fi: "Kotieläintalous",
      },
    },
    {
      value: "chimneys_smokestacks",
      text: {
        default: "Chimneys, smokestacks",
        gr: "Καμινάδες, φουγάρα",
        fr: "Cheminées, fours à fumée",
        de: "",
        ka: "საკვამურები ",
        fi: "Savupiiput",
      },
    },
    {
      value: "construction_work",
      text: {
        default: "Construction work",
        gr: "Οικοδομικές κατασκευές",
        fr: "Travaux de construction",
        de: "",
        ka: "სამშენებლო სამუშაოები",
        fi: "Rakennustyöt",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλο",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muu",
      },
    },
    {
      value: "none",
      text: {
        default: "None",
        gr: "Καμία",
        fr: "Aucune",
        de: "",
        ka: "არ აღინიშნება",
        fi: "Ei mitään",
      },
    },
  ],
};

const s7q2 = {
  type: "radiogroup",
  name: "q1s7q2",
  colCount: 4,
  title: {
    default: "Building type: ",
    gr: "Τύπος κτιρίου:",
    fr: "Type de bâtiment",
    de: "",
    ka: "შენობის ტიპი",
    fi: "Rakennustyyppi",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "single_family_house",
      text: {
        default: "Single-family house",
        gr: "Μονοκατοικία",
        fr: "Maison individuelle",
        de: "",
        ka: "იზოლირებული სახლი ერთი ოჯახისთვის",
        fi: "Omakotitalo",
      },
    },
    {
      value: "multifamily_housing",
      text: {
        default: "Multifamily housing",
        gr: "Συγκρότημα κατοικιών",
        fr: "Maison mitoyenne",
        de: "",
        ka: "იზოლირებული  სახლი რამოდენიმე  ოჯახისთვის",
        fi: "Rivitalo",
      },
    },
    {
      value: "row_housing",
      text: {
        default: "Row housing",
        gr: "Κατοικία που συνδέεται με άλλα σπίτια χτισμένα στη σειρά",
        fr: "Logement multifamilial",
        de: "",
        ka: "მწკრივი დასახლება",
        fi: "Luhtitalo",
      },
    },
    {
      value: "apartment_block",
      text: {
        default: "Apartment block",
        gr: "Πολυκατοικία",
        fr: "Immeuble d’appartements",
        de: "",
        ka: "საცხოვრებელი კორპუსი",
        fi: "Kerrostalo",
      },
    },
  ],
};

const s7q3 = {
  type: "panel",
  name: "q1s7q3",
  visibleIf:
    "{q1s7q2} == 'multifamily_housing' || {q1s7q2} == 'apartment_block'",
  elements: [
    {
      type: "image",
      name: "banner",
      imageLink: "/building.png",
      imageWidth: "500px",
      imageHeight: "400px",
    },
    {
      type: "radiogroup",
      name: "q1s7q3a",
      colCount: 4,
      isRequired: isNotRequired,
      title: {
        default: "Position in building",
        gr: "Θέση στο κτίριο",
        fr: "Position dans le bâtiment",
        de: "",
        ka: "გთხოვთ მონიშნოთ  თქვენი  ბინის პოზიციონირება",
        fi: "Sijainti rakennuksessa",
      },
      choices: [
        {
          value: "a",
          text: {
            default: "Option A",
            gr: "Επιλογή Α",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
        {
          value: "b",
          text: {
            default: "Option B",
            gr: "Επιλογή Β",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
        {
          value: "c",
          text: {
            default: "Option C",
            gr: "Επιλογή Γ",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
      ],
    },
  ],
};

const s7q4 = {
  type: "checkbox",
  name: "q1s7q4",
  colCount: 4,
  title: {
    default: "Facade(s) Orientation",
    gr: "Προσανατολισμός πρόσοψης",
    fr: "Façade (s) et orientation",
    de: "",
    ka: "სახლის ფასადის ორიენტაცია",
    fi: "Julkisivun/-sivujen suunta",
  },
  description: selectMultiple,
  isRequired: isNotRequired,
  choices: [
    {
      value: "north",
      text: {
        default: "North",
        gr: "Βόρεια",
        fr: "Nord",
        de: "",
        ka: "ჩრდილოეთი",
        fi: "Pohjoinen",
      },
    },
    {
      value: "east",
      text: {
        default: "East",
        gr: "Ανατολικά",
        fr: "Est",
        de: "",
        ka: "აღმოსავლეთი",
        fi: "Itä",
      },
    },
    {
      value: "south",
      text: {
        default: "South",
        gr: "Νότια",
        fr: "Sud",
        de: "",
        ka: "სამხრეთი",
        fi: "Etelä",
      },
    },
    {
      value: "west",
      text: {
        default: "West",
        gr: "Δυτικά",
        fr: "Ouest",
        de: "",
        ka: "დასავლეთი",
        fi: "Länsi",
      },
    },
  ],
};

const s7q4a = {
  name: "q1s7q4a",
  type: "radiogroup",
  visibleIf: "{q1s7q4} contains 'north'",
  title: {
    default: "North windows?",
    gr: "Βόρεια παράθυρα;",
    fr: "Fenêtres nord?",
    de: "",
    ka: "ჩრდილოეთი ფანჯრები?",
    fi: "Pohjoinen ikkunat?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q4b = {
  name: "q1s7q4b",
  type: "radiogroup",
  visibleIf: "{q1s7q4} contains 'east'",
  title: {
    default: "East windows?",
    gr: "Ανατολικά παράθυρα;",
    fr: "Fenêtres Est ?",
    de: "",
    ka: "აღმოსავლეთის ფანჯრები?",
    fi: "Itä ikkunat?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q4c = {
  name: "q1s7q4c",
  type: "radiogroup",
  visibleIf: "{q1s7q4} contains 'south'",
  title: {
    default: "South windows?",
    gr: "Νότια παράθυρα;",
    fr: "Des fenêtres sud ?",
    de: "",
    ka: "სამხრეთი ფანჯრები?",
    fi: "Etelä ikkunat?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q4d = {
  name: "q1s7q4d",
  type: "radiogroup",
  visibleIf: "{q1s7q4} contains 'west'",
  title: {
    default: "West windows?",
    gr: "Δυτικά παράθυρα;",
    fr: "Fenêtres ouest?",
    de: "",
    ka: "დასავლეთის ფანჯრები?",
    fi: "Länsi ikkunat?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

// const s7d1 = {
//   type: "html",
//   name: "subheading",
//   html: {
//     default:
//       "<p>Original construction</p>",
//     gr: "<p>Αρχική κατασκευή</p>",
//     fr: "<p></p>",
//     de: "<p></p>",
//     ka: "<p></p>",
//     fi: "<p></p>",
//   },
// };

const s7q5a = {
  type: "text",
  name: "q1s7q5a",
  title: {
    default: "Year of original construction: ",
    gr: "Έτος αρχικής κατασκευής:",
    fr: "Année de construction originale :",
    de: "",
    ka: "შენობის აგების წელი: ",
    fi: "Alkuperäinen rakennusvuosi:",
  },
  maxLength: 4,
  isRequired: isNotRequired,
};

const s7q5b = {
  name: "q1s7q5b",
  type: "radiogroup",
  title: {
    default: "Originally built as a residential building? ",
    gr: "Ο αρχικός προορισμός χρήσης ήταν για κατοικία;",
    fr: "Construit à l’origine comme bâtiment résidentiel ?",
    de: "",
    ka: "როდესაც ნაგებობა შენდებოდა, იყო თუ არა ის გათვალისწინებული როგორც საცხოვრებელი  შენობა?",
    fi: "Alunperin rakennettu asuinrakennukseksi?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q6 = {
  type: "multipletext",
  name: "q1s7q6",
  title: {
    default: "Area/Volume",
    gr: "Έκταση/Όγκος",
    fr: "Surface/Volume",
    de: "",
    ka: "ფართობი/ მოცულობა",
    fi: "Pinta-ala/tilavuus",
  },
  isRequired: isNotRequired,
  items: [
    {
      name: "level_id",
      title: {
        default: "Floor/level identification",
        gr: "Όροφος/ Επίπεδο",
        fr: "Identification de l’étage/du niveau ",
        de: "",
        ka: "სართულების რაოდენობა ",
        fi: "Mikä kerros",
      },
      inputType: "text",
      isRequired: isNotRequired,
    },
    {
      name: "dwelling_floor_area",
      title: {
        default: "Dwelling floor area (m2)",
        gr: "Εμβαδόν ορόφου κατοικίας (m2)",
        fr: "Surface de plancher du logement (m2)",
        de: "",
        ka: "საცხოვრებელი ფართობი (m2)",
        fi: "Asunnon pinta-ala (m2)",
      },
      inputType: "text",
      isRequired: isNotRequired,
    },
    {
      name: "main_ceiling_height",
      title: {
        default: "Main ceiling height (m)",
        gr: "Ύψος κύριας οροφής (m)",
        fr: "Hauteur sous plafond principale (m)",
        de: "",
        ka: "ჭერის საშუალო სიმაღლე (m)",
        fi: "Kattokorkeus (m)",
      },
      inputType: "text",
      isRequired: isNotRequired,
    },
    {
      name: "rooms",
      title: {
        default: "Room(s) + living room",
        gr: "Αριθμός Δωματίων (συμπεριλαμβανομένου του καθιστικού)",
        fr: "Pièce(s) + salon",
        de: "",
        ka: "ოთახები+ მისაღები ოთახი",
        fi: "Huoneet + olohuone",
      },
      inputType: "text",
      isRequired: isNotRequired,
    },
  ],
};

const s7q7 = {
  type: "text",
  name: "q1s7q7",
  title: {
    default: "How many years living in this home?",
    gr: "Πόσα χρόνια διαμένετε σε αυτό το σπίτι;",
    fr: "Depuis combien d’années vivez-vous dans ce logement?",
    de: "",
    ka: "რამდენი წელია რაც ამ სახლში ცხოვრობთ?",
    fi: "Kuinka monta vuotta tässä kodissa on asuttu?",
  },
  maxLength: 4,
  isRequired: isNotRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 0 to 99",
      minValue: 0,
      maxValue: 99,
    },
  ],
};

const s7q8 = {
  type: "checkbox",
  name: "q1s7q8",
  colCount: 3,
  title: {
    default: "Refurbishment or retrofitting in the last 12 months?",
    gr: "Έχει γίνει ανακαίνιση ή τροποποίηση τους τελευταίους 12 μήνες;",
    fr: "Rénovation ou modernisation au cours des 12 derniers mois ",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში  ჩატარებული სარემონტო/ სარეკონსტრუქციო  სამუშაოები?",
    fi: "Kunnostus tai jälkiasennus viimeisten 12 kuukauden aikana?",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "ceiling_finish",
      text: {
        default: "Ceiling finish",
        gr: "Φινίρισμα οροφής",
        fr: "Finition du plafond",
        de: "",
        ka: "ჭერის მოპირკეთება",
        fi: "Sisäkaton pinnoite",
      },
    },
    {
      value: "wall_finish",
      text: {
        default: "Wall finish",
        gr: "Φινίρισμα τοίχου ",
        fr: "Finition du mur",
        de: "",
        ka: "კედლების ზედაპირის განახლება",
        fi: "Seinäpinnoite",
      },
    },
    {
      value: "window frame",
      text: {
        default: "Window frame",
        gr: "Πλαίσιο παραθύρου/Κούφωμα",
        fr: "Cadre de fenêtre ",
        de: "",
        ka: "ფანჯრის ჩარჩოების გამოცვლა",
        fi: "Ikkunan kehykset",
      },
    },
    {
      value: "floor_finish",
      text: {
        default: "Floor finish",
        gr: "Φινίρισμα δαπέδου",
        fr: "Finition du sol",
        de: "",
        ka: "იატაკის მოპირკეთება",
        fi: "Lattian pinnoite",
      },
    },
    {
      value: "false_walls_ceilings",
      text: {
        default: "False walls/ceilings",
        gr: "Ψευδότοιχος/ Ψευδοροφή",
        fr: "Faux murs/plafonds",
        de: "",
        ka: "შეკიდული ჭერი/ ცრუ კედლები",
        fi: "Valeseinä/valekatto",
      },
    },
    {
      value: "kitchen_exhaustion ",
      text: {
        default: "Kitchen exhaustion ",
        gr: "Απορροφητήρας/Εξαεριστήρας κουζίνας",
        fr: "Épuisement de la cuisine",
        de: "",
        ka: "სამზარეულოს გამწოვი",
        fi: "Keittiö liesituuletin/ilmastointi",
      },
    },
    {
      value: "bathroom_exhaustion",
      text: {
        default: "Bathroom exhaustion",
        gr: "Εξαεριστήρας μπάνιου",
        fr: "Épuisement de la salle de bains",
        de: "",
        ka: "აბაზანის  გამწოვი",
        fi: "WC:n ilmastointi",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλο",
        fr: "Autre",
        de: "",
        ka: "სხვა",
        fi: "Jotain muuta",
      },
    },
    {
      value: "none",
      text: {
        default: "None",
        gr: "Καμία",
        fr: "Aucune",
        de: "",
        ka: "არ აღინიშნება",
        fi: "Ei mitään",
      },
    },
  ],
};

const s7q9 = {
  name: "q1s7q9",
  type: "radiogroup",
  title: {
    default: "General ventilation:",
    gr: "Γενικός αερισμός: ",
    fr: "Ventilation générale",
    de: "",
    ka: "ზოგადი ვენტილაცია",
    fi: "Yleinen ilmanvaihto",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "natural ",
      text: {
        default: "Natural ",
        gr: "Φυσικός",
        fr: "Naturelle",
        de: "",
        ka: "ბუნებრივი",
        fi: "Luonnollinen",
      },
    },
    {
      value: "mechanical",
      text: {
        default: "Mechanical",
        gr: "Μηχανικός",
        fr: "Mécanique",
        de: "",
        ka: "მექანიკური",
        fi: "Mekaaninen",
      },
    },
  ],
};

const s7q10 = {
  type: "radiogroup",
  name: "q1s7q10",
  title: {
    default: "Kitchen Ventilation?",
    gr: "Αερισμός(Εξαερισμός) Κουζίνας;",
    fr: "Ventilation de la cuisine ?",
    de: "",
    ka: "სამზარეულოს ვენტილაცია?",
    fi: "Keittiön ilmanvaihto?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q11 = {
  type: "radiogroup",
  name: "q1s7q11",
  visibleIf: "{q1s7q10} == 'yes'",
  title: {
    default: "Extraction? (Kitchen)",
    gr: "Εξαερισμός; (Κουζίνα)",
    fr: "Extraction? (Cuisine)",
    de: "",
    ka: "გამწოვი? (სამზარეულოს)",
    fi: "Ilmanpoisto? (Keittiö)",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "natural",
      text: {
        default: "Natural (windows)",
        gr: "Φυσικός(παράθυρα) ",
        fr: "Naturelle (fenêtre)",
        de: "",
        ka: "ბუნებრივი(ფანჯარა)",
        fi: "Ikkunat",
      },
    },
    {
      value: "passive",
      text: {
        default: "Passive stack extraction",
        gr: "Παθητικός εξαερισμός",
        fr: "Ventilation passive",
        de: "",
        ka: "მილებით, პასიურად",
        fi: "Painovoimainen ilmanvaihto",
      },
    },
    {
      value: "assisted",
      text: {
        default: "Assisted extraction",
        gr: "Υποβοηθούμενος εξαερισμός ",
        fr: "Ventilation dirigée",
        de: "",
        ka: "დამხ.მოწყობილობით",
        fi: "Avustettu ilmanpoisto",
      },
    },
    {
      value: "mechanical",
      text: {
        default: "Fully mechanical",
        gr: "Πλήρως μηχανικός",
        fr: "Ventilation mécanique",
        de: "",
        ka: "სრულად მექანიკური",
        fi: "Täysin mekaaninen ilmanpoisto",
      },
    },
  ],
};

const s7q12 = {
  type: "radiogroup",
  name: "q1s7q12",
  visibleIf: "{q1s7q10} == 'yes'",
  title: {
    default: "Intermittence? (Kitchen)",
    gr: "Περιοδικότητα? (Κουζίνα)",
    fr: "Intermittence? (Cuisine)",
    de: "",
    ka: "შეწყვეტა? (სამზარეულოს)",
    fi: "Ajoitus? (Keittiö)",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არასოდეს",
        fi: "Ei koskaan",
      },
    },
    {
      value: "permanent",
      text: {
        default: "Permanent",
        gr: "Μόνιμη",
        fr: "Continue",
        de: "",
        ka: "მუდმივად",
        fi: "Koko ajan",
      },
    },
    {
      value: "intermittent",
      text: {
        default: "Intermittent",
        gr: "Διαλείπουσα",
        fr: "Intermittent",
        de: "",
        ka: "დროებით",
        fi: "Ajoittain",
      },
    },
  ],
};

const s7q13 = {
  type: "radiogroup",
  name: "q1s7q13",
  title: {
    default: "Bathroom/Toilets Ventilation? ",
    gr: "Μπάνιο/Τουαλέτες Αερισμός (Εξαερισμός);",
    fr: "Ventilation de la salle de bain/toilettes ?",
    de: "",
    ka: "აბაზანა/ტუალეტი ვენტილაცია?",
    fi: "WC/kylpyhuone Ilmanvaihto?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q14 = {
  type: "radiogroup",
  name: "q1s7q14",
  visibleIf: "{q1s7q13} == 'yes'",
  title: {
    default: "Extraction? (Bathroom/Toilets)",
    gr: "Εξαερισμός; (Μπάνιο/Τουαλέτες)",
    fr: "Extraction? (Toilettes/Salle de bain)",
    de: "",
    ka: "გამწოვი? (აბაზანა/ტუალეტი)",
    fi: "Ilmanpoisto? (WC/kylpyhuone)",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "natural",
      text: {
        default: "Natural (windows)",
        gr: "Φυσικός(παράθυρα)",
        fr: "Naturelle (fenêtre)",
        de: "",
        ka: "ბუნებრივი(ფანჯარა)",
        fi: "Ikkunat",
      },
    },
    {
      value: "passive",
      text: {
        default: "Passive stack extraction",
        gr: "Παθητικός εξαερισμός",
        fr: "Ventilation passive",
        de: "",
        ka: "მილებით, პასიურად",
        fi: "Painovoimainen ilmanvaihto",
      },
    },
    {
      value: "assisted",
      text: {
        default: "Assisted extraction",
        gr: "Υποβοηθούμενος εξαερισμός",
        fr: "Ventilation dirigée",
        de: "",
        ka: "დამხ.მოწყობილობით",
        fi: "Avustettu ilmanpoisto",
      },
    },
    {
      value: "mechanical",
      text: {
        default: "Fully mechanical",
        gr: "Πλήρως μηχανικός",
        fr: "Ventilation mécanique",
        de: "",
        ka: "სრულად მექანიკური ",
        fi: "Täysin mekaaninen ilmanpoisto",
      },
    },
  ],
};

const s7q15 = {
  type: "radiogroup",
  name: "q1s7q15",
  visibleIf: "{q1s7q13} == 'yes'",
  title: {
    default: "Intermittence? (Bathroom/Toilets)",
    gr: "Περιοδικότητα; (Μπάνιο/Τουαλέτες)",
    fr: "Intermittence? (Toilettes/Salle de bain)",
    de: "",
    ka: "შეწყვეტა? (აბაზანა/ტუალეტი)",
    fi: "Ajoitus? (WC/kylpyhuone)",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არასოდეს ",
        fi: "Ei koskaan",
      },
    },
    {
      value: "permanent",
      text: {
        default: "Permanent",
        gr: "Μόνιμη",
        fr: "Continue",
        de: "",
        ka: "მუდმივად",
        fi: "Koko ajan",
      },
    },
    {
      value: "intermittent",
      text: {
        default: "Intermittent",
        gr: "Διαλείπουσα",
        fr: "Intermittent",
        de: "",
        ka: "დროებით",
        fi: "Ajoittain",
      },
    },
  ],
};

const s7q16 = {
  type: "radiogroup",
  name: "q1s7q16",
  title: {
    default: "Other Ventilation? ",
    gr: "Άλλος εξαερισμός?",
    fr: "Autre ventilation?",
    de: "",
    ka: "სხვა ვენტილაცია?",
    fi: "Muu Ilmanvaihto?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s7q17 = {
  type: "text",
  name: "q1s7q17",
  visibleIf: "{q1s7q16} == 'yes'",
  title: {
    default: "Specify: ",
    gr: "Διευκρινίστε: ",
    fr: "Si oui, précisez:",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, გთხოვთ დააკონკრეტოთ: ",
    fi: "Jos kyllä, tarkenna:",
  },
  isRequired: isNotRequired,
};

const s7q18 = {
  type: "radiogroup",
  name: "q1s7q18",
  visibleIf: "{q1s7q16} == 'yes'",
  title: {
    default: "Extraction? ",
    gr: "Εξαερισμός;",
    fr: "Extraction?",
    de: "",
    ka: "გამწოვი?",
    fi: "Ilmanpoisto?",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "natural",
      text: {
        default: "Natural (windows)",
        gr: "Φυσικός(παράθυρα)",
        fr: "Naturelle (fenêtre)",
        de: "",
        ka: "ბუნებრივი(ფანჯარა)",
        fi: "Ikkunat",
      },
    },
    {
      value: "passive",
      text: {
        default: "Passive stack extraction",
        gr: "Παθητικός εξαερισμός",
        fr: "Ventilation passive",
        de: "",
        ka: "მილებით, პასიურად",
        fi: "Painovoimainen ilmanvaihto",
      },
    },
    {
      value: "assisted",
      text: {
        default: "Assisted extraction",
        gr: "Υποβοηθούμενος εξαερισμός",
        fr: "Ventilation dirigée",
        de: "",
        ka: "დამხ.მოწყობილობით",
        fi: "Avustettu ilmanpoisto",
      },
    },
    {
      value: "mechanical",
      text: {
        default: "Fully mechanical",
        gr: "Πλήρως μηχανικός",
        fr: "Ventilation mécanique",
        de: "",
        ka: "სრულად მექანიკური",
        fi: "Täysin mekaaninen ilmanpoisto",
      },
    },
  ],
};

const s7q19 = {
  type: "radiogroup",
  name: "q1s7q19",
  visibleIf: "{q1s7q16} == 'yes'",
  title: {
    default: "Intermittence?",
    gr: "Περιοδικότητα;",
    fr: "Intermittence?",
    de: "",
    ka: "შეწყვეტა?",
    fi: "Ajoitus?",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არასოდეს ",
        fi: "Ei koskaan",
      },
    },
    {
      value: "permanent",
      text: {
        default: "Permanent",
        gr: "Μόνιμη",
        fr: "Continue",
        de: "",
        ka: "მუდმივად",
        fi: "Koko ajan",
      },
    },
    {
      value: "intermittent",
      text: {
        default: "Intermittent",
        gr: "Διαλείπουσα",
        fr: "Intermittent",
        de: "",
        ka: "დროებით",
        fi: "Ajoittain",
      },
    },
  ],
};

const s7q20 = {
  type: "checkbox",
  name: "q1s7q20",
  colCount: 3,
  title: {
    default: "Energy Sources for heating",
    gr: "Πηγές ενέργειας για θέρμανση",
    fr: "Sources d’énergie pour le chauffage",
    de: "",
    ka: "ენერგიის წყაროები, რომლებსაც გასათბობად იყენებთ",
    fi: "Energianlähteet lämmitykseen",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "electricity",
      text: {
        default: "Electricity ",
        gr: "Ηλεκτρικό",
        fr: "Électricité",
        de: "",
        ka: "ელექტრო ენერგია",
        fi: "Sähkö",
      },
    },
    {
      value: "district_heating",
      text: {
        default: "District heating",
        gr: "Κεντρική θέρμανση",
        fr: "Chauffage urbain",
        de: "",
        ka: "უბნის ცენტრალური გათბობა",
        fi: "Kaukolämpö",
      },
    },
    {
      value: "gpl",
      text: {
        default: "GPL gas",
        gr: "Υγραέριο",
        fr: "Gaz GPL",
        de: "",
        ka: "თხევადი აირი",
        fi: "GPL kaasu",
      },
    },
    {
      value: "natural gas",
      text: {
        default: "Natural Gas",
        gr: "Φυσικό αέριο",
        fr: "Gaz naturel",
        de: "",
        ka: "ბუნებრივი აირი",
        fi: "Maakaasu",
      },
    },
    {
      value: "oil_fuel",
      text: {
        default: "Oil, fuel",
        gr: "Λάδι, πετρέλαιο",
        fr: "Pétrole, fuel",
        de: "",
        ka: "ნავთი/მაზუთი",
        fi: "Öljy tai polttoaine",
      },
    },
    {
      value: "wood_charcoal",
      text: {
        default: "Wood, charcoal",
        gr: "Ξύλο, κάρβουνο",
        fr: "Bois, charbon de bois",
        de: "",
        ka: "შეშა/ხის ნახშირი",
        fi: "Puu tai puuhiili",
      },
    },
    {
      value: "solar_panels",
      text: {
        default: "Solar panels",
        gr: "Ηλιακοί συλλέκτες",
        fr: "Panneaux solaires",
        de: "",
        ka: "მზის პანელები",
        fi: "Aurinkopaneelit",
      },
    },
    {
      value: "other_biomass",
      text: {
        default: "Other biomass",
        gr: "Άλλες βιομάζες",
        fr: "Autre biomasse",
        de: "",
        ka: "სხვა ბიომასები",
        fi: "Muu biomassa",
      },
    },
  ],
};

const s7q21 = {
  type: "text",
  name: "q1s7q21",
  title: {
    default: "Total number of occupants: ",
    gr: "Συνολικός αριθμός διαμενόντων",
    fr: "Nombre total d’occupants ",
    de: "",
    ka: "სახლის მაცხოვრებლები",
    fi: "Talossa asuvien henkilöiden määrä",
  },
  inputType: "number",
  min: 0,
  isRequired: isNotRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 0 to 20",
      minValue: 0,
      maxValue: 20,
    },
  ],
};

const s7q22 = {
  type: "matrixdropdown",
  name: "q1s7q22",
  colCount: 5,
  title: {
    default: "Occupation period",
    gr: "Περίοδος διαμονής:",
    fr: "Période d’occupation ",
    de: "",
    ka: "მაცხოვრებლების რაოდენობა სხვადასხვა პერიოდში:",
    fi: "Asumisperiodi:",
  },
  isRequired: isNotRequired,
  columns: [
    {
      name: "Occupation period",
      title: {
        default: "Occupation period",
        gr: "Περίοδος διαμονής:",
        fr: "Période d’occupation ",
        de: "",
        ka: "მაცხოვრებლების რაოდენობა სხვადასხვა პერიოდში:",
        fi: "Asumisperiodi:",
      },
      choices: [
        {
          value: "morning",
          text: {
            default: "Morning",
            gr: "Πρωί",
            fr: "Matin",
            de: "",
            ka: "დილით",
            fi: "Aamu",
          },
        },
        {
          value: "noon",
          text: {
            default: "Noon",
            gr: "Μεσημέρι",
            fr: "Midi",
            de: "",
            ka: "შუადღით",
            fi: "Päivä",
          },
        },
        {
          value: "afternoon",
          text: {
            default: "Afternoon",
            gr: "Απόγευμα",
            fr: "Après-midi",
            de: "",
            ka: "ნაშუადღევს",
            fi: "Iltapäivä",
          },
        },
        {
          value: "evening",
          text: {
            default: "Evening",
            gr: "Αργά απόγευμα",
            fr: "Soir",
            de: "",
            ka: "საღამოს",
            fi: "Ilta",
          },
        },
        {
          value: "night",
          text: {
            default: "Night",
            gr: "Νύχτα",
            fr: "Nuit",
            de: "",
            ka: "ღამით",
            fi: "Yö",
          },
        },
      ],
      cellType: "checkbox",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "week",
      text: {
        default: "Week",
        gr: "Καθημερινές",
        fr: "Dans la semaine",
        de: "",
        ka: "სამუშაო კვირის მანძილზე",
        fi: "Viikko",
      },
    },
    {
      value: "weekend",
      text: {
        default: "Weekend",
        gr: "Σαββατοκύριακά",
        fr: "Pendant le weekend",
        de: "",
        ka: "შაბათ-კვირას",
        fi: "Viikonloppu",
      },
    },
  ],
};

export const elementsS7 = [
  s7q1,
  s7q2,
  s7q3,
  s7q4,
  s7q4a,
  s7q4b,
  s7q4c,
  s7q4d,
  s7q5a,
  s7q5b,
  s7q6,
  s7q7,
  s7q8,
  s7q9,
  s7q10,
  s7q11,
  s7q12,
  s7q13,
  s7q14,
  s7q15,
  s7q16,
  s7q17,
  s7q18,
  s7q19,
  s7q20,
  s7q21,
  s7q22,
];
