import {
  Admin,
  CreatePayload,
  Operator,
  ROLES,
  Role,
  Student,
  Teacher,
  User,
  hasFullname,
  hasSchools,
  isStudent,
  isTeacher,
} from "../types/user";
import { SchoolFormData, UserFormData } from "../types/formik";
import { School } from "../types/school";

export const getFormUser = (values: UserFormData): User => {
  let user: User;
  // console.log("schools: ", values.schools);

  console.log("User: ", values);
  switch (values.role) {
    case ROLES.OPERATOR:
      user = new Operator(values);
      break;
    case ROLES.TEACHER:
      user = new Teacher(values);
      break;
    case ROLES.STUDENT:
      user = new Student(values);
      if (user.schools !== undefined && user.schools[0] === "") {
        delete user["schools"];
      }
      break;
    default:
      user = new Admin(values);
      break;
  }

  Object.keys(user).forEach((key) => {
    if (
      (user[key as keyof User] === "" ||
        user[key as keyof User] === null ||
        user[key as keyof User] === undefined) &&
      key !== "garmin_id"
    ) {
      delete user[key as keyof User];
    }
  });

  return user;
};

export const getFullData = (
  user: User | null,
  role: Role,
  initSchools: string[] | string
): UserFormData => {
  // console.log(user);
  if (user !== null) {
    const fullname =
      hasFullname(user) && user.fullname !== null ? user.fullname : "";
    const email = user.email ? user.email : "";
    const username = user._id ? user._id : "";
    const schools =
      hasSchools(user) && user.schools !== null && user.schools !== undefined
        ? user.schools
        : [""];
    const teacher =
      isStudent(user) && user.teacher !== null ? user.teacher : "";
    const garmin =
      isStudent(user) && user.garmin_id !== null && user.garmin_id
        ? user.garmin_id
        : "";
    const code =
      isStudent(user) &&
      user.offline_code !== null &&
      user.offline_code !== undefined
        ? user.offline_code
        : "";
    const students = isTeacher(user) ? user.students : [];

    if (user.role === ROLES.OPERATOR) {
      return {
        fullname: fullname,
        username: username,
        password: "",
        email: email,
        role: user.role,
        schools: schools,
        students: [],
        teacher: "",
        garminId: "",
        uniqueId: "",
        active: user.active,
      };
    } else if (user.role === ROLES.TEACHER) {
      return {
        fullname: fullname,
        username: username,
        password: "",
        email: email,
        role: user.role,
        schools: schools[0],
        students: students,
        teacher: "",
        garminId: "",
        uniqueId: "",
        active: user.active,
      };
    } else if (user.role === ROLES.STUDENT) {
      return {
        fullname: "",
        username: username,
        password: "",
        email: email,
        role: user.role,
        schools: schools[0],
        students: [],
        teacher: teacher!,
        garminId: garmin,
        uniqueId: code,
        active: user.active,
      };
    } else {
      return {
        fullname: fullname,
        username: username,
        password: "",
        email: email,
        role: user.role,
        schools: [],
        students: [],
        teacher: "",
        garminId: "",
        uniqueId: "",
        active: user.active,
      };
    }
  } else
    return {
      fullname: "",
      username: "",
      password: "",
      email: "",
      role: role,
      schools: initSchools,
      students: [],
      teacher: "",
      garminId: "",
      uniqueId: "",
      active: true,
    };
};

export const getSchoolData = (school: School | null): SchoolFormData => {
  // console.log(school);
  if (school !== null) {
    return {
      sensor_box: school.sensor_box,
      title: school.title,
      description: school.description,
      country: school.country,
    };
  } else
    return {
      sensor_box: "",
      title: "",
      description: "",
      country: "",
    };
};

export const getFormSchool = (
  values: SchoolFormData,
  coordinates: number[],
  id?: string
): School => {
  if (id === undefined) {
    return {
      ...values,
      lat: coordinates[0],
      lon: coordinates[1],
      area: "",
      sensor_box: values.sensor_box,
    };
  } else {
    // console.log("here");
    return {
      ...values,
      school_id: id,
      lat: coordinates[0],
      lon: coordinates[1],
      area: "",
      sensor_box: values.sensor_box,
    };
  }
};
