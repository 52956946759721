import { isRequired } from "../../consts/required";
import { dontknow, no, nosymptoms, yes } from "../../consts/yes_no";

const s2q1 = {
  type: "radiogroup",
  name: "q4s2q1",
  title: {
    default: "Has your child ever had a diagnosis of allergic rhinitis?",
    gr: "Είχε ποτέ το παιδί σας διάγνωση αλλεργικής ρινίτιδας;",
    fr: "Votre enfant a-t-il déjà reçu un diagnostic de rhinite allergique ? ",
    de: "",
    ka: "დაუდგინდა თუ არა როდესმე თქვენს შვილს ალერგიული რინიტი?",
    fi: "Onko lapsellasi koskaan diagnosoitu allergista nuhaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};
const s2q2 = {
  type: "radiogroup",
  name: "q4s2q2",
  title: {
    default:
      "Has your child had symptoms of allergic rhinitis in the last 12 months?",
    gr: "Είχε το παιδί σας συμπτώματα αλλεργικής ρινίτιδας τους τελευταίους 12 μήνες;",
    fr: "Votre enfant a-t-il eu des symptômes de rhinite allergique au cours des 12 derniers mois ?",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე აღენიშნება თუ არა თქვენს შვილს ალერგიული რინიტის სიმტომები?",
    fi: "Onko lapsellasi ollut allergisen nuhan oireita viimeisten 12 kuukauden aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s2q3 = {
  type: "radiogroup",
  name: "q4s2q3",
  visibleIf: "{q4s2q2} == 'yes' ",
  colCount: 2,
  title: {
    default: "Allergic rhinitis symptoms are present:",
    gr: "Έχει το παιδί σας συμπτώματα αλλεργικής ρινίτιδας:",
    fr: "Des symptômes de rhinite allergique sont présents :",
    de: "",
    ka: "ალერგიული რინიტის სიმტომების გამოვლენის სიხშირეა:",
    fi: "Allergisen nuhan oireita esiintyy:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4days/week",
        gr: "<4ημέρες/εβδομάδα",
        fr: "< 4 jours/semaine ",
        de: "",
        ka: "<4დღე/კვირაში",
        fi: "<4 päivää viikossa",
      },
      value: "less_than_4d_pw",
    },
    {
      text: {
        default: ">4 days/week",
        gr: ">4 ημέρες/εβδομάδα",
        fr: "> 4 jours/semaine",
        de: "",
        ka: ">4 დღე/კვირაში",
        fi: ">4 päivää/viikko",
      },
      value: "more_than_4d_pw",
    },
    // nosymptoms,
  ],
};

const s2q4 = {
  type: "radiogroup",
  name: "q4s2q4",
  visibleIf: "{q4s2q2} == 'yes' ",
  colCount: 2,
  title: {
    default: "Allergic rhinitis symptoms are present:",
    gr: "Έχει το παιδί σας συμπτώματα αλλεργικής ρινίτιδας:",
    fr: "Des symptômes de rhinite allergique sont présents :",
    de: "",
    ka: "ალერგიული რინიტის სიმპტომები ვლინდება:",
    fi: "Allergisen nuhan oireita esiintyy:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "<4 consecutive weeks",
        gr: "<4 συνεχόμενες εβδομάδες",
        fr: "< 4 semaines consécutives ",
        de: "",
        ka: "<4 კვირის მანძილზე ზედიზედ",
        fi: "<4 peräkkäistä viikkoa",
      },
      value: "less_than_4_cw",
    },
    {
      text: {
        default: ">4 consecutive weeks",
        gr: ">4 συνεχόμενες εβδομάδες",
        fr: "> 4 semaines consécutives",
        de: "",
        ka: ">4 კვირის მანძილზე ზედიზედ",
        fi: ">4 peräkkäistä viikkoa",
      },
      value: "more_than_4_cw",
    },
    // nosymptoms,
  ],
};

const s2q5 = {
  type: "matrix",
  name: "q4s2q5",
  visibleIf: "{q4s2q2} == 'yes' ",
  isRequired: isRequired,
  title: {
    default: "Are allergic rhinitis symptoms of your child associated with?",
    gr: "Τα αλλεργικά συμπτώματα του παιδιού σας σχετίζονται με",
    fr: "Les symptômes de la rhinite allergique de votre enfant sont-ils associés à :",
    de: "",
    ka: "უკავშირდება თუ არა თქვენი შვილის ალერგიული რინიტი შემდეგს? ",
    fi: "Liittyykö lapsesi allergisen nuhan oireisiin?",
  },
  columns: [yes, no, dontknow],
  rows: [
    {
      value: "sleep_disturbance",
      text: {
        default: "Sleep disturbance:",
        gr: "Διαταραχές στον ύπνο:",
        fr: "Un trouble du sommeil ?",
        de: "",
        ka: "ძილის დარღვევა:",
        fi: "Unihäiriöt:",
      },
    },
    {
      value: "impairment_of_daily_activities",
      text: {
        default: "Impairment of daily activities, leisure and/or sport:",
        gr: "Περιορισμό καθημερινών δραστηριοτήτων, ελευθερου χρόνου ή/και αθλημάτων:",
        fr: "Une altération des activités quotidiennes, des loisirs et/ou du sport ?",
        de: "",
        ka: "ყოველდღიური აქტივობებისგან, გასართობი და/ან სპორტული აქტივობებისგან თავის არიდება",
        fi: "Päivittäisen toiminnan, vapaa-ajan ja/tai urheilun heikentyminen:",
      },
    },
    {
      value: "impairment_of_school",
      text: {
        default: "Impairment of school:",
        gr: "Περιορισμό στο σχολείο:",
        fr: "Une altération de l’apprentissage à l’école ?",
        de: "",
        ka: "სკოლის გაცდენა:",
        fi: "Koulun heikkeneminen:",
      },
    },
  ],
  alternateRows: true,
  // isAllRowRequired: true,
};

const s2q6 = {
  name: "q4s2q6",
  type: "radiogroup",
  visibleIf: "{q4s2q2} == 'yes' ",
  title: {
    default:
      "Has your child received any treatment for allergic rhinitis, in the last 3 months:",
    gr: "Έχει λάβει το παιδί σας οποιαδήποτε θεραπεία για αλλεργική ρινίτιδα, τους τελευταίους 3 μήνες: ",
    fr: "Votre enfant a-t-il reçu des traitements pour la rhinite allergique aux cours des dernier 3 mois ?	",
    de: "",
    ka: "ბოლო 3 თვის განმავლობაში ხომ არ მიუღია თქვენს შვილს ალერგიული რინიტის სამკურნალო საშუალებები:",
    fi: "Onko lapsesi saanut hoitoa allergiseen nuhaan viimeisten 3 kuukauden aikana:",
  },
  choices: [yes, no],
  isRequired: isRequired,
};

const s2q6a = {
  type: "multipletext",
  name: "q4s2q6a",
  visibleIf: "{q4s2q2} == 'yes' and {q4s2q6} == 'yes'",
  title: {
    default: "If yes, on average how many days has your child received:",
    gr: "Αν ναι, πόσες μέρες κατά μέσο όρο έλαβε το παιδί σας:",
    fr: "Si oui, combien de jour a-t-il/elle été traité(e) par",
    de: "",
    ka: "ანტიჰისტამინური პრეპარატები [დღე]",
    fi: "Jos kyllä, kuinka monta päivää lapsesi on saanut:",
  },
  isRequired: isRequired,
  items: [
    {
      name: "oral_antistamines",
      title: {
        default: "Oral antihistamines [days]",
        gr: "Αντιισταμινικά από το στόμα (σιρόπι, χάπια) [μέρες]",
        fr: "Des antihistaminiques (jours)",
        de: "",
        ka: "ანტიჰისტამინური პრეპარატები [დღე]",
        fi: "Suun kautta otettavia antihistamiineja [päivää]",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 90,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 90",
          minValue: 1,
          maxValue: 90,
        },
      ],
    },
    {
      name: "nasal_sprays",
      title: {
        default: "Nasal sprays (Intranasal corticosteroids) [days]",
        gr: "Σπρεί για τη μύτη (ενδορινικά κορτικοστεροειδή) [μέρες]",
        fr: "Des sprays nasaux (corticoïdes nasaux) [jours]",
        de: "",
        ka: "ნაზალური სპრეი (ინტრანაზალური კორტიკოსტეროიდები) [დღე]",
        fi: "Nenäsumutteita (intranasaaliset kortikosteroidit) [päivää]",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 90,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 90",
          minValue: 1,
          maxValue: 90,
        },
      ],
    },
  ],
};

const s2q7 = {
  name: "q4s2q7",
  type: "radiogroup",
  visibleIf: "{q4s2q2} == 'yes' ",
  title: {
    default: " Is your child receiving Allergen Immunotherapy?",
    gr: "Λαμβάνει το παιδί σας ανοσοθεραπεία; ",
    fr: "Votre enfant est-il en cours de traitement par une immunothérapie allergénique (désensibilisation) ?",
    de: "",
    ka: "ხომ არ იღებს თქვენი შვილი იმუნოთერაპიას?",
    fi: "Saako lapsesi allergeeni-immunoterapiaa?",
  },
  choices: [yes, no],
  isRequired: isRequired,
};

const s2q7a = {
  name: "q4s2q7a",
  type: "text",
  visibleIf: "{q4s2q2} == 'yes' and {q4s2q7} == 'yes' ",
  title: {
    default: "If yes, how long?",
    gr: " Εάν ναι, πόσο καιρό; ",
    fr: "Si oui, depuis combien de temps ? ",
    de: "",
    ka: "თუ კი, რამდენი ხანი?",
    fi: "Jos kyllä, kuinka kauan?",
  },
  isRequired: isRequired,
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 5,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 5",
      minValue: 1,
      maxValue: 5,
    },
  ],
};

export const elementsS2 = [
  s2q1,
  s2q2,
  s2q3,
  s2q4,
  s2q5,
  s2q6,
  s2q6a,
  s2q7,
  s2q7a,
];
