import { useEffect, useRef, useState } from "react";
import mapboxgl, { GeoJSONSourceRaw, LngLat } from "mapbox-gl";
import { Feature } from "geojson";
import styles from "../styles/Map.module.scss";
import { MAP_TOKEN } from "../consts/map";
import marker from "../assets/school-icon.png";

//Transpiling error for mapbox
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = MAP_TOKEN; //process.env.NEXT_PUBLIC_MAP_TOKEN || "";

type MapProps = {
  schools: Feature[];
  viewOnly?: boolean;
  setCoordinates?: (coordinates: Array<number>) => void;
};

const MapView: React.FC<MapProps> = ({
  schools,
  viewOnly = true,
  setCoordinates,
}) => {
  const mapContainer = useRef<any>(null);
  // const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(23.727539);
  const [lat, setLat] = useState(37.98381);
  const [zoom, setZoom] = useState(5);
  const marker = new mapboxgl.Marker();

  const geojsondata: GeoJSONSourceRaw = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: schools,
    },
  };

  // console.log(geojsondata);
  // useEffect(() => {
  //   if (map.current) return; // initialize map only once

  //   map.current = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: "mapbox://styles/mapbox/streets-v12",
  //     center: [lng, lat],
  //     zoom: zoom,
  //   });
  // });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    map.on("style.load", function () {
      map.on("click", function (e) {
        if (!viewOnly) {
          const coordinates = e.lngLat;
          // new mapboxgl.Popup()
          //   .setLngLat(coordinates)
          //   .setHTML('Lat: ' + coordinates.lat + '<br/> Lon: ' + coordinates.lng)
          //   .addTo(map);

          marker.setLngLat(coordinates).addTo(map);

          //marker.remove();

          if (setCoordinates !== undefined)
            setCoordinates([coordinates.lat, coordinates.lng]);
        }
      });
      map.on("click", "places", function (e) {
        if (
          e.features &&
          e.features[0] !== null &&
          e.features[0].properties !== null
        ) {
          // console.log(e.features);
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(e.features[0].properties.title)
            .addTo(map);
        }
      });
    });

    map.on("load", function () {
      // Add an image to use as a custom marker
      // console.log("Image");
      map.loadImage(
        `${process.env.PUBLIC_URL}/school-icon.png`,
        function (error, image) {
          if (error) throw error;
          if (image !== undefined) map.addImage("custom-marker", image);
          // Add a GeoJSON source with multiple places
          // console.log("image?");
          map.addSource("places", geojsondata);
          // Add a symbol layer
          map.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "custom-marker",
              "icon-size": 0.25,
              // get the title name from the source's "title" property
              // "text-field": ["get", "title"],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 1.25],
              "text-anchor": "top",
            },
          });
        }
      );
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on("mouseenter", "places", () => {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", "places", () => {
      map.getCanvas().style.cursor = "";
    });

    // Clean up on unmount
    return () => map.remove();
  }, []);

  // useEffect(() => {
  //   if (!map.current) return; // wait for map to initialize
  //   map.current.on("move", () => {
  //     setLng(map.current.getCenter().lng.toFixed(4));
  //     setLat(map.current.getCenter().lat.toFixed(4));
  //     setZoom(map.current.getZoom().toFixed(2));
  //   });
  // });

  return <div ref={mapContainer} className={styles.map_container} />;
};
export default MapView;
