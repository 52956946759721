import students from "../../assets/tutorial/analytics/students.png";
import students2 from "../../assets/tutorial/analytics/students2.png";
import parents from "../../assets/tutorial/analytics/parents.png";
import parents2 from "../../assets/tutorial/analytics/parents2.png";
import games from "../../assets/tutorial/analytics/games.png";
import games2 from "../../assets/tutorial/analytics/games2.png";

export const analyticsTutorialData = [
  {
    imageSrc: students,
    subtitle: "Students Analytics",
    description: [
      "In the students tab, a table is presented listing all answered health questionnaires and the answers.",
      "In the top right corner of the table, you'll find a button to export the answered health questionnaires.",
    ],
  },
  {
    imageSrc: students2,
    subtitle: "Students Export",
    description: [
      "The Export button opens a prompt that allows you to select the country for exporting student questionnaires and specify the desired time period.",
      "Please note that in order to receive the export, you must have an email registered to your account.",
    ],
  },
  {
    imageSrc: parents,
    subtitle: "Parents Analytics",
    description: [
      "In the parents tab, a table is presented listing all answered questionnaires. ",
      "In the top left corner of the table, you can select the specific questionnaire for which you wish to view the details.",
      "In the top right corner of the table, you'll find a button to export the answered parent questionnaires.",
    ],
  },
  {
    imageSrc: parents2,
    subtitle: "Parents Export",
    description: [
      "The Export button opens a prompt that allows you to choose which questionnaires you wish to export, select the country for exporting parent questionnaires and specify the desired time period.",
      "Please note that in order to receive the export, you must have an email registered to your account.",
    ],
  },
  {
    imageSrc: games,
    subtitle: "Games Analytics",
    description: [
      "In the games tab, a table is presented listing all game scores for the games played by the students.",
      "In the top right corner of the table, you'll find a button to export the game scores.",
    ],
  },
  {
    imageSrc: games2,
    subtitle: "Games Export",
    description: [
      "The Export button opens a prompt that allows you to select the country for exporting student game scores and specify the desired time period.",
      "Please note that in order to receive the export, you must have an email registered to your account.",
    ],
  },
];
