import { Button, ListItemButton, ListItemText  } from "@mui/material";
import { styled } from "@mui/material";
import variables from '../../styles/variables.module.scss';
import { ElementType } from "react";

export const StyledListButton = styled(ListItemButton)<IStyledListItemButton>(
  () => {
    return {
     color: variables.sidenav_item_icon_color,
     "&:hover": {
      backgroundColor: variables.color_primary_hover,
      color: variables.color_primary,
    },
    };
  }
);

export const StyledListText = styled(ListItemText)(
  () => {
    return {
     color: variables.white,
    };
  }
);

interface IStyledListItemButton {
  component: ElementType;
  to: string // or to
}