import { Box, Container, Toolbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { User, UserSchema } from "../../../types/user";
import { Column } from "../../../types/table";
import { TableBar } from "../../../components/styled/AppBar";
import { AddOne, StyledIconButton } from "../../../components/styled/AddButton";
import CustomTable from "../../../components/Table/CustomTable";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectUsers,
  selectUsersLoading,
} from "../../../store/selectors/userSelector";
import { getUsers } from "../../../store/effects/userEffects";
import { removeUser } from "../../../store/actions/userActions";
import { deleteUser, deleteUserPerm } from "../../../services/api/users.api";
import { getRole } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";
import { PermissionControl } from "../../../components/Permission";
import { usePathname } from "../../../hooks/usePathname";
import { Outlet, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/Alert";
import { handleApiError } from "../../../utils/handleApiError";

const Users = () => {
  const role = getRole();
  const USER_TEXT = "User";
  const data = useAppSelector(selectUsers);
  const isLoading = useAppSelector(selectUsersLoading);
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const router = useNavigate();
  const [alert, setAlert] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [rowDel, setRowDel] = useState<User | undefined>();
  const [rowRem, setRowRem] = useState<User | undefined>();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(getActiveUser(null));
  //   };
  // }, [dispatch]);

  const handleRemoveDelete = () => {
    if (rowRem) {
      const row = rowRem;
      console.log(row);
      deleteUser(row)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUser(row._id!));
            setRowRem(undefined);
          }
        })
        .catch((e) => {
          handleApiError(e, setError);
        });
    } else if (rowDel) {
      const row = rowDel;
      console.log(row);
      deleteUserPerm(row)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUser(row._id!));
            setRowDel(undefined);
          }
        })
        .catch((e) => {
          handleApiError(e, setError);
        });
    }
  };

  const ProcessRows = (data: User[]): UserSchema<User>[] => {
    return data.map((row) => {
      const actions = [
        {
          label: "Edit",
          action: (e: React.MouseEvent<HTMLElement>) => {
            router(`${pathname}/${row._id}/`);
          },
          show: role ? permissions.pages.users.update.includes(role) : false,
        },
        {
          label: "Remove",
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row._id);
            setAlert(
              `User ${row._id} will be removed from dashboard. Their data will remain in storage. Do you wish to proceed?`
            );
            setRowRem(row);
          },
          show: role ? permissions.pages.users.delete.includes(role) : false,
        },
        {
          label: "Delete",
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row);
            setAlert(
              `This user and its data will be deleted permanently. Do you wish to permanently delete ${row._id}?`
            );
            setRowDel(row);
          },
          show: role ? permissions.pages.users.delete.includes(role) : false,
        },
      ];

      !actions.some((action) => action.show === true) &&
        actions.push({
          label: "No Available Actions",
          action: (e: React.MouseEvent<HTMLElement>) => {
            return;
          },
          show: true,
        });

      return { ...row, actions };
    });
  };

  const columns: Column<User, keyof User>[] = [
    // { name: "ID", key: "_id" },
    { name: "USERNAME", key: "_id" },
    { name: "EMAIL", key: "email" },
    { name: "ROLE", key: "role" },
  ];

  const handleClickToCreate = () => {
    router(`${pathname}/create${USER_TEXT}`);
  };

  const UsersTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>{`${USER_TEXT}s`}</h2>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <Tooltip title={`Create New ${USER_TEXT}`} placement='bottom'>
              <StyledIconButton
                aria-label='add'
                onClick={() => handleClickToCreate()}
                edge='end'
              >
                <AddOne />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  // console.log("Data: ", data);
  // console.log("Columns: ", columns);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth='xl'>
      <PermissionControl
        permissionPath={permissions.pages.users.access}
        role={role}
      >
        <CustomTable
          toolbar={UsersTableToolbar}
          rows={ProcessRows(data)}
          columns={columns}
        />
      </PermissionControl>
      <Outlet />
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={
            error
              ? [
                  {
                    label: "Ok",
                    onClick: () => {
                      setError(undefined);
                    },
                  },
                ]
              : [
                  {
                    label: "Ok",
                    onClick: () => {
                      setAlert(undefined);
                      handleRemoveDelete();
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {
                      setAlert(undefined);
                      setRowDel(undefined);
                      setRowRem(undefined);
                    },
                    outlined: true,
                  },
                ]
          }
        />
      )}
    </Container>
  );
};

export default Users;
