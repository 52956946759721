import { createTheme, lighten } from "@mui/material";
import variables from "../styles/variables.module.scss";

export const theme = createTheme({
  palette: {
    primary: {
      main: `${variables.color_primary}`,
      light: lighten(variables.color_primary, 0.8),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // components: {
  //   MuiFormControl: {
  //     styleOverrides: {
  //       root: {
  //         background : 'red',
  //       },
  //     },
  //   },
  // }
});
