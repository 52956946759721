import { Padding } from "@mui/icons-material";
import { Theme } from "@mui/material";

export const styles = {
  contentBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingX: 8,
    paddingY: 4,
    gap: 2,
  },
  subtitle: {
    marginTop: 2,
    marginBottom: 2,
    alignSelf: "flex-start",
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 2,
  },
  description: {
    textAlign: "justify",
    padding: 2,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500],
  },
};
