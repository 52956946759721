import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { dontknow, no, nosymptoms, yes } from "../../consts/yes_no";

const s1q1 = {
  type: "radiogroup",
  name: "q4s1q1",
  colCount: 3,
  title: {
    default:
      "How often did your child have asthma symptoms during the day, in the last 3 months",
    gr: "Πόσο συχνά είχε το παιδί σας συμπτώματα από το άσθμα τους τελευταίους 3 μήνες",
    fr: "À quelle fréquence votre enfant a-t-il/elle eu des symptômes d'asthme pendant la journée, au cours des 3 derniers mois",
    de: "",
    ka: "ბოლო 3 თვის განმავლობაში, რამდენად ხშირად უვლინდებოდა თქვენს შვილს ასთმის სიმპტომები?",
    fi: "Kuinka usein lapsellasi oli astmaoireita päivän aikana viimeisten 3 kuukauden aikana:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "< 1/week",
        gr: "< 1/εβδομάδα",
        fr: "<1 fois par semaine",
        de: "",
        ka: "< 1/კვირაში",
        fi: "< 1/vko",
      },
      value: "less_than_1_pw",
    },
    {
      text: {
        default: ">1week but <1/day",
        gr: "1/εβδομάδα αλλά <1/ημέρα",
        fr: "> 1 fois par semaine, mais < 1 fois par jour ",
        de: "",
        ka: ">1კვირაში, მაგრამ <1/დღის მანძილზე",
        fi: "> 1/vko mutta < 1/pv",
      },
      value: "more_than_1_pw",
    },
    {
      text: {
        default: "Daily",
        gr: "Καθημερινά",
        fr: "Tous les jours",
        de: "",
        ka: "ყოველდღიურად",
        fi: "päivittäin",
      },
      value: "daily",
    },
    nosymptoms,
  ],
};

const s1q2 = {
  type: "radiogroup",
  name: "q4s1q2",
  colCount: 3,
  title: {
    default:
      "How often did your child have asthma symptoms during the night,in the last 3 months",
    gr: "Πόσο συχνά είχε το παιδί σας συμπτώματα από το άσθμα κατά τη διάρκεια της νύχτας τους τελευταίους 3 μήνες",
    fr: "À quelle fréquence votre enfant a-t-il/elle eu des symptômes d'asthme pendant la nuit, au cours des 3 derniers mois",
    de: "",
    ka: " ბოლო 3 თვის განმავლობაში, რამდენად ხშირად უვლინდებოდა თქვენს შვილს ასთმის სიმპტომები ღამით",
    fi: "Kuinka usein lapsellasi oli astmaoireita yön aikana viimeisten 3 kuukauden aikana",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 times/month",
        gr: "≤2  φορές/μήνα",
        fr: "≤ 2 fois/mois",
        de: "",
        ka: "≤2 -ჯერ/თვეში",
        fi: "≤ 2 krt/kk",
      },
      value: "less_than_2_pm",
    },
    {
      text: {
        default: ">2 times/month",
        gr: ">2 φορές/μήνα",
        fr: "> 2 fois /mois",
        de: "",
        ka: ">2 -ჯერ/თვეში",
        fi: ">2 krt/kk",
      },
      value: "more_than_2_pm",
    },
    {
      text: {
        default: ">1week",
        gr: ">1/εβδομάδα",
        fr: "> 1 fois/semaine",
        de: "",
        ka: ">1/კვირაში",
        fi: ">1/vko",
      },
      value: "more_than_1_pw",
    },
    {
      text: {
        default: "Almost daily",
        gr: "Σχεδόν καθημερινά",
        fr: "Presque tous les jours",
        de: "",
        ka: "თითქმის ყოველ ღამით",
        fi: "lähes päivittäin",
      },
      value: "daily",
    },
    nosymptoms,
  ],
};

const s1d1 = {
  type: "html",
  name: "subheading",
  html: {
    default:
      "<p>What treatment did your child use for his/her asthma the last 3 months:</p>",
    gr: "<p>Τι θεραπεία χρησιμοποιήσε το παιδί σας για το άσθμα του τους τελευταίους 3 μήνες</p>",
    fr: "<p>Quel(s) traitement(s) votre enfant a-t-il/elle utilisé pour l’asthme, au cours des 3 derniers mois ?</p>",
    de: "<p></p>",
    ka: "<p>ბოლო 3 თვის განმავლობაში, რა სახის მკურნალობას იღებდა თქვენი შვილი ასთმის სამკურნალოდ?</p>",
    fi: "<p> Mitä hoitoa lapsesi on käyttänyt astmaansa viimeisten 3 kuukauden aikana</p>",
  },
};

const s1q3 = {
  type: "radiogroup",
  name: "q4s1q3",
  title: {
    default:
      "Relievers /rescue treatment (Salbutamol/Salamol/Aerolin/Ventolin):",
    gr: "Θεραπεία ανακουφιστική /διάσωσης (Aerolin-το 'μπλε')",
    fr: "Traitement de secours/crise (Ventoline, Airomir, Ventilastin…)",
    de: "",
    ka: "გადაუდებელი ჩარევისთვის საჭირო, სიმპტომების სწრაფად მოხნისთვის განკუთვნილ საშუალებებს (აეროლინი/ვენტოლონი):",
    fi: "Lievityslääkkeet/varahoito (Aerolin/Ventolin):",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q4 = {
  type: "radiogroup",
  name: "q4s1q4",
  visibleIf: "{q4s1q3} == 'yes' ",
  colCount: 2,
  title: {
    default: "If yes how often:",
    gr: "Εάν ναι πόσο συχνά:",
    fr: "Si oui, avec quelle fréquence",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, რა სიხშირით?",
    fi: "Jos kyllä, kuinka usein:",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "≤2 days/week",
        gr: "≤2 ημέρες/εβδομάδα",
        fr: "≤2 jours/semaine",
        de: "",
        ka: "≤2 დღე/კვირაში",
        fi: "≤2 päivää/viikko",
      },
      value: "less_than_2d_pw",
    },
    {
      text: {
        default: ">2days/week ",
        gr: ">2ημέρες /εβδομάδα",
        fr: ">2 jours/semaine",
        de: "",
        ka: ">2დღე/ კვირაში",
        fi: ">2 päivää/viikko",
      },
      value: "more_than_2d_pw",
    },
  ],
};

const s1q5 = {
  type: "radiogroup",
  name: "q4s1q5",
  title: {
    default: "Daily controller/preventive medication:",
    gr: "Καθημερινή θεραπεία συντήρησης-ελέγχου:",
    fr: "Traitement de fond",
    de: "",
    ka: "სიმპტომების კონტროლისთვის განკუთვნილ ყოველდღიურ საშუალებებს?",
    fi: "Päivittäinen kontrollilääkitys",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q6 = {
  type: "matrixdropdown",
  name: "q4s1q6",
  visibleIf: "{q4s1q5} == 'yes' ",
  isRequired: isRequired,
  title: {
    default: "If yes, please specify below",
    gr: "Εάν ναι, παρακαλώ προσδιορίστε παρακάτω",
    fr: "Si oui, spécifiez le médicament :",
    de: "",
    ka: "თუ თქვენი პასუხია“კი“,  ქვემოთ მოცემულთაგან მონიშნეთ შესაბამისი მედიკამენტები",
    fi: "Jos vastasitte kyllä, tarkentakaa alla:",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    {
      name: "daily_dose",
      title: {
        default: "Daily dose (Please write down what you remember eg Dose in grams, puffs, how many times a day) ",
        gr: "Καθημερινή δόση (Παρακαλώ σημειώστε ό,τι θυμάστε π.χ Δόση σε γραμμάρια, εισπνοές(puffs), πόσες φορές την ημέρα)",
        fr: "Dose journalière (Indiquez ce dont vous vous souvenez, comme la dose en mg, le nombre de pulvérisation, combien de fois par jour…)",
        de: "",
        ka: "დღიური დოზა (გთხოვთ, მიუთითოთ გაც გახსოვთ, მაგ. დოზა გრამებში, შესხურების რაოდენობა, დღეში რამდენჯერ)",
        fi: "Vuorokausiannos (Kirjoita muistiin mitä muistat esim. Annos grammoina, hengityksiä, kuinka monta kertaa päivässä)",
      },
      cellType: "text",
      min: 0,
      isRequired: isRequired,
    },
  ],
  rows: [
    {
      value: "inhaled_corticosteroid",
      text: {
        default: "Inhaled corticosteroid (clenil/flixotide/seretide)",
        gr: "Εισπνεόμενα κορτικοστεροειδή (flixotide - το 'πορτοκαλί')",
        fr: "Corticoïde inhalé (Flixotide)",
        de: "",
        ka: "საინჰალაციო კორტიკოსტეროიდები (ფლიქსოტიდი)",
        fi: "Inhaloitava kortikosteroidi (flixotide/seretide)",
      },
    },
    {
      value: "leukotriene",
      text: {
        default: "Leukotriene modifier (modulair/singulair)",
        gr: "Αναστολείς λευκοτριενίων (modulair/singulair)",
        fr: "Anti-Leukotriène (Singulair/Montelukast)",
        de: "",
        ka: "ლეიკოტრიენების მოდიფიკატორები (მოდულეარი/სინგულარი)",
        fi: "Leukotrieenimodifioija (modulair/singulair)",
      },
    },
    {
      value: "corticosteroids",
      text: {
        default:
          "Combination of corticosteroids and long-acting b2 agonist (seretide, symbicort)",
        gr: "Συνδυασμός κορτικοστεροειδών και β2 διεγέρτες μακράς δράσης (seretide, symbicort, duobreathe)",
        fr: "Association fixe (Seretide, Symbicort, Gibiter)",
        de: "",
        ka: "კორტიკოსტეროიდებისა და გახანგრძლივებული მოქმედების ბეტა2 აგონისტები(სერეტიდი,სიმბიკორტი)",
        fi: "Kortikosteroidien ja pitkävaikutteisen b2-agonistin yhdistelmä (seretide, symbicort)",
      },
    },
    {
      value: "other",
      text: {
        default: "Other medication",
        gr: "Άλλο φάρμακο",
        fr: "Autre médicament",
        de: "",
        ka: "სხვა წამალი",
        fi: "Muita lääkkeitä",
      },
    },
  ],
};

const s1q6a = {
  type: "text",
  name: "q4s1q6a",
  visibleIf: "{q4s1q6.other.daily_dose} > 0 ",
  title: {
    default: "Please describe:",
    gr: "Εάν Ναι, παρακαλώ περιγράψτε:",
    fr: "Si oui, veuillez décrire:",
    de: "",
    ka: "გთხოვთ დააკონკრეტოთ:",
    fi: "Jos kyllä, kuvaile:",
  },
  maxLength: 100,
  isRequired: isRequired,
};

const s1q7 = {
  type: "radiogroup",
  name: "q4s1q7",
  title: {
    default: "Are your child’s activities limited by asthma symptoms?",
    gr: "Περιορίζονται οι δραστηριότητες του παιδιού σας από τα συμπτώματα του άσθματος; ",
    fr: "Les activités de votre enfant sont-elles limitées par des symptômes d'asthme ?",
    de: "",
    ka: "ზღუდება თუ არა თქვენი შვილის ფიზიკური აქტიურობა ასთმის გამო?",
    fi: "Rajoittavatko astman oireet lapsesi toimintaa?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q8 = {
  type: "radiogroup",
  name: "q4s1q8",
  title: {
    default: "Is your child completely well between symptomatic periods?",
    gr: "Μεταξύ των επεισοδίων είναι το παιδί σας τελείως καλά;",
    fr: "Votre enfant va-t-il bien entre les périodes symptomatiques ",
    de: "",
    ka: "გრძნობს თუ არა თქვენი შვილი თავს სრულებით კარგად სიმპტომების გამწვავებებს შორის პერიოდებში?",
    fi: "Voiko lapsesi täysin hyvin oireellisten periodien välillä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q9 = {
  name: "q4s1q9",
  type: "text",
  title: {
    default:
      "How many episodes of wheezing/asthma/cough did your child had in the last 3 months?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 3 μήνες;",
    fr: "Combien d'épisodes de respiration sifflante/d'asthme/de toux votre enfant a-t-il eu au cours des 3 derniers mois ?",
    de: "",
    ka: "ბოლო 3 თვის მანძილზე  რამდენი ეპიზოდი ჰქონდა თქვენს შვილს?",
    fi: "Kuinka monta hengityksen vinkumista/astmaa/yskää lapsellasi oli viimeisen 3 kuukauden aikana?",
  },
  inputType: "number",
  min: 0,
  defaultValue: 0,
  isRequired: isRequired,
};

const s1q10 = {
  name: "q4s1q10",
  type: "text",
  title: {
    default:
      "How many episodes of wheezing/asthma/cough did your child had in the last 12 months?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 12 μήνες;",
    fr: "Combien d'épisodes de respiration sifflante/d'asthme/de toux votre enfant a-t-il eu au cours des 12 derniers mois ? ",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე  ხიხინის/ასთმის/ხველისრამდენი ეპიზოდი ჰქონდა თქვენს შვილს?",
    fi: "Kuinka monta hengityksen vinkumista/astmaa/yskää lapsellasi oli viimeisen 12 kuukauden aikana?",
  },
  inputType: "number",
  min: 0,
  defaultValue: 0,
  isRequired: isRequired,
};

const s1q11 = {
  name: "q4s1q11",
  type: "text",
  title: {
    default:
      "How many hospitalizations for wheezing/asthma/cough did your child had in the last 12 months?",
    gr: "Πόσες νοσηλείες για «βράσιμο» ή σφύριγμα/άσθμα/βήχα είχε το παιδί σας τους τελευταίους 12 μήνες;",
    fr: "Combien d'hospitalisations pour respiration sifflante/asthme/toux votre enfant a-t-il eu au cours des 12 derniers mois ?",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე  ხიხინის/ასთმის/ხველის გამო რამდენჯერ დასჭირდა თქვენს შვილს ჰოსპიტალიზაცია? ",
    fi: "Kuinka monta sairaalahoitoa hengityksen vinkumisen/astman/yskän vuoksi lapsella on ollut viimeisen 12 kuukauden aikana?",
  },
  inputType: "number",
  min: 0,
  defaultValue: 0,
  isRequired: isRequired,
};
const s1q12 = {
  type: "radiogroup",
  name: "q4s1q12",
  title: {
    default:
      "Is there any mold or humidity in the house where your child lives?",
    gr: "Υπάρχει καθόλου μούχλα ή υγρασία στο σπίτι που μένει το παιδί σας; ",
    fr: "Y a-t-il de la moisissure ou de l'humidité dans la maison où vit votre enfant ?",
    de: "",
    ka: " სახლში, სადაც თქვენი შვილი ცხოვრობს, აღინიშნება თუ არა ობის ან ნესტის არსებობა?",
    fi: "Onko talossa, jossa lapsesi asuu, hometta tai kosteutta?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};
const s1q13 = {
  type: "radiogroup",
  name: "q4s1q13",
  visibleIf: "{q4s1q12} == 'yes' ",
  title: {
    default: "If yes, does this affect child’s symptoms?",
    gr: "Εάν ναι, επηρεάζει τα συμπτώματα του παιδιού σας;",
    fr: "Si oui, cela affecte-t-il les symptômes de votre enfant ?",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ახდენს თუ არა ეს გავლენას თქვენი შვილის სიმპტომებზე?",
    fi: "Jos kyllä, vaikuttaako tämä lapsen oireisiin?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q14 = {
  type: "tagbox",
  name: "q4s1q14",
  isRequired: isRequired,
  title: {
    default: "If yes, please indicate which symptoms are worse",
    gr: "Εάν ναι παρακαλώ προσδιορίστε ποια συμπτώματα είναι χειρότερα ",
    fr: "Si oui, veuillez indiquer quels symptômes sont les plus graves",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ქვემოთ მოცემულთგან აღნიშნეთ თუ რომელი სიმპტომი (სიმპტომები)უარესდება? ",
    fi: "Jos vastasitte kyllä, ilmoittakaa, mitkä oireet ovat pahempia",
  },
  description: selectMultiple,
  visibleIf: "{q4s1q12} == 'yes' ",
  choices: [
    {
      value: "cough",
      text: {
        default: "Cough",
        gr: "Βήχας",
        fr: "Toux",
        de: "",
        ka: "ხველა",
        fi: "Yskä",
      },
    },
    {
      value: "wheezing",
      text: {
        default: "Wheezing",
        gr: "Βράσιμο ή σφύριγμα στο στήθος",
        fr: "Sifflements",
        de: "",
        ka: "ხიხინი",
        fi: "Vinkuminen",
      },
    },
    {
      value: "difficulty_in_breathing",
      text: {
        default: "Difficulty in breathing",
        gr: "Δυσκολία στην αναπνοή",
        fr: "Difficulté à respire",
        de: "",
        ka: "სუნთქვის გაძნელება",
        fi: "Hengitysvaikeudet",
      },
    },
    {
      value: "runny_nose",
      text: {
        default: "Runny nose",
        gr: "Συνάχι",
        fr: "Nez qui coule",
        de: "",
        ka: "სურდო",
        fi: "Vuotava nenä",
      },
    },
    {
      value: "stuffy_nose",
      text: {
        default: "Stuffy nose",
        gr: "Μπουκωμένη μύτη",
        fr: "Nez bouché",
        de: "",
        ka: "ცხვირის გაჭედვა",
        fi: "Tukkoinen nenä",
      },
    },
    {
      value: "atopic_eczema",
      text: {
        default: "Atopic eczema",
        gr: "Ατοπικό έκζεμα",
        fr: "Dermatite atopique",
        de: "",
        ka: "ატოპიური ეგზემა",
        fi: "Atooppinen ihottuma",
      },
    },
  ],
};

export const elementsS1 = [
  s1q1,
  s1q2,
  s1d1,
  s1q3,
  s1q4,
  s1q5,
  s1q6,
  s1q6a,
  s1q7,
  s1q8,
  s1q9,
  s1q10,
  s1q11,
  s1q12,
  s1q13,
  s1q14,
];
