import { BarProps, StackedBarProps } from "../../types/dashboard";
import { isEqual } from "lodash";
import { memo } from "react";
import Widget from "./Widget";
import { ChartWidget } from "../../types/widget";
import { useAppThunkDispatch } from "../../store/hooks";
import { getBarChartQuestionnaires } from "../../store/effects/reportingEffects";
import { QueryParams } from "../../types/queries";
import { BACKGROUND_COLORS, BORDER_COLORS } from "./consts";

const BarChart: React.FC<BarProps> = memo((props) => {
  const dispatch = useAppThunkDispatch();

  // console.log("MEMO: ", props.data);

  const widgetBarChartCallBack = (params: QueryParams) => {
    // console.log("From BarChart: ", params);
    dispatch(getBarChartQuestionnaires(params));
  };

  const data = {
    datasets: [
      {
        label: "Students",
        data: props.data,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const barChartConfig: ChartWidget = {
    toolbarConfig: {
      datepicker: true,
      country: true,
      countryConfig: {
        countries: props.countries,
        required: true,
      },
      queryCB: widgetBarChartCallBack,
    },
    chartConfig: {
      type: "bar",
      title: "Answered Questionnaires per School",
      data: data,
    },
  };

  return <Widget config={barChartConfig} />;
}, isEqual);

export default BarChart;

export const StackedBarChart: React.FC<StackedBarProps> = memo((props) => {
  const dispatch = useAppThunkDispatch();

  // console.log("Stacked: ", props.data);

  const widgetBarChartCallBack = (params: QueryParams) => {
    console.log("From BarChart: ", params);
    dispatch(getBarChartQuestionnaires(params));
  };

  const data = {
    datasets: [
      {
        label: "Answered",
        data: props.data["Answered"],
        // backgroundColor: BACKGROUND_COLORS,
        // borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
      {
        label: "Not Answered",
        data: props.data["Not answered"],
        // backgroundColor:  BACKGROUND_COLORS,
        // borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const barChartConfig: ChartWidget = {
    toolbarConfig: {
      datepicker: true,
      country: true,
      countryConfig: {
        countries: props.countries,
        required: true,
      },
      queryCB: widgetBarChartCallBack,
    },
    chartConfig: {
      type: "bar",
      title: "Answered Questionnaires per School",
      data: data,
    },
  };

  return <Widget config={barChartConfig} />;
}, isEqual);

