import Cookies from "js-cookie";
import { User } from "../../types/authUser";
import { getJwt } from "../../services/auth/auth";

export const useLogin = () => {
  const login = async (username: string, password: string) => {
    const { data } = await getJwt(username, password);

    if (data.errors) {
      throw new Error(data.errors[0]);
    }

    const jwt = {
      accessToken: data.data.jwt.access_token,
      // expiredAt: data.data.jwt.expires_in,
      refreshToken: data.data.jwt.refresh_token,
    };

    console.log(jwt);

    if (jwt) {
      Cookies.set("currentUser", JSON.stringify(jwt.accessToken));
      Cookies.set("currentUserRef", JSON.stringify(jwt.refreshToken));
    }
    return jwt as User;
  };

  return { login };
};
