import { Box, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import { Visibility } from "@mui/icons-material";
import { useState } from "react";
import styles from "../../../../styles/Form.module.scss";
import AlertDialog from "../../../../components/Alert";
import Loading from "../../../../components/Loading";
import { StyledButton } from "../../../../components/styled/Button";
import { StyledTextField } from "../../../../components/styled/TextField";
import { yupValidationPrivacy } from "../../../../utils/yupValidation";
import { changePwd } from "../../../../services/api/users.api";
import { handleApiError } from "../../../../utils/handleApiError";

const AccountPassword = () => {
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdConfirm, setShowPwdConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [alert, setAlert] = useState<string | null>(null);

  if (isLoading) return <Loading h={"50vh"} />;

  return (
    <Paper
      elevation={2}
      sx={{
        pt: { xs: 4, md: 10 },
        pb: { xs: 4, md: 10 },
        pl: { xs: 8, md: 12 },
        pr: { xs: 8, md: 12 },
        borderRadius: 4,
      }}
    >
      <Formik
        // enableReinitialize
        initialValues={{ password: "", confirm_password: "" }}
        onSubmit={(values, actions) => {
          console.log("Change Password: ", values);

          setIsLoading(true);
          changePwd(values.password)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                setAlert("Your password has changed successfully!");
              }
            })
            .catch((error) => {
              console.error("Axios request error:", error);
              handleApiError(error, setError);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        validationSchema={yupValidationPrivacy}
      >
        {({
          values,
          errors,
          touched,
          // setValues,
          setFieldValue,
          handleChange,
          // handleBlur,
          handleSubmit,
          // isSubmitting,
          resetForm,
        }) => (
          <form
            // className={styles.form}
            onSubmit={handleSubmit}
            autoComplete='off'
          >
            <Grid flexDirection={"column"} container gap={4}>
              <Grid>
                <Typography variant='h5'>{"Change Password"}</Typography>
              </Grid>
              <Grid container gap={4}>
                {/* <Field
                  as={StyledTextField}
                  required
                  id='password'
                  name='password'
                  label={t("profile.privacy.current_pass")}
                  autoComplete='off'
                  variant='outlined'
                  type='password'
                  // inputProps={{ minLength: 8 }}
                /> */}
                <Field
                  as={StyledTextField}
                  required
                  id={"password"}
                  name={"password"}
                  label={"Password"}
                  autoComplete='off'
                  variant='outlined'
                  type={showPwd ? "text" : "password"}
                  helperText={<ErrorMessage name={"password"} />}
                  error={errors["password"] && touched["password"]}
                  // inputProps={{ minLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Visibility
                          sx={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  as={StyledTextField}
                  required
                  id='confirm_password'
                  name='confirm_password'
                  label={"Confirm New Password"}
                  autoComplete='off'
                  variant='outlined'
                  type={showPwdConfirm ? "text" : "password"}
                  helperText={<ErrorMessage name={"confirm_password"} />}
                  error={
                    errors["confirm_password"] && touched["confirm_password"]
                  }
                  // inputProps={{ minLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Visibility
                          sx={{ cursor: "pointer" }}
                          onClick={() => setShowPwdConfirm(!showPwdConfirm)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ color: "rgba(58, 53, 65, 0.6)", marginBottom: 2 }}
                >
                  {"Password Requirements"}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: "rgba(58, 53, 65, 0.6)" }}
                >
                  <ul style={{ gap: 4 }}>
                    <li>
                      {"Minimum 8 characters long - the more, the better"}
                    </li>
                    <li>
                      {"At least one lowercase & one uppercase character"}
                    </li>
                    <li>{"At least one number & one special character"}</li>
                  </ul>
                </Typography>
              </Box>
              <Grid marginTop={4} gap={2} flexDirection={"row"} container>
                <StyledButton
                  className={styles.button}
                  variant='contained'
                  type='submit'
                >
                  {"Save Changes"}
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Info"}
          message={error || alert || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(null);
                setAlert(null);
              },
            },
          ]}
        />
      )}
    </Paper>
  );
};

export default AccountPassword;
