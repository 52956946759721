import { createAction } from "@reduxjs/toolkit";
export const REPORTING_PREFIX = "reporting";

export const REPORTING_ACTIONS = {
  GET_BAR_CHART: `${REPORTING_PREFIX}/GET_BAR_CHART`,
  GET_LINE_CHART: `${REPORTING_PREFIX}/GET_LINE_CHART`,
  GET_LINE_CHART_GAMES: `${REPORTING_PREFIX}/GET_LINE_CHART_GAMES`,
  GET_BAR_CHART_QUESTIONNAIRES: `${REPORTING_PREFIX}/GET_BAR_CHART_QUESTIONNAIRES`,
  GET_NO_ANSWERS_STUDENTS_TABLE: `${REPORTING_PREFIX}/GET_NO_ANSWERS_STUDENTS_TABLE`,
  GET_NO_ANSWERS_STUDENTS_24H: `${REPORTING_PREFIX}/GET_NO_ANSWERS_STUDENTS_24H`,
  CLEAR_ERROR: `${REPORTING_PREFIX}/CLEAR_ERROR`,
} as const;

// export const getBarChartWidget = createAction(
//   REPORTING_ACTIONS.GET_BAR_CHART,
//   (data : any) : { payload: any } => {
//     // console.log(school);
//     return { payload: data };
//   }
// );

export const clearError = createAction(REPORTING_ACTIONS.CLEAR_ERROR);
