import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s3q1 = {
  type: "radiogroup",
  name: "q1s3q1",
  title: {
    default:
      "Has your child ever had a problem with sneezing, or a runny nose, or blocked nose when he/she DID NOT have a cold or the flu?",
    gr: "Είχε ποτέ το παιδί σας πρόβλημα με φτερνίσματα, συνάχι, ή «βουλωμένη μύτη», ενώ δεν ήταν κρυωμένο ή με γρίπη;",
    fr: "Votre enfant a-t-il/elle déjà eu des problèmes d’éternuement, d’écoulement nasal ou de nez bouché alors qu’il n’avait PAS de rhume ou de grippe ? ",
    de: "",
    ka: "შეუწუხებია თუ არა თქვენი ბავშვი ოდესმე ცხვირის ცემინებას, სურდოს ან ცხვირის გაჭედვას მაშინ, როცა ის არ იყო გაციებული და არც გრიპი ჰქონდა?",
    fi: "Onko lapsellanne koskaan ollut aivastelua, nuhaa tai tukkoista nenää, vaikka hänellä EI ole ollut flunssaa tai vilustumista?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s3q2 = {
  type: "radiogroup",
  name: "q1s3q2",
  visibleIf: "{q1s3q1} == 'yes' ",
  title: {
    default:
      "In the past 12 months has your child had a problem with sneezing, or a runny, or blocked nose when she/he DID NOT have a cold or the flu?",
    gr: "Τους τελευταίους 12 μήνες είχε ποτέ το παιδί σας πρόβλημα με φτερνίσματα, συνάχι, ή «βουλωμένη μύτη», ενώ δεν ήταν κρυωμένο ή με γρίπη;",
    fr: "Au cours des 12 derniers mois, votre enfant a-t-il/elle eu des problèmes d’éternuements, d’écoulement nasal ou de nez bouché alors qu’il n’avait PAS de rhume ou de grippe ? ",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში შეუწუხებია თუა რა თქვენი ბავშვი ოდესმე ცხვირის ცემინებას, სურდოს ან ცხვირის გაჭედვას მაშინ, როცა ის არ იყო გაციებული და არც გრიპი  ჰქონდა?",
    fi: "Onko lapsellanne viimeisen 12 kk aikana ollut aivastelua, nuhaa tai tukkoista nenää, vaikka hänellä EI ole ollut flunssaa tai vilustumista?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s3q3 = {
  type: "radiogroup",
  name: "q1s3q3",
  visibleIf: "{q1s3q1} == 'yes' and {q1s3q2} == 'yes'",
  title: {
    default:
      "In the past 12 months has this nose problem been accompanied by itchy-watery eyes?",
    gr: "Τους τελευταίους 12 μήνες μήπως μαζί με τα προβλήματα από τη μύτη υπήρχαν και συμπτώματα από τα μάτια όπως φαγούρα, κοκκινίλα, δάκρυα;",
    fr: "Au cours des 12 derniers mois, les symptômes au nez se sont-ils accompagnés de démangeaisons et de larmoiements ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში ცხვირთან დაკავშირებულ პრობლემებს ახლდა თუა რა თან თვალების ქავილი და ცრემლდენა? ",
    fi: "Onko tähän ongelmaan liittynyt viimeisen 12 kk aikana silmien kutinaa ja vetisyyttä? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s3q4 = {
  type: "checkbox",
  name: "q1s3q4",
  visibleIf: "{q1s3q1} == 'yes' and {q1s3q2} == 'yes' ",
  colCount: 3,
  description: selectMultiple,
  title: {
    default:
      " In which of the past 12 months did this nose problem occur?",
    gr: "Ποιο μήνα εμφανίστηκε αυτό το ρινικό πρόβλημα;",
    fr: "Au cours des 12 derniers mois, au cours de quelle période ce problème de nez s’est-il produit ? Veuillez cocher les cases correspondantes :",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში როდის აწუხებდა თქვენს ბავშვს ეს მოვლენები ცხვირის მხრივ?",
    fi: "Missä kuussa viimeisen 12 kk aikana tämä nenäongelma on ilmennyt? Rastittakaa kaikki kyseiset kuukaudet:",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "january",
      text: {
        default: "January",
        gr: "Ιανουάριος",
        fr: "Janvier",
        de: "",
        ka: "იანვარი",
        fi: "Tammikuu",
      },
    },
    {
      value: "february",
      text: {
        default: "February",
        gr: "Φεβρουάριος",
        fr: "Février",
        de: "",
        ka: "თებერვალი",
        fi: "Helmikuu ",
      },
    },
    {
      value: "march",
      text: {
        default: "March",
        gr: "Μάρτιος",
        fr: "Mars",
        de: "",
        ka: "მარტი",
        fi: "Maaliskuu",
      },
    },
    {
      value: "april",
      text: {
        default: "April",
        gr: "Απρίλιος",
        fr: "Avril",
        de: "",
        ka: "აპრილი",
        fi: "Huhtikuu",
      },
    },
    {
      value: "may",
      text: {
        default: "May",
        gr: "Μάϊος",
        fr: "Mai",
        de: "",
        ka: "მაისი",
        fi: "Toukokuu",
      },
    },
    {
      value: "june",
      text: {
        default: "June",
        gr: "Ιούνιος",
        fr: "Juin",
        de: "",
        ka: "ივნისი",
        fi: "Kesäkuu",
      },
    },
    {
      value: "july",
      text: {
        default: "July",
        gr: "Ιούλιος",
        fr: "Juillet",
        de: "",
        ka: "ივლისი",
        fi: "Heinäkuu",
      },
    },
    {
      value: "august",
      text: {
        default: "August",
        gr: "Αύγουστος",
        fr: "Août",
        de: "",
        ka: "აგვისტო",
        fi: "Elokuu",
      },
    },
    {
      value: "september",
      text: {
        default: "September",
        gr: "Σεπτέμβριος",
        fr: "Septembre",
        de: "",
        ka: "სექტემბერი",
        fi: "Syyskuu",
      },
    },
    {
      value: "october",
      text: {
        default: "October",
        gr: "Οκτώβριος",
        fr: "Octobre",
        de: "",
        ka: "ოქტომბერი",
        fi: "Lokakuu",
      },
    },
    {
      value: "november",
      text: {
        default: "November",
        gr: "Νοέμβριος",
        fr: "Novembre",
        de: "",
        ka: "ნოემბერი",
        fi: "Marraskuu",
      },
    },
    {
      value: "december",
      text: {
        default: "December",
        gr: "Δεκέμβριος",
        fr: "Décembre",
        de: "",
        ka: "დეკემბერი",
        fi: "Joulukuu",
      },
    },
  ],
};

const s3q5 = {
  type: "radiogroup",
  name: "q1s3q5",
  visibleIf: "{q1s3q1} == 'yes' and {q1s3q2} == 'yes' ",
  title: {
    default:
      "In the past 12 months how much did this nose problem interfere with your child’s daily activities? ",
    gr: "Τους τελευταίους 12 μήνες πόσο επηρέασε τις καθημερινές δραστηριότητες του παιδιού σας αυτό το ρινικό πρόβλημα;",
    fr: "Au cours des 12 derniers mois, dans quelle mesure ce problème de nez a-t-il gêné les activités quotidiennes de votre enfant ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში ცხვირთან დაკავშირებული ეს პრობლემები რამდენად უშლიდა ხელს თქვენს ბავშვს ყოველდღიურ საქმიანობაში? ",
    fi: "Kuinka paljon tämä nenäongelma haittasi lapsenne päivittäisiä aktiviteetteja viimeisen 12 kk aikana?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "no",
      text: {
        default: "Not at all",
        gr: "Καθόλου",
        fr: "Pas du tout",
        de: "",
        ka: "სრულებით არ უშლიდა ",
        fi: "Ei yhtään",
      },
    },
    {
      value: "little",
      text: {
        default: "A little",
        gr: "Λίγο",
        fr: "Un peu",
        de: "",
        ka: "მცირედ",
        fi: "Vähän",
      },
    },
    {
      value: "mοderate",
      text: {
        default: "A moderate ammount",
        gr: "Μέτρια",
        fr: "Modérément",
        de: "",
        ka: "საშუალოდ",
        fi: "Kohtalaisesti",
      },
    },
    {
      value: "a_lot",
      text: {
        default: "A lot",
        gr: "Πολύ",
        fr: "Beaucoup ",
        de: "",
        ka: "ძალიან",
        fi: "Paljon",
      },
    },
  ],
};


const s3q6 = {
  type: "radiogroup",
  name: "q1s3q6",
  title: {
    default:
      "Has your child ever had hay fever?",
    gr: "Είχε ποτέ το παιδί σας αλλεργική ρινίτιδα (την άνοιξη);",
    fr: "Votre enfant a-t-il déjà eu une rhinite allergique ?",
    de: "",
    ka: "ქონია თუ არა თქვენს ბავშვს ოდესმე ალერგიული რინიტი, ალერგიული რინიკონიუქტივიტი?",
    fi: "Onko lapsellanne koskaan ollut heinänuhaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};


export const elementsS3 = [s3q1, s3q2, s3q3, s3q4, s3q5, s3q6];
