import * as geojson from 'geojson';
import { School } from "../types/school";

const gjsonAdapter = (data: School[] | null) => {
  const geoJsonSchoolsFeatures = [];

  if(data){
    for (const itemIndex in data) {
      geoJsonSchoolsFeatures.push(<geojson.Feature>{
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [data[itemIndex]["lon"], data[itemIndex]["lat"]],
        },
        properties: {
          title: data[itemIndex]["title"],
          // description: "France",
          // "icon": "monument"
        },
      });
    }
  }

  // return { type: "FeatureCollection", features: geoJsonSchoolsFeatures };
  return geoJsonSchoolsFeatures ;
};

export default gjsonAdapter;

