import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_ACTIONS } from "../actions/userActions";
import { fetchUser, fetchUsers } from "../../services/api/users.api";
import { ApiResponse, UserApiData } from "../../types/api";
import { getRole, getUsername } from "../../services/api/utils.api";

export const getUsers = createAsyncThunk<UserApiData>(
  USER_ACTIONS.GET_USERS,
  async (params, thunkAPI) => {
    try {
      // const response = (await fetch(`${API_URL}/user//get/all`)).json();
      const { data: responseData } = await fetchUsers(); //await userClient.get<ApiResponse<UserApiData[]>>("/get/all");

      return responseData.data;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProfileDetails = createAsyncThunk<any>(
  USER_ACTIONS.GET_PROFILE_DETAILS,
  async (params, thunkAPI) => {
    try {
      // const response = (await fetch(`${API_URL}/user//get/all`)).json();
      const role = getRole();
      if (!role) {
        throw new Error("Your role is not defined. Please sign in again");
      }
      const { data: responseData } = await fetchUser(role, getUsername());
      return responseData.data;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getCaseStudents = createAsyncThunk<UserApiData>(
//   USER_ACTIONS.GET_USERS,
//   async () => {
//     // const response = (await fetch(`${API_URL}/user//get/all`)).json();
//     const { data:responseData } = await fetchUsers(); //await userClient.get<ApiResponse<UserApiData[]>>("/get/all");

//     return responseData.data;
//   }
// );

// export const getStudents = createAsyncThunk<Student[]>(
//   USER_ACTIONS.GET_STUDENTS,
//   async () => {
//     // const response = (await fetch(`${API_URL}/user//get/all`)).json();

//     const { data:responseData } = await fetchStudents(); //await userClient.get<ApiResponse<UserApiData[]>>("/get/all");

//     return responseData.data;
//   }
// );
