import { OidcSecure, useOidc } from "@axa-fr/react-oidc";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import variables from "../../styles/variables.module.scss";
import AuthCheck from "../../AuthCheck";
import { Outlet } from "react-router-dom";

export default function DashboardLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <AuthCheck>
      {/* <OidcSecure> */}
        <Nav />
        <Header />
        <div
          style={{
            transform: `translateY(${variables.transform_calc})`,
            width: "100%",
            marginLeft: "50px",
          }}
        >
          <main className="container">
            <Outlet />
          </main>
        </div>
      {/* </OidcSecure> */}
    </AuthCheck>
  );
}
