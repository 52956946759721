import { ParentQuestionnaireAnswers } from "../types/questionnaire";

type Condition = "case";

export type ruleResponse<T = keyof Condition> = T | undefined;
function selectedHealthProblems(data: ParentQuestionnaireAnswers): string[] {
  if (data.q1s1q2) {
    return data.q1s1q2.map((m: string) => stripValue(m));
  }
  return [];
}
function selectedMedication(data: ParentQuestionnaireAnswers): string[] {
  if (data.q1s1q3) {
    return data.q1s1q3.map((m: string) => stripValue(m));
  }
  return [];
}

const YES = "yes";

export function isAsthmaticNew(data: ParentQuestionnaireAnswers) {
  //STEP 1

  // -Q2
  const medication = selectedMedication(data);
  const targetMedication: string[] = [
    "inhalers",
    "antihistamines_for_rhinitis",
    "nasal_sprays",
  ];

  const receivesMedication = targetMedication.some((m: string) =>
    medication.includes(m)
  );

  // -Q3
  const conditions = selectedHealthProblems(data);

  const targetConditions: string[] = [
    "asthma",
    "allergic_rhinitis",
    "atopic_dermatitis",
  ];
  const hasCondition = targetConditions.some((m: string) =>
    conditions.includes(m)
  );

  //STEP 2

  // -SECTION 1
  const past12MonthsWheezingOrWhistling = stripValue(data.q1s2q2) == YES;
  const past12MonthsChestSoundedWheezy = stripValue(data.q1s2q7) == YES;
  const past12MonthsDryCough = stripValue(data.q1s2q8) == YES;

  const section1: boolean = [
    past12MonthsWheezingOrWhistling,
    past12MonthsChestSoundedWheezy,
    past12MonthsDryCough,
  ].some((a) => a == true);
  const past12SneezingBlockedOrRunnyNose = stripValue(data.q1s3q1) == YES;
  // -SECTION 2

  const section2: boolean = past12SneezingBlockedOrRunnyNose == true;

  const past12ItchyRash = stripValue(data.q1s4q2) == YES;
  const rushAffectedPlaces = stripValue(data.q1s4q3) == YES;
  // -SECTION 3

  const section3: boolean = past12ItchyRash && rushAffectedPlaces;

  // -SECTION 4
  const everHadFoodAllergy = stripValue(data.q1s5q1) == YES;
  const section4: boolean = everHadFoodAllergy;

  const cases = [
    receivesMedication,
    hasCondition,
    section1,
    section2,
    section3,
    section4,
  ];

  if (cases.some((c) => c == true)) return "case";
  return undefined;
}

function isAsthmatic(
  data: ParentQuestionnaireAnswers
): ruleResponse<"asthmatic"> {
  const conditions = selectedHealthProblems(data);
  const medication = selectedMedication(data);
  const past12MonthsWheezingOrWhistling = stripValue(data.q1s2q2) == YES;
  const past12MonthsChestSoundedWheezy = stripValue(data.q1s2q7) == YES;
  const past12MonthsDryCough = stripValue(data.q1s2q8) == YES;

  if (conditions && medication) {
    if (
      conditions.includes("asthma") &&
      medication.map((m: string) => stripValue(m)).includes("inhalers") &&
      (past12MonthsWheezingOrWhistling ||
        past12MonthsChestSoundedWheezy ||
        past12MonthsDryCough)
    ) {
      return "asthmatic";
    }
  }
  return undefined;
}

function isRhinitic(
  data: ParentQuestionnaireAnswers
): ruleResponse<"rhinitic"> {
  const conditions = selectedHealthProblems(data);
  const medication = selectedMedication(data);
  const past12SneezingBlockedOrRunnyNose = stripValue(data.q1s3q1) == YES;
  const rhinitis_medication = ["antihistamines_for_rhinitis", "nasal_sprays"];

  if (
    rhinitis_medication.some((m: string) => medication.includes(m)) &&
    conditions.includes("allergic_rhinitis") &&
    past12SneezingBlockedOrRunnyNose
  ) {
    return "rhinitic";
  }
  return undefined;
}

function isEczematic(
  data: ParentQuestionnaireAnswers
): ruleResponse<"eczematic"> {
  const conditions = selectedHealthProblems(data);
  const past12ItchyRash = stripValue(data.q1s4q2) == YES;
  const everHadEczema = stripValue(data.q1s4q3) == YES;
  if (
    conditions.includes("atopic_dermatitis") &&
    past12ItchyRash &&
    everHadEczema
  ) {
    return "eczematic";
  }
  return undefined;
}

function isFoodAllergic(
  data: ParentQuestionnaireAnswers
): ruleResponse<"food_allergic"> {
  const everHadFoodAllergy = stripValue(data.q1s5q1) == YES;
  if (everHadFoodAllergy) {
    return "food_allergic";
  }
  return undefined;
}

export function getHealthTags(data: ParentQuestionnaireAnswers) {
  const conditionsFilter: ruleResponse<Condition>[] = [
    isAsthmaticNew(data),
    // isRhinitic(data),
    // isEczematic(data),
    // isFoodAllergic(data),
  ];

  const tags = conditionsFilter.filter((r: ruleResponse<Condition>) => r);
  return tags;
}

function stripValue(v: string): string {
  // remove empty char
  // lowercase
  if (v) return v.replaceAll(" ", "").toLowerCase();
  else return "";
}
