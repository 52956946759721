import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { StyledButton } from "../styled/Button";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useLogin } from "../../hooks/auth/useLogin";
import { getProfile, resetAction } from "../../store/actions/userActions";
import { getRole } from "../../services/api/utils.api";
import { redirectPaths } from "../../utils/permissions";
import { StyledTextField } from "../styled/TextField";
import styles from "../../styles/Login.module.scss";
import { LOGO } from "../../consts/title";
import { handleApiError } from "../../utils/handleApiError";
import AlertDialog from "../Alert";
import Loading from "../Loading";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useLogin();
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  console.log("error: ", error);
  console.log("usr: ", username);
  console.log("pwd: ", password);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(resetAction);
    setIsLoading(true);
    login(username, password)
      .then((res) => {
        // console.log(res)
        if (res) {
          dispatch(getProfile(username));
          const role = getRole();
          role && router(redirectPaths[role]);
        }
      })
      .catch((e) => {
        handleApiError(e, setError);
        setIsLoading(false);
      });
  };

  return (
    <Paper
      elevation={1}
      style={{ height: "100%" }}
      sx={{ background: "rgba(255, 255, 255,.9)" }}
    >
      <Grid
        container
        alignItems={"center"}
        maxWidth='md'
        style={{ height: "100%" }}
      >
        <Box className={styles.paper}>
          <Box className={styles.box}>
            {LOGO}
            <h2>Please Login</h2>
            <form
              className={styles.form}
              onSubmit={handleSubmit}
              autoComplete='off'
            >
              <div className={styles.fields}>
                <StyledTextField
                  required
                  id='username'
                  name='username'
                  label='Username'
                  variant='outlined'
                  onChange={(e) => setUsername(e.target.value)}
                  helperText=''
                  value={username}
                  // inputProps={{ maxLength: 8 }}
                />
                <StyledTextField
                  required
                  id='password'
                  name='password'
                  label='Password'
                  autoComplete='off'
                  variant='outlined'
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // inputProps={{ minLength: 8 }}
                />
                {isLoading ? (
                  <Loading h={"5rem"} />
                ) : (
                  <StyledButton
                    className={styles.button}
                    variant='contained'
                    type='submit'
                    fullWidth
                  >
                    Login
                  </StyledButton>
                )}
              </div>
            </form>
          </Box>
        </Box>
      </Grid>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
                setUsername("");
                setPassword("");
              },
            },
          ]}
        />
      )}
    </Paper>
  );
}

export default Login;
