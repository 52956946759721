import dashboard1 from "../../assets/tutorial/dashboard/dashboard1.png";
import dashboard4 from "../../assets/tutorial/dashboard/dashboard4.png";
import dashboard1a from "../../assets/tutorial/dashboard/dashboard1a.png";

export const dashboardTutorialData = [
  {
    imageSrc: dashboard1,
    subtitle: "Overview",
    description: [
      "The top-left section displays a graph showing the number of students who have completed questionnaires per school within a specific time frame (default is the last day).",
      "The top-right section features a graph illustrating the number of questionnaires answered daily.",
      "The bottom-left section contains a map showing the locations of schools participating in the study.",
      "The bottom-right section includes two cards: one showing the total number of questionnaires answered in the last 24 hours, and another displaying the number of students who have not completed a questionnaire within the same time frame.",
    ],
  },
  {
    imageSrc: dashboard4,
    description: [
      "Below there is a table showing the students that have not answered the health questionnaire for a specific period of time.",
      "And finally, there is a graph illustrating the number of games played daily.",
    ],
  },
  {
    imageSrc: dashboard1a,
    subtitle: "Filters",
    description: [
      "Above the graphs, after clicking the cogwheel icon, filters will appear allowing you to select a specific time period, country, and school.",
      "For graphs displaying daily results, the selected time period cannot exceed one month.",
    ],
  },
];
