import React from "react";
import Header from "../../components/Header";
import variables from "../../styles/variables.module.scss";
import AuthCheck from "../../AuthCheck";

export default function LoginLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div>
      <div>
        <AuthCheck>{children}</AuthCheck>
      </div>
    </div>
  );
}
