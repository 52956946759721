import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import variables from '../../styles/variables.module.scss'; 


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: variables.primary,
    fontSize: variables.font_size_table,
    color: variables.text_color,
    fontWeight: variables.font_weight,
    padding: variables.padding_table,
  },
  [`&.${tableCellClasses.body}`]: {
    color: variables.text_color,
    fontSize: variables.font_size_table,
    padding: variables.padding_table,
    // borderBottom: `1px solid ${variables.background_toolbar_secondary}`,
  },
}));

