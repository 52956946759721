import { ApiResponse } from "../../types/api";
import {
  GameCount,
  QueryCountAnswers,
  QueryFrequencyAnswers,
  QueryParams,
} from "../../types/queries";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";

export const queriesClient = () =>
  Axios(`${getApiUrl()}/queries`.replace("/v2", ""));

export async function fetchQueriesAnswersFrequency(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryFrequencyAnswers>>(
    "/answers/frequency",
    {
      params,
    }
  );
}

export async function fetchQueriesAnswersSchoolCount(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryCountAnswers>>(
    "/answers/school/count",
    {
      params,
    }
  );
}

export async function fetchQueriesAnswersDailyCount(params?: QueryParams) {
  return queriesClient().get<ApiResponse<QueryCountAnswers>>(
    "/answers/daily/count",
    {
      params,
    }
  );
}

export async function fetchGamesDailyCount(params?: QueryParams) {
  return queriesClient().get<ApiResponse<GameCount>>(
    "/game-sessions/daily/count",
    {
      params,
    }
  );
}
