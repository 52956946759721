import styles from "../styles/Unauthorised.module.scss";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { StyledButton } from "./styled/Button";
import unauthorised from "../assets/trespasser-icon-test.png";

const Unauthorised = ({ path }: { path: string }) => {
  return (
    <Container maxWidth="xl">
      <Paper elevation={3} className={styles.paper}>
        <Box className={styles.box}>
          <img
            src={unauthorised}
            width="200"
            height="250"
            alt="Unauthorised"
          />
          <div className={styles.text}>
            <h2>
              You do not have permission to access this route. Please go back.
            </h2>
            <StyledButton
              sx = {{ fontSize: "16px"}}
              variant="contained"
              type="button"
              onClick={() => {
                // router.push(path);
              }}
            >
              Go Back
            </StyledButton>
          </div>
        </Box>
      </Paper>
    </Container>
  );
}

export default Unauthorised;
