import { User } from "../../types/user";
import { createAction } from "@reduxjs/toolkit";

export const USERS_PREFIX = "users";

export const USER_ACTIONS = {
  GET_USERS: `${USERS_PREFIX}/GET_USERS`,
  GET_STUDENTS: `${USERS_PREFIX}/GET_STUDENTS`,
  GET_CASE_STUDENTS: `${USERS_PREFIX}/GET_CASE_STUDENTS`,
  GET_ACTIVE_USER: `${USERS_PREFIX}/GET_ACTIVE_USER`,
  GET_PROFILE: `${USERS_PREFIX}/GET_PROFILE`,
  GET_PROFILE_DETAILS: `${USERS_PREFIX}/GET_PROFILE_DETAILS`,
  REMOVE_USER: `${USERS_PREFIX}/REMOVE_USER`,
  RESET: `${USERS_PREFIX}/RESET`,
} as const;

export const getProfile = createAction(
  USER_ACTIONS.GET_PROFILE,
  (user: string): { payload: string } => {
    return { payload: user };
  }
);

export const getActiveUser = createAction(
  USER_ACTIONS.GET_ACTIVE_USER,
  (user: User | null): { payload: User | null } => {
    // console.log(user);
    return { payload: user };
  }
);

export const removeUser = createAction(
  USER_ACTIONS.REMOVE_USER,
  (user: string): { payload: string } => {
    return { payload: user };
  }
);

export const resetAction = createAction(USER_ACTIONS.RESET);
