import { Role } from "../../types/user";
import Cookies from "js-cookie";

export function getToken() {
  const currentUser = Cookies.get("currentUser");
  if (currentUser) return JSON.parse(currentUser);
  return null;
}

export function getRole() {
  const currentUser = Cookies.get("currentUser");
  if (currentUser) {
    const jwt = JSON.parse(currentUser);
    const payload = JSON.parse(atob(jwt.split(".")[1]));
    const role: Role = payload.synairg_groups[0].replace("/", "");
    return role;
  } else {
    return undefined;
  }
}

export function getUsername() {
  const currentUser = Cookies.get("currentUser");
  if (currentUser) {
    const jwt = JSON.parse(currentUser);
    const payload = JSON.parse(atob(jwt.split(".")[1]));
    const username = payload.preferred_username;
    return username;
  } else {
    return undefined;
  }
}

// export function getToken() {
//   if (typeof window !== "undefined" && window && window.sessionStorage) {
//     let oidc: any = sessionStorage.getItem("oidc.default");
//     if (!oidc) {
//       return undefined;
//     }

//     oidc = JSON.parse(oidc);
//     const accessToken: string = oidc?.tokens?.accessToken;
//     const expiresAt: number = oidc?.tokens?.expiresAt;

//     // *1000 because we want it in ms
//     const targetTime = new Date(expiresAt * 1000);
//     const currentTime = new Date();
//     if (currentTime >= targetTime) {
//       console.log("Token has expired");
//       return undefined;
//     }
//     return accessToken;
//   } else {
//     return undefined;
//   }
// }

// export function getRole() {
//   if (typeof window !== "undefined" && window && window.sessionStorage) {
//     let oidc: any = sessionStorage.getItem("oidc.default");
//     if (!oidc) {
//       return undefined;
//     }

//     oidc = JSON.parse(oidc);
//     const role: Role | undefined = oidc?.tokens?.accessTokenPayload.synairg_groups[0].replace("/","");
    
//     return role;
//   } else {
//     return undefined;
//   }
// }

