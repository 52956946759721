import { SCHOOL_ACTIONS } from "./../actions/schoolActions";
import { School } from "../../types/school";
import { getSchools } from "../effects/schoolEffects";
import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../types/redux";
import { handleApiError } from "../../utils/handleApiError";

export interface SchoolState {
  schools: School[];
  activeSchool: School | null;
  loading: boolean;
  error: string | undefined;
}

const initialState: SchoolState = {
  schools: [],
  activeSchool: null,
  loading: false,
  error: undefined,
};

const schoolReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    SCHOOL_ACTIONS.GET_ACTIVE_SCHOOL,
    (state, action: ActionWithPayload<School>) => {
      // console.log(state.schools.find(school => school.school_id === action.payload))
      return { ...state, activeSchool: action.payload };
    }
  );
  builder.addCase(getSchools.fulfilled, (state, { payload }) => {
    // console.log(payload);
    return { ...state, schools: payload, loading: false };
  });
  builder.addCase(getSchools.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getSchools.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(
    SCHOOL_ACTIONS.REMOVE_SCHOOL,
    (state, action: ActionWithPayload<string>) => {
      return {
        ...state,
        schools: state.schools.filter(
          (school) => school.school_id !== action.payload
        ),
      };
    }
  );
});

export default schoolReducer;
