"use client";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { postParentAnswers } from "../../services/api/pquestionnaires.api";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { getDrafts } from "../../store/effects/questionnaireEffects";
import {
  selectDrafts,
  selectQuestionnaires,
} from "../../store/selectors/questionnaireSelector";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading";
import { getRole, getUsername } from "../../services/api/utils.api";
import { ROLES } from "../../types/user";
import SurveyComponent from "./SurveyComponent";

const SurveyCreator = ({ id }: { id: string }) => {
  const router = useNavigate();
  const draft = useAppSelector(selectDrafts).find(
    (draft) => draft.questionnaire_id === id
  );
  const dispatch = useAppThunkDispatch();
  const role = getRole();
  const questionnaire =
    role !== ROLES.STUDENT
      ? useAppSelector(selectQuestionnaires).find((q) => {
          console.log(q);
          return q._id === id;
        })
      : undefined;
  const username = getUsername() as string;
  const params = useParams();
  console.log("Params:", params, role, params.student);

  useEffect(() => {
    if (role === ROLES.STUDENT) {
      const data = { id, is_draft: true, username };

      postParentAnswers(data).then((res) => {
        console.log("RES:", res);
        if (res.status === 200) {
          dispatch(getDrafts());
          router(
            `/dashboard/questionnaires/${data.id}/${params.student}/${res.data.data}`
          );
        }
      }); // console.log("CREATE DRAFT");
    } else {
      if (params.student !== username) {
        console.log("here");
        router(`/dashboard/questionnaires/${id}/${params.student}/draft`);
      } else {
        router(`/dashboard/questionnaires/${id}`);
      }
    }
  }, []);

  // console.log("HERE: ", role, questionnaire);

  return (
    <Container maxWidth='xl'>
      {role === ROLES.STUDENT || questionnaire === undefined ? (
        <Loading />
      ) : (
        <SurveyComponent
          questionnaire={questionnaire}
          answers={{}}
          hasDraft={false}
        />
      )}
    </Container>
  );
};

export default SurveyCreator;
