import { isNotRequired } from "./required";

export const age = {
  name: "age",
  title: {
    default: "If yes, at what age of the child? (years)",
    gr: "Εάν Ναι, σε ποια ηλικία του παιδιού; (ετών)",
    fr: "Si oui, à quel âge de l’enfant? (ans)",
    de: "",
    ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
    fi: "os kyllä, minkä ikäinen lapsi oli? (vuosia)",
  },
  cellType: "text",
  inputType: "number",
  min: 1,
  max: 12,
  visibleIf: "{row.yes_no_notRequired} == 'yes'",
  isRequired: isNotRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 12",
      minValue: 1,
      maxValue: 12,
    },
  ],
};
