import { useEffect } from "react";
import { usePathname } from "./hooks/usePathname";
import { useNavigate } from "react-router-dom";
import { getRole } from "./services/api/utils.api";
import { redirectPaths } from "./utils/permissions";
import { useAuth } from "./hooks/auth/useAuth";

export default function AuthCheck({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useAuth();
  const pathname = usePathname();
  const router = useNavigate();

  useEffect(() => {
    console.log("Route changed", pathname);

    if (
      !isAuthenticated &&
      (pathname.startsWith("/dashboard") || pathname === "/")
    ) {
      router("/login");
      return;
    }
    if (isAuthenticated && pathname === "/login") {
      router("/dashboard");
      return;
    }
    if (isAuthenticated && pathname === "/") {
      router("/dashboard");
      return;
    }
    // if (isAuthenticated) {
    //   const role = getRole();
    //   role && router(redirectPaths[role]);
    // }
  }, [pathname, isAuthenticated]);

  return <>{children}</>;
}
