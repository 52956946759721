import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SchoolIcon from "@mui/icons-material/School";
import { Dashboard } from "@mui/icons-material";

export const getIcon = (text: string) => {
  switch (text) {
    case "Dashboard":
      return <Dashboard />;
    case "Users":
      return <PersonIcon />;
    case "Players":
      return <AccountBoxIcon />;
    case "Analytics":
      return <AssessmentIcon />;
    case "Schools":
      return <SchoolIcon />;
    case "Questionnaires":
      return <AssignmentIcon />;
    case "Tutorials":
      return <ImportContactsIcon />;
    // case "Generator":
    //   return <AddBox/>;
    case "Logout":
      return <ExitToAppRoundedIcon />;
    default:
      return <></>;
  }
};
