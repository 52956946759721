import {  Checkbox, CheckboxClasses, styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";

export const StyledCheckbox = styled(Checkbox)(
  () => ({
    // color: variables.color_primary,
    '&.Mui-checked': {
      color: variables.color_primary,
      '&:hover': {
        backgroundColor: variables.color_primary_hover,
      },
    },
    
  }));