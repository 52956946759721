import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "../../styles/Form.module.scss";
import { ROLES, Role } from "../../types/user";
import {
  Container,
  Grid,
  InputAdornment,
  ListItemText,
  Modal,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { StyledButton } from "../styled/Button";
import { StyledTextField } from "../styled/TextField";
import { TableBar } from "../styled/AppBar";
import {
  AccountCircle,
  Email,
  Visibility,
  Watch,
  Face,
  Person,
} from "@mui/icons-material";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { selectSchools } from "../../store/selectors/schoolSelectors";
import { getSchools } from "../../store/effects/schoolEffects";
import { getActiveUser } from "../../store/actions/userActions";
import { selectActiveUser } from "../../store/selectors/userSelector";
import { AddOne, StyledIconButton } from "../styled/AddButton";
import SchoolForm from "./SchoolForm";
import { StyledFormControl, menuStyles } from "../styled/StyledFormSelect";
import { StyledSwitch } from "../styled/StyledSwitch";
import { StyledCheckbox } from "../styled/StyledCheckbox";
import {
  ErrorMessage,
  Field,
  Formik,
  FormikErrors,
  FormikTouched,
} from "formik";
import { yupValidationUser } from "../../utils/yupValidation";
import { UserFormData, FormikUserTypes } from "../../types/formik";
import { createNewUser, updateUser } from "../../services/api/users.api";
import { getFormUser, getFullData } from "../../utils/getFormData";
import { School } from "../../types/school";
import { getUsers } from "../../store/effects/userEffects";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../Alert";
import { handleApiError } from "../../utils/handleApiError";

const Form = ({ role = ROLES.ADMIN }: { role?: Role }) => {
  const activeUser = useAppSelector(selectActiveUser);
  const schools = useAppSelector(selectSchools);
  const school_ids = schools.map((school) => school.school_id);
  const [school_options, setSchoolOptions] = useState(school_ids);
  const fullData: UserFormData = getFullData(
    activeUser,
    role,
    school_options[0]!
  );
  const dispatch = useAppThunkDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(getSchools());
    setOpen(false);
  };
  const router = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  console.log(activeUser);

  useEffect(() => {
    setSchoolOptions(schools.map((school) => school.school_id));
  }, [schools]);

  useEffect(() => {
    dispatch(getSchools());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(getActiveUser(null));
    };
  }, []);

  const getTitle = (schools: School[], id: string) => {
    return schools.find((s) => s.school_id === id)?.title;
  };

  const createTextField = (
    errors: FormikErrors<FormikUserTypes>,
    touched: FormikTouched<FormikUserTypes>,
    text: string,
    icon: JSX.Element,
    disabled: boolean,
    required: boolean
    // type: string,
  ): JSX.Element => {
    return (
      <Field
        as={StyledTextField}
        disabled={disabled}
        required={required}
        autoComplete='off'
        id={text}
        name={text}
        label={text.charAt(0).toUpperCase() + text.slice(1)}
        fullWidth
        margin='dense'
        variant='filled'
        helperText={<ErrorMessage name={text} />}
        error={
          errors[text as keyof FormikUserTypes] &&
          touched[text as keyof FormikUserTypes]
        }
        type={text}
        // inputProps={{ maxLength: 8, minLength: 8 }}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{icon}</InputAdornment>,
        }}
      />
    );
  };

  return (
    <Container className={styles.formContainer} maxWidth='lg'>
      <Paper elevation={2} className={styles.paper} sx={{ overflow: "hidden" }}>
        <TableBar position='relative'>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>{`User Information`}</h2>
          </Toolbar>
        </TableBar>
        <Box className={styles.box}>
          <Formik
            initialValues={fullData}
            onSubmit={(values, actions) => {
              // values = { ...values, schools: [values.schools] };
              console.log(values);

              if (activeUser) {
                const editUser = getFormUser(values);
                console.log("Edit: ", editUser);
                updateUser(editUser)
                  .then((res) => {
                    console.log(res);
                    dispatch(getUsers());
                    // alert(res.message);
                    router(-1);
                  })
                  .catch((e) => {
                    handleApiError(e, setError);
                  });
              } else {
                const createUser = getFormUser(values);
                console.log("Create: ", createUser);

                createNewUser(createUser)
                  .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                      dispatch(getUsers());
                      router(-1);
                    }
                  })
                  .catch((e) => {
                    handleApiError(e, setError);
                  });
              }
            }}
            validationSchema={yupValidationUser}
          >
            {({
              values,
              errors,
              touched,
              setValues,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className={styles.form}
                onSubmit={handleSubmit}
                autoComplete='off'
              >
                <Grid container rowSpacing={1.5} columnSpacing={3}>
                  <Grid container item xs={12} justifyContent={"flex-start"}>
                    <StyledFormControl variant='filled' sx={{ width: "100%" }}>
                      <InputLabel id='role'>Role</InputLabel>
                      <Field
                        as={Select}
                        disabled={
                          (activeUser || role === ROLES.STUDENT) && true
                        }
                        labelId='role'
                        id='role'
                        name='role'
                        value={values.role}
                        MenuProps={menuStyles}
                        onChange={(e: { target: { value: string } }) => {
                          const role =
                            e.target.value.toUpperCase() as keyof typeof ROLES;

                          // setValues(getFormUser(ROLES[role], values));
                          console.log(values);
                          handleChange(e);
                          if (
                            activeUser === null ||
                            activeUser.role !== ROLES[role]
                          ) {
                            if (role.toLowerCase() === ROLES.OPERATOR) {
                              setFieldValue("schools", [school_options[0]]);
                            } else {
                              setFieldValue("schools", school_options[0]);
                            }
                          } else {
                            // hasSchool(activeUser) &&
                            //   setFieldValue("schools", activeUser.school);
                            // isOperator(activeUser) &&
                            //   setFieldValue("schools", activeUser.schools);
                          }
                        }}
                      >
                        <MenuItem value={ROLES.ADMIN}>
                          {ROLES.ADMIN.toUpperCase()}
                        </MenuItem>
                        <MenuItem value={ROLES.OPERATOR}>
                          {ROLES.OPERATOR.toUpperCase()}
                        </MenuItem>
                        <MenuItem value={ROLES.STUDENT}>
                          {ROLES.STUDENT.toUpperCase()}
                        </MenuItem>
                      </Field>
                    </StyledFormControl>
                  </Grid>
                  <Grid container item xs={6}>
                    {createTextField(
                      errors,
                      touched,
                      "username",
                      <AccountCircle />,
                      activeUser !== null,
                      true
                    )}
                  </Grid>
                  <Grid container item xs={6}>
                    {createTextField(
                      errors,
                      touched,
                      "password",
                      <Visibility />,
                      false,
                      activeUser === null ? true : false
                    )}
                  </Grid>
                  {values.role !== ROLES.STUDENT && (
                    <Grid container item xs={6}>
                      {createTextField(
                        errors,
                        touched,
                        "fullname",
                        <Person />,
                        false,
                        false
                      )}
                    </Grid>
                  )}
                  <Grid container item xs={6}>
                    {createTextField(
                      errors,
                      touched,
                      "email",
                      <Email />,
                      false,
                      false
                    )}
                  </Grid>
                  {values.role === ROLES.STUDENT && (
                    <>
                      <Grid container item xs={3}>
                        {createTextField(
                          errors,
                          touched,
                          "garminId",
                          <Watch />,
                          false,
                          false
                        )}
                      </Grid>
                      <Grid container item xs={3}>
                        {createTextField(
                          errors,
                          touched,
                          "uniqueId",
                          <Face />,
                          false,
                          false
                        )}
                      </Grid>
                    </>
                  )}
                  {values.role !== ROLES.ADMIN && (
                    <Grid container item xs={6}>
                      <Grid container item xs={10}>
                        <StyledFormControl
                          variant='filled'
                          sx={{ width: "100%" }}
                        >
                          <InputLabel id='schools'>Schools</InputLabel>
                          <Field
                            as={Select}
                            multiple={values.role === ROLES.OPERATOR && true}
                            labelId='shools'
                            id='schools'
                            name='schools'
                            value={values.schools}
                            onChange={(e: { target: { value: string } }) => {
                              handleChange(e);
                              //to add limit teacher selection options
                              // if (values.role === ROLES.STUDENT) {
                              // }
                            }}
                            renderValue={(selected: string[] | string) =>
                              !(typeof selected === "string")
                                ? selected
                                    .map((s) => getTitle(schools, s))
                                    .join(", ")
                                : getTitle(schools, selected)
                            }
                            MenuProps={menuStyles}
                          >
                            {school_options.map((school, index) => {
                              return (
                                <MenuItem key={index} value={school}>
                                  {values.role === ROLES.OPERATOR && (
                                    <StyledCheckbox
                                      checked={
                                        values.schools.indexOf(school!) > -1
                                      }
                                    />
                                  )}
                                  <ListItemText
                                    primary={getTitle(schools, school!)}
                                  />
                                </MenuItem>
                              );
                            })}
                            {values.role !== ROLES.OPERATOR && (
                              <MenuItem value={""}>
                                <ListItemText primary={"No Selection"} />
                              </MenuItem>
                            )}
                          </Field>
                        </StyledFormControl>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Tooltip title={`Add School`} placement='bottom'>
                          <StyledIconButton
                            aria-label='add'
                            onClick={handleOpen}
                            edge='end'
                          >
                            <AddOne />
                          </StyledIconButton>
                        </Tooltip>
                        <Modal
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          open={open}
                          onClose={handleClose}
                          aria-labelledby='modal-school-form'
                          aria-describedby='modal-school-form'
                        >
                          <div>
                            <SchoolForm modal={handleClose} />
                          </div>
                        </Modal>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item xs={6} justifyContent={"flex-start"}>
                    <FormControlLabel
                      control={
                        <Field
                          as={StyledSwitch}
                          name='active'
                          onChange={handleChange}
                        />
                      }
                      label='Active'
                      checked={values.active}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent={"center"}>
                    <StyledButton
                      className={styles.button}
                      variant='contained'
                      type='submit'
                    >
                      Submit
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default Form;
