import Box from "@mui/material/Box";
import { SyntheticEvent, useDebugValue, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import styles from "../../styles/Form.module.scss";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Toolbar,
} from "@mui/material";
import { StyledButton } from "../styled/Button";
import { StyledTextField } from "../styled/TextField";
import { School } from "../../types/school";
import {
  selectActiveSchool,
  selectSchools,
} from "../../store/selectors/schoolSelectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store/hooks";
import { getSensors } from "../../store/effects/sensorEffects";
import { selectSensors } from "../../store/selectors/sensorSelector";
import { getActiveSchool } from "../../store/actions/schoolActions";
import { TableBar } from "../styled/AppBar";
import MapView from "../Map";
import gjsonAdapter from "../../utils/gjsonAdapter";
import { StyledCheckbox } from "../styled/StyledCheckbox";
import { StyledFormControl, menuStyles } from "../styled/StyledFormSelect";
import { ErrorMessage, Field, Formik } from "formik";
import { yupValidationSchool } from "../../utils/yupValidation";
import { getFormSchool, getSchoolData } from "../../utils/getFormData";
import { createNewSchool, updateSchool } from "../../services/api/schools.api";
import { CustomH3 } from "../styled/CustomH3";
import { getSchools } from "../../store/effects/schoolEffects";
import { getCountries } from "../../store/effects/countriesEffects";
import { selectCountries } from "../../store/selectors/countries";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../../utils/handleApiError";
import AlertDialog from "../Alert";

const SchoolForm = ({ modal, id }: { modal?: any; id?: string }) => {
  const countries = useAppSelector(selectCountries);
  const activeSchool = useAppSelector(selectActiveSchool);
  const [coordinates, setCoordinates] = useState<Array<number>>([]);
  // const sensors = useAppSelector(selectSensors).map((sensor) => sensor.s_id);
  const dispatch = useAppThunkDispatch();
  const router = useNavigate();
  const [sBox, setSBox] = useState([""]);
  const [errorMap, setErrorMap] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  // console.log(activeSchool);
  console.log(countries);

  useEffect(() => {
    // console.log("sens");
    // dispatch(getSensors());
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(getActiveSchool(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeSchool) {
      setCoordinates([activeSchool.lat, activeSchool.lon]);
    }
  }, [activeSchool]);

  return (
    <Container className={styles.formContainer} maxWidth='xl'>
      <Grid container columnSpacing={2}>
        <Grid container item xs={6}>
          <Container className={styles.formContainer} maxWidth='xl'>
            <Box
              sx={{
                background: "#ebebee",
                width: "100%",
                borderRadius: "2px",
                paddingTop: "5px",
                paddingLeft: "10px",
              }}
            >
              {activeSchool === null && (
                <CustomH3 error={errorMap}>
                  Please select the location of the school on the map
                </CustomH3>
              )}
            </Box>
            <MapView
              schools={activeSchool ? gjsonAdapter([activeSchool]) : []}
              viewOnly={false}
              setCoordinates={setCoordinates}
            ></MapView>
          </Container>
        </Grid>
        <Grid container item xs={6}>
          <Paper elevation={3} className={styles.paper}>
            <TableBar position='relative'>
              <Toolbar
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>{`School Information`}</h2>
              </Toolbar>
            </TableBar>
            <Box className={styles.box}>
              <Formik
                initialValues={getSchoolData(activeSchool)}
                onSubmit={(values, actions) => {
                  // values = { ...values, schools: [values.schools] };
                  console.log(values);
                  setErrorMap(true);

                  if (activeSchool) {
                    console.log("edit");
                    const updatedSchool = getFormSchool(
                      values,
                      coordinates,
                      activeSchool.school_id
                    );
                    console.log(updatedSchool);
                    updateSchool(updatedSchool)
                      .then((res) => {
                        console.log(res);
                        // alert(res.message);
                        router(-1);
                      })
                      .catch((e) => {
                        handleApiError(e, setError);
                      });
                  } else {
                    console.log("create");
                    const newSchool = getFormSchool(values, coordinates);
                    console.log(newSchool);
                    if (
                      newSchool.lat === undefined ||
                      newSchool.lon === undefined
                    ) {
                      setErrorMap(true);
                    } else {
                      createNewSchool(newSchool)
                        .then((res) => {
                          console.log(res);
                          if (res.status === 200) {
                            if (modal) {
                              modal();
                            } else {
                              router(-1);
                            }
                          }
                        })
                        .catch((e) => {
                          handleApiError(e, setError);
                        });
                    }
                  }

                  // setTimeout(() => {
                  //   alert(JSON.stringify(values, null, 2));
                  //   actions.setSubmitting(false);
                  // }, 1000);
                }}
                validationSchema={yupValidationSchool}
              >
                {({
                  values,
                  errors,
                  touched,
                  setValues,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    className={styles.form}
                    onSubmit={handleSubmit}
                    autoComplete='off'
                  >
                    <Grid container rowSpacing={2} columnSpacing={6}>
                      <Grid container item xs={12}>
                        <Field
                          as={StyledTextField}
                          required
                          autoComplete='off'
                          id='title'
                          name='title'
                          label='School Name'
                          fullWidth
                          margin='dense'
                          variant='filled'
                          helperText={<ErrorMessage name='title' />}
                          error={errors["title"] && touched["title"]}
                        />
                      </Grid>
                      <Grid container item xs={12}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={2}
                          width={"100%"}
                        >
                          <StyledFormControl
                            variant='filled'
                            sx={{ width: "100%" }}
                          >
                            <InputLabel id='country'>Country</InputLabel>
                            <Field
                              as={Select}
                              labelId='country'
                              id='country'
                              name='country'
                              MenuProps={menuStyles}
                            >
                              {countries.map((country, index) => {
                                return (
                                  <MenuItem key={index} value={country.name}>
                                    <ListItemText primary={country.name} />
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </StyledFormControl>
                          <Field
                            as={StyledTextField}
                            autoComplete='off'
                            labelId='sensor_box'
                            id='sensor_box'
                            name='sensor_box'
                            label='Sensor Box Id'
                            fullWidth
                            margin='dense'
                            variant='filled'
                            helperText={<ErrorMessage name='sensor_box' />}
                            error={
                              errors["sensor_box"] && touched["sensor_box"]
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid container item xs={12}>
                        <Field
                          as={StyledTextField}
                          id='description'
                          label='Description'
                          name='description'
                          variant='filled'
                          multiline
                          fullWidth
                          rows={2}
                          helperText={<ErrorMessage name={"description"} />}
                          error={
                            errors["description"] && touched["description"]
                          }
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent={"center"}>
                        <StyledButton
                          className={styles.button}
                          variant='contained'
                          type='submit'
                        >
                          Submit
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default SchoolForm;
