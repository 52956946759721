import { Action } from "./table";
import { Questionnaire } from "./questionnaire";

export const ROLES = {
  INLECOM: "inlecom",
  ADMIN: "admin",
  OPERATOR: "operator",
  TEACHER: "teacher",
  STUDENT: "student",
} as const;

export type Role = (typeof ROLES)[keyof typeof ROLES];

export type _roles = "inlecom" | "admin" | "operator" | "teacher" | "student";

export type User = Admin | Operator | Teacher | Student;

export type CreatePayload<T> = Omit<T, "_id">;

export class UserCommon {
  _id?: string;
  role: _roles;
  username: string;
  password?: string;
  email?: string;
  enabled: boolean;
  active: boolean;

  constructor(data: any) {
    this.role = data.role;
    this.username = data.username;
    this.password = data.password;
    this.email = data.email;
    this.active = data.active;
    this.enabled = true;
    this._id = data._id;

    // if (data.email !== undefined) {
    //   this.email = data.email;
    // }
  }
}

export class Admin extends UserCommon {
  fullname: string;

  constructor(data: any) {
    super(data);
    this.role = "admin";
    this.fullname = data.fullname;
  }
}

export class Operator extends UserCommon {
  fullname: string;
  schools: string[];

  constructor(data: any) {
    super(data);
    this.schools = data.schools;
    this.role = "operator";
    this.fullname = data.fullname;
  }
}

export class Teacher extends UserCommon {
  fullname: string;
  schools: string[];
  students: string[];

  constructor(data: any) {
    super(data);
    this.schools = [data.schools];
    this.role = "teacher";
    this.fullname = data.fullname;
    this.students = data.students;
  }
}

export class Student extends UserCommon {
  schools?: string[];
  school?: string;
  teacher?: string;
  garmin_id?: string;
  garmin_last_used?: string;
  offline_code?: string;
  registration_code?: string;
  answered_questionnaires?: number;
  questionnaires?: Questionnaire[];
  tags?: string[];
  registered?: boolean | string;

  constructor(data: any) {
    super(data);
    this.schools =
      typeof data.schools === "string" ? [data.schools] : data.schools;
    this.role = "student";
    this.garmin_id = data.garminId;
    this.teacher = data.teacher;
    this.offline_code = data.uniqueId;
    this.registration_code = data.registration_code;
  }
}

export type UserSchema<T> = { actions?: Action[] } & T;

// export interface Operator extends UserCommon {
//   fullname?: string;
//   schools: string[];
//   role: "operator";
// }

// export interface Teacher extends UserCommon {
//   fullname?: string;
//   schools: string[];
//   students: string[];
//   role: "teacher";
// }

// export interface Student extends UserCommon {
//   school: string;
//   teacher: string;
//   role: "student";
//   garminId?: string;
//   uniqueId?: string;
//   questionnaires?: Questionaire[];
// }

// function isΑdmin(user: User): user is Admin {
//   return (user as Admin).schools !== undefined;
// }

export function hasFullname(user: User): user is Teacher | Operator | Admin {
  return (user as Teacher | Operator | Admin).fullname !== undefined;
}

export function hasSchools(user: User): user is Teacher | Student | Operator {
  return (user as Teacher | Student | Operator).schools !== undefined;
}

export function isTeacher(user: User): user is Teacher {
  return (user as Teacher).students !== undefined;
}

export function isStudent(user: User): user is Student {
  return (user as Student).teacher !== undefined;
}
