import { Container } from "@mui/material";
import TabPanels from "./TabPanels";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../consts/theme";

const TutorialPage = () => {
  return (
    <Container maxWidth={"xl"} style={{ paddingRight: "60px" }}>
      <ThemeProvider theme={theme}>
        <TabPanels />
      </ThemeProvider>
    </Container>
  );
};

export default TutorialPage;
