import Button, { ButtonPropsVariantOverrides } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@emotion/react";
import { Fragment, useState } from "react";
import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { theme } from "../consts/theme";
import { StyledButton } from "./styled/Button";

interface Props {
  title: string;
  message: string;
  buttons?: {
    label: string;
    onClick: () => void;
    outlined?: boolean;
  }[];
}

const AlertDialog: React.FC<Props> = ({ title, message, buttons = [] }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Dialog
          open={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <Box sx={{ minWidth: "20rem", padding: "0.65rem" }}>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id='alert-dialog-description'
                textAlign={"justify"}
              >
                {message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {buttons.map((button, index) => (
                <Button
                  variant={button.outlined ? "outlined" : "contained"}
                  key={index}
                  onClick={button.onClick}
                  autoFocus
                >
                  {button.label}
                </Button>
              ))}
            </DialogActions>
          </Box>
        </Dialog>
      </Fragment>
    </ThemeProvider>
  );
};

export default AlertDialog;

interface TransProps {
  open: boolean;
  message: string;
}

export const TransitionAlert: React.FC<TransProps> = ({ open, message }) => {
  const [openDialog, setOpenDialog] = useState(open);

  return (
    <Box sx={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Collapse in={openDialog}>
          <Alert
            severity='warning'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpenDialog(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </ThemeProvider>
    </Box>
  );
};
