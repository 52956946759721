import { ApiResponse } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { Country } from "../../types/country";

export const countriesClient = () => Axios(`${getApiUrl()}/countries`);

export async function fetchCountries() {
  return countriesClient().get<ApiResponse<Country[]>>("");
}
