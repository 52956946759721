import { Box, Container, Tab, Tabs, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Groups, Person, SportsEsports } from "@mui/icons-material";
import { useAppThunkDispatch } from "../../../store/hooks";
import StudentsAnalyticsPage from "./StudentsAnalyticsPage";
import ParentsAnalyticsPage from "./ParentsAnalyticsPage";
import { theme } from "../../../consts/theme";
import GameAnalyticsPage from "./GameAnalyticsPage";

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: { xs: "8px", sm: "10px", lg: "15px" },
  fontSize: { xs: "15px", sm: "15px", lg: "20px" },
};

const AnalyticsPage = () => {
  const [type, setType] = useState<string>("students");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
  };

  return (
    <Container sx={styles.tabs} maxWidth={false}>
      <ThemeProvider theme={theme}>
        <Box sx={{ marginBottom: "20px" }}>
          <Tabs centered value={type} onChange={handleChange}>
            <Tab
              value='students'
              label={
                <Box sx={buttonStyle}>
                  <Groups fontSize='medium' />
                  Students
                </Box>
              }
            />
            <Tab
              value='parents'
              label={
                <Box sx={buttonStyle}>
                  <Person fontSize='medium' />
                  Parents
                </Box>
              }
            />
            <Tab
              value='games'
              label={
                <Box sx={buttonStyle}>
                  <SportsEsports fontSize='medium' />
                  Games
                </Box>
              }
            />
          </Tabs>
        </Box>
        {(() => {
          switch (type) {
            case "students":
              return <StudentsAnalyticsPage />;
            case "parents":
              return <ParentsAnalyticsPage />;
            case "games":
              return <GameAnalyticsPage />;
            default:
              return <></>;
          }
        })()}
      </ThemeProvider>
    </Container>
  );
};

export default AnalyticsPage;

const styles = {
  tabs: {
    position: "absolute",
    zIndex: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
    top: {
      xs: `calc(0px - 60px)`,
      sm: `calc(0px - 70px)`,
    },
  },
};
