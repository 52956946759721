import {
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { theme } from "../../consts/theme";
import { styles } from "./tutorialStyles";

type TutorialContent = {
  imageSrc: string;
  description: string[];
  subtitle?: string;
};

interface TutorialProps {
  title: string;
  subtitle?: string;
  tutorialContents: TutorialContent[];
}

const Tutorial: React.FC<TutorialProps> = ({
  title,
  subtitle,
  tutorialContents,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleClickOpen = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container maxWidth={"lg"}>
      <Box sx={styles.contentBox}>
        <Typography variant='h3'>{title}</Typography>

        <hr
          style={{
            backgroundColor: theme.palette.primary.main,
            width: "100%",
            padding: "0.5px",
          }}
        />

        {tutorialContents.map((content, index) => (
          <Box key={index}>
            {content.subtitle && (
              <Typography variant='h4' sx={styles.subtitle}>
                {content.subtitle}
              </Typography>
            )}
            <Box sx={styles.imgBox}>
              <img
                src={content.imageSrc}
                alt={`Dashboard ${index}`}
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => handleClickOpen(content.imageSrc)}
              />
            </Box>

            <Typography variant='body1' sx={styles.description}>
              <ul>
                {content.description.map((desc, descIndex) => (
                  <li key={descIndex}>{desc}</li>
                ))}
              </ul>
            </Typography>
          </Box>
        ))}

        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
          <DialogContent sx={{ position: "relative", padding: 8 }}>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{ position: "absolute", right: 16, top: 16 }}
            >
              <CloseIcon />
            </IconButton>

            {selectedImage && (
              <TransformWrapper>
                <TransformComponent>
                  <img
                    src={selectedImage}
                    alt='Enlarged View'
                    style={{ width: "100%", cursor: "zoom-in" }}
                  />
                </TransformComponent>
              </TransformWrapper>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Tutorial;
