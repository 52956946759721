import { fetchCountries } from "../../services/api/countries.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { COUNTRIES_ACTIONS } from "../actions/countries";
import { Country } from "../../types/country";

export const getCountries = createAsyncThunk<Country[]>(
  COUNTRIES_ACTIONS.GET_COUNTRIES,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await fetchCountries();
      return responseData.data;
    } catch (error) {
      console.log("Countries effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
