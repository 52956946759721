import { selectMultiple } from "../../consts/description";
import { isNotRequired, isRequired } from "../../consts/required";
import { no, yes, yes_no_notRequired } from "../../consts/yes_no";

const s6q1 = {
  type: "matrixdropdown",
  name: "q1s6q1",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default:
      "Does or did your child share the bedroom with other people (adults or children)?",
    gr: "Μοιράζεται ή μοιραζόταν το παιδί σας το υπνοδωμάτιο του με άλλα άτομα (ενήλικες ή παιδιά) ;",
    fr: "Votre enfant partage-t-il/elle ou a-t-il/elle partagé sa chambre avec d’autres personnes (adultes ou enfants) ?",
    de: "",
    ka: "თქვენს შვილთან ერთად საძინებელში სძინავთ თუ არა ოჯახის სხვა წევრებსაც (ზრდასრულებს ან ბავშვებს)?",
    fi: "Jakaako tai jakoiko lapsenne makuuhuoneen muiden ihmisten (aikuisten tai lasten) kanssa?",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q2 = {
  type: "matrixdropdown",
  name: "q1s6q2",
  isRequired: isNotRequired,
  colCount: 5,
  title: {
    default:
      "Which of the following pets do or did you keep inside your child’s home?",
    gr: "Ποιο από τα παρακάτω κατοικίδια ζώα είχατε ή έχετε εντός του σπιτιού που μένει/έμενε το παιδι σας; ",
    fr: "Parmi les animaux suivants, lesquels gardez-vous ou avez-vous gardé à l’intérieur du domicile ?",
    de: "",
    ka: "რომელი შინაური ცხოველი გყავთ ან გყავდათ სახლში, სადაც თქვენი შვილი ცხოვრობს?",
    fi: "Mitä seuraavista lemmikkieläimistä pidätte tai piditte sisällä?",
  },
  columns: [
    {
      name: "pets",
      title: {
        default: "Pets",
        gr: "Κατοικίδια",
        fr: "Animaux domestiques",
        de: "",
        ka: "შინაური ცხოველები",
        fi: "Pemmikkejä",
      },
      isRequired: isNotRequired,
      choices: [
        {
          value: "dog",
          text: {
            default: "Dog",
            gr: "Σκύλος",
            fr: "Chien",
            de: "",
            ka: "ძაღლი",
            fi: "Koira",
          },
        },
        {
          value: "cat",
          text: {
            default: "Cat",
            gr: "Γάτα",
            fr: "Chat",
            de: "",
            ka: "კატა",
            fi: "Kissa",
          },
        },
        {
          value: "other_furry_pets",
          text: {
            default: "Other furry pets",
            gr: "Άλλα κατοικίδια ζώα με τρίχωμα",
            fr: "Autres animaux à fourrure",
            de: "",
            ka: "სხვა ბეწვიანი ცხოველები",
            fi: "Muita karvaisia lemmikkejä",
          },
        },
        {
          value: "bird",
          text: {
            default: "Bird",
            gr: "Πτηνό",
            fr: "Oiseau",
            de: "",
            ka: "ჩიტები",
            fi: "Lintu",
          },
        },
        {
          value: "none",
          text: {
            default: "None",
            gr: "Κανένα",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
      ],
      cellType: "checkbox",
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q3 = {
  type: "matrixdropdown",
  name: "q1s6q3",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default:
      "Does or did your child have at least once a week contact with any of the following animals outside your child’s home? ",
    gr: "Έχει ή είχε το παιδί σας τουλάχιστον μία φορά την εβδομάδα επαφή με οποιοδήποτε από τα παρακάτω ζώα εκτός σπιτιού; ",
    fr: "Votre enfant a-t-il ou a-t-il eu au moins une fois par semaine des contacts avec l’un des animaux suivants en dehors de son domicile ?",
    de: "",
    ka: "სახლის გარდა, კვირაში სულ მცირე  ერთხელ, აქვს ან ჰქონდა თუ არა თქვენს შვილს კონტაქტი ქვემოთ ჩამოთვლილ ცხოველებთან ?",
    fi: "Onko tai oliko lapsenne vähintään kerran viikossa tekemisissä seuraavien eläinten kanssa lapsenne kodin ulkopuolella?",
  },
  columns: [
    {
      name: "pets",
      title: {
        default: "Pets",
        gr: "Κατοικίδια",
        fr: "animaux domestiques",
        de: "",
        ka: "შინაური ცხოველები",
        fi: "Pemmikkejä",
      },
      isRequired: isNotRequired,
      choices: [
        {
          value: "dog",
          text: {
            default: "Dog",
            gr: "Σκύλος",
            fr: "Chien",
            de: "",
            ka: "ძაღლი",
            fi: "Koira",
          },
        },
        {
          value: "cat",
          text: {
            default: "Cat",
            gr: "Γάτα",
            fr: "Chat",
            de: "",
            ka: "კატა",
            fi: "Kissa",
          },
        },
        {
          value: "other_furry_pets",
          text: {
            default: "Other furry pets",
            gr: "Άλλα κατοικίδια ζώα με τρίχωμα",
            fr: "Autres animaux à fourrure",
            de: "",
            ka: "სხვა ბეწვიანი ცხოველები",
            fi: "Muita karvaisia lemmikkejä",
          },
        },
        {
          value: "bird",
          text: {
            default: "Bird",
            gr: "Πτηνό",
            fr: "Oiseau",
            de: "",
            ka: "ჩიტები",
            fi: "Lintu",
          },
        },
        {
          value: "other",
          text: {
            default: "Other",
            gr: "Άλλα",
            fr: "Autres",
            de: "",
            ka: "სხვა",
            fi: "Muita",
          },
        },
        no
      ],
      cellType: "checkbox",
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q4 = {
  type: "matrixdropdown",
  name: "q1s6q4",
  isRequired: isNotRequired,
  colCount: 2,
  title: {
    default: "Does or did your child’s mother smoke?",
    gr: "Καπνίζει ή κάπνιζε η μητέρα του παιδιού σας ;",
    fr: "La mère de votre enfant fume-t-elle ou a-t-elle fumé ?",
    de: "",
    ka: "ეწევა ან ეწეოდა  თუ არა ბავშვის დედა სიგარეტს?",
    fi: "Tupakoiko tai onko lapsen äiti tupakoinut? ",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q5 = {
  type: "matrixdropdown",
  name: "q1s6q5",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default: "Does or did your child’s father smoke?",
    gr: "Καπνίζει ή κάπνιζε ο πατέρας του παιδιού σας ; ",
    fr: "Le père de votre enfant fume-t-il ou a-t-il fumé ?",
    de: "",
    ka: "ეწევა ან ეწეოდა  თუ არა ბავშვის მამა  სიგარეტს?",
    fi: "Tupakoiko tai onko lapsen isä tupakoinut?",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q6 = {
  type: "radiogroup",
  name: "q1s6q6",
  isRequired: isNotRequired,
  title: {
    default: "Does anybody, at present, smoke inside your child’s home?",
    gr: "Καπνίζει κανείς σήμερα εντός του σπιτιού που μένει το παιδί σας; ",
    fr: "Y a-t-il actuellement quelqu’un qui fume à l’intérieur de la maison de votre enfant ? ",
    de: "",
    ka: "ამჟამად  ეწევა თუ არა სიგარეტს რომელიმე ოჯახის წევრი  იმ სახლში, სადაც თქვენი შვილი ცხოვრობს?",
    fi: "Tupakoiko kukaan tällä hetkellä lapsenne kotona?",
  },
  choices: [yes, no],
};

const s6q7 = {
  type: "radiogroup",
  name: "q1s6q7",
  visibleIf: "{q1s6q6} == 'yes' ",
  title: {
    default:
      "How many cigarettes in total are smoked per day in the child’s home?",
    gr: "Εάν Ναι, πόσα συνολικά τσιγάρα καπνίζονται την ημέρα στο σπίτι του παιδιού;",
    fr: "Si oui, combien de cigarettes au total sont fumées par jour au domicile de l’enfant",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ჯამში რამდენ  ღერ სიგარეტს ეწევიან ყოველდღიურად სახლში, სადაც თქვენი შვილი ცხოვრობს? ",
    fi: "Jos kyllä, kuinka monta savuketta lapsen kotona poltetaan yhteensä päivässä?",
  },
  description: {
    default:
      "E.g. mother smokes 4 + father smokes 5 + other persons smoke 3 = 12 cigarettes",
    gr: "Π.χ η μητέρα καπνίζει 4 + ο πατέρας καπνίζει 5 + άλλα άτομα καπνίζουν 3 = 12 τσιγάρα",
    fr: "Par exemple, la mère en fume 4 + le père en fume 5 + d’autres personnes en fument 3 = 12 cigarettes) ",
    de: "",
    ka: "მაგ: დედა ეწევა 4 ღერს, მამა 5 ღერს, სხვა წევრი 3. ჯამში 12 ღერი სიგარეტი",
    fi: "Esim. äiti polttaa 4 savuketta + isä polttaa 5 savuketta + muut henkilöt polttavat 3 savuketta = 12 savuketta",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "<10>",
      text: {
        default: "Less than 10 cigarettes",
        gr: "Λιγότερα από 10 τσιγάρα ",
        fr: "Moins de 10 cigarettes",
        de: "",
        ka: "10 ღერ  სიგარეტზე ნაკლები",
        fi: "Vähemmän kuin 10 savuketta",
      },
    },
    {
      value: "10-20",
      text: {
        default: "10‐20 cigarettes",
        gr: "10‐20 τσιγάρα",
        fr: "10-20 cigarettes",
        de: "",
        ka: "10‐20 ღერი სიგარეტი",
        fi: "10-20 savuketta",
      },
    },
    {
      value: ">20",
      text: {
        default: "More than 20 cigarette",
        gr: "Περισσότερα από 20 τσιγάρα",
        fr: "Plus de 20 cigarettes ",
        de: "",
        ka: "20 ღერ  სიგარეტზე მეტი",
        fi: "Enemmän kuin 20 savuketta",
      },
    },
  ],
};

const s6q8 = {
  type: "text",
  name: "q1s6q8",
  visibleIf: "{q1s6q6} == 'yes' ",
  title: {
    default: "In which room does, he/she smokes?",
    gr: "Σε ποιο δωμάτιο καπνίζει;",
    fr: "Si oui, dans quelle pièce fume-t-il/elle ?",
    de: "",
    ka: "ასევე, დაასახელეთ ოთახი სადაც სიგარეტს ეწევიან",
    fi: "Jos kyllä, missa huoneissa hän polttaa?",
  },
  maxLength: 50,
  isRequired: isNotRequired,
};

const s6q9 = {
  type: "matrixdropdown",
  name: "q1s6q9",
  colCount: 6,
  isRequired: isNotRequired,
  title: {
    default: "Which fuel do or did you use for cooking?",
    gr: "Τι χρησιμοποιείτε για το μαγείρεμα;",
    fr: "Quel combustible utilisez-vous ou avez-vous utilisé pour cuisiner?",
    de: "",
    ka: "რა სახის საწვავს იყენებდით ან იყენებთ საკვების მოსამზადებლად?",
    fi: "Mitä energiaa käytätte tai käytitte ruoanlaittoon?",
  },
  description: selectMultiple,
  columns: [
    {
      name: "cooking_fuel",
      title: {
        default: "Cooking Fuel",
        gr: "Καύσιμο μαγειρικής",
        fr: "Combustible de cuisson",
        de: "",
        ka: "სამზარეულოს საწვავი",
        fi: "Ruoanlaittopolttoaine",
      },
      isRequired: isNotRequired,
      choices: [
        {
          value: "electricity",
          text: {
            default: "Electricity",
            gr: "Ηλεκτρικό",
            fr: "Électricité",
            de: "",
            ka: "ელექტროენერგია",
            fi: "Sähkö",
          },
        },
        {
          value: "gas",
          text: {
            default: "Gas",
            gr: "Αέριο",
            fr: "Gaz",
            de: "",
            ka: "ბუნებრივი აირი",
            fi: "Kaasu",
          },
        },
        {
          value: "coal_wood",
          text: {
            default: "Coal or wood",
            gr: "Κάρβουνο ή Ξύλο",
            fr: "Charbon ou bois",
            de: "",
            ka: "ნახშირი ან შეშა",
            fi: "Hiili tai puu",
          },
        },
        {
          value: "other",
          text: {
            default: "Other",
            gr: "Άλλο",
            fr: "Autres",
            de: "",
            ka: "სხვა",
            fi: "Muu",
          },
        },
      ],
      cellType: "checkbox",
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q10 = {
  type: "matrixdropdown",
  name: "q1s6q10",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default: "How is or was your child’s home heated?",
    gr: "Πως θερμαίνεται ή θερμαινόταν το σπίτι που μένει/έμενε το παιδί σας;",
    fr: "Comment la maison de votre enfant est-elle ou était-elle chauffée ? ",
    de: "",
    ka: "როგორ თბებოდა ან თბება ბინა, სადაც თქვენი ბავშვი ცხოვრობს?",
    fi: "Miten lapsenne kotia lämmitetään tai lämmitettiin? ",
  },
  description: selectMultiple,
  columns: [
    {
      choices: [
        {
          value: "one_fire_inside",
          text: {
            default: "One fire, stove or boiler inside the home",
            gr: "Με ένα τζάκι, μια σόμπα ή ένα λέβητα μέσα στο σπίτι",
            fr: "Un feu, un poêle ou une chaudière à l’intérieur de la maison",
            de: "",
            ka: "ბუხარი,ქურა ან ბოილერი საცხოვრებელი ფართის შიგნით (ერთი )",
            fi: "Yksi tulisija, liesi tai boileri kodin sisällä",
          },
        },
        {
          value: "more_than_one_fire_inside",
          text: {
            default: "More than one fire, stove or boiler inside the home",
            gr: "Περισσότερα από ένα τζάκια, σόμπες ή λέβητες μέσα στο σπίτι",
            fr: "Plus d’un feu, d’un poêle ou de chaudière à l’intérieur de la maison",
            de: "",
            ka: "ბუხარი,ქურა ან ბოილერი საცხოვრებელი ფართის შიგნით (რამოდენიმე)",
            fi: "Enemmän kuin yksi tulisija, liesi tai boileri kodin sisällä",
          },
        },
        {
          value: "one_fire_outside",
          text: {
            default: "A fire, stove or boiler outside the home",
            gr: "Με τζάκι, σόμπα ή λέβητα έξω από το σπίτι",
            fr: "Un feu, un poêle ou une chaudière à l’extérieur de la maison",
            de: "",
            ka: "ბუხარი,ქურა ან ბოილერი საცხოვრებელი ფართის გარეთ",
            fi: "Yksi tulisija, liesi tai boileri kodin ulkopuolella",
          },
        },
        {
          value: "not_heated",
          text: {
            default: "Not heated",
            gr: "Δεν έχει θέρμανση",
            fr: "Non chauffée",
            de: "",
            ka: "საცხოვრებელი ფართი არ თბება",
            fi: "Ei lämmitystä",
          },
        },
      ],
      cellType: "checkbox",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q11 = {
  type: "matrixdropdown",
  name: "q1s6q11",
  colCount: 6,
  isRequired: isNotRequired,
  title: {
    default: "Which fuel do or did you use for heating?",
    gr: "Τι είδους θέρμανση χρησιμοποιείτε ή χρησιμοποιούσατε;",
    fr: "Quel combustible utilisez-vous ou avez-vous utilisé pour vous chauffer ?",
    de: "",
    ka: "რომელი ტიპის საწვავი გამოიყენებოდა ან გამოიყენება სახლის გასათბობად?",
    fi: "Mitä energiaa käytätte tai käytitte lämmitykseen?",
  },
  description: selectMultiple,
  columns: [
    {
      name: "heating_fuel",
      title: {
        default: "Heating Fuel",
        gr: "Καύσιμο Θέρμανσης",
        fr: "Combustible de chauffage",
        de: "",
        ka: "გათბობის საწვავი",
        fi: "Lämmitys polttoaine",
      },
      isRequired: isNotRequired,
      choices: [
        {
          value: "gas",
          text: {
            default: "Gas",
            gr: "Αέριο",
            fr: "Gaz",
            de: "",
            ka: "ბუნებრივი აირი",
            fi: "Kaasu",
          },
        },
        {
          value: "oil",
          text: {
            default: "Oil",
            gr: "Πετρέλαιο",
            fr: "Huile",
            de: "",
            ka: "ნავთი/მაზუთი",
            fi: "Öljy",
          },
        },
        {
          value: "electricity",
          text: {
            default: "Electricity",
            gr: "Ηλεκτρικό",
            fr: "Électricité",
            de: "",
            ka: "ელექტრო ენერგია",
            fi: "Sähkö",
          },
        },
        {
          value: "coal_coke",
          text: {
            default: "Coal or coke",
            gr: "Κάρβουνο",
            fr: "Charbon",
            de: "",
            ka: "ნახშირი ან კოქსი",
            fi: "Hiili tai koksi",
          },
        },
        {
          value: "wood",
          text: {
            default: "Wood",
            gr: "Ξύλο",
            fr: "Bois",
            de: "",
            ka: "შეშა",
            fi: "puu",
          },
        },
        {
          value: "other",
          text: {
            default: "Other",
            gr: "Άλλο",
            fr: "Autres",
            de: "",
            ka: "სხვა ",
            fi: "Muu",
          },
        },
      ],
      cellType: "checkbox",
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q12 = {
  type: "matrixdropdown",
  name: "q1s6q12",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default: "Does or did your child’s home have air conditioning? ",
    gr: "Έχει ή είχε το σπίτι που μένει/έμενε το παιδί σας κλιματιστικό (air-condition);",
    fr: "La maison de votre enfant est-elle ou était-elle équipée d’un système de climatisation ? ",
    de: "",
    ka: "ჰქონდა ან აქვს თუ არა ჰაერის კონდეცირება ბინას, სადაც თქვენი შვილი ცხოვრობს?",
    fi: "Onko tai oliko lapsenne kodissa ilmastointia?",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q13 = {
  type: "matrixdropdown",
  name: "q1s6q13",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default:
      "Does or did your child’s home have damp spots on the walls or ceiling?",
    gr: "Έχει ή είχε το σπίτι που μένει/έμενε το παιδί σας σημεία υγρασίας στους τοίχους ή στο ταβάνι;",
    fr: "La maison de votre enfant présente-t-elle ou a-t-elle présenté des taches d’humidité sur les murs ou les plafonds ?",
    de: "",
    ka: "ჰქონდა ან აქვს თუ არა სახლის კედლებს ან ჭერს  ნესტის ლაქები ?",
    fi: "Onko tai oliko lapsesi kotona seinissä tai katossa kosteita kohtia?",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q14 = {
  type: "matrixdropdown",
  name: "q1s6q14",
  colCount: 2,
  isRequired: isNotRequired,
  title: {
    default:
      "Does or did your child’s home have visible molds or fungus on the walls or ceiling? ",
    gr: "Έχει ή είχε το σπίτι που μένει/έμενε το παιδί σας ορατή μούχλα στους τοίχους ή το ταβάνι; ",
    fr: "La maison de votre enfant présente-t-elle ou a-t-elle présenté des moisissures ou des champignons visibles sur les murs ou les plafonds ?",
    de: "",
    ka: "ჰქონდა/აქვს თუ არა სახლის კედლებს ან ჭერს ადვილად შესამჩნევი ობის ან სხვა სოკოთი დაზიანებული უბნები?",
    fi: "Onko tai oliko lapsesi kotona näkyviä homeita tai sieniä seinissä tai katossa? ",
  },
  choices: [yes, no],
  columns: [yes_no_notRequired],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q15 = {
  name: "q1s6q15",
  type: "radiogroup",
  title: {
    default: "Does your child’s room have visible mold or humidity?",
    gr: "Έχει το υπνοδωμάτιο του παιδιού σας ορατή μούχλα ή υγρασία; ",
    fr: "La chambre de votre enfant présente-t-elle des moisissures visibles ou de l’humidité ?",
    de: "",
    ka: "თქვენი შვილის ოთახში შეიმჩნევა თუ არა ობით ან ტენიანობით დაზიანებული  შესამჩნევი უბნები?",
    fi: "Onko lapsesi huoneessa näkyvää hometta tai kosteutta?",
  },
  isRequired: isNotRequired,
  choices: [yes, no],
};

const s6q16 = {
  type: "radiogroup",
  name: "q1s6q16",
  isRequired: isNotRequired,
  title: {
    default: "Are there any pests or in the house?",
    gr: "Υπάρχουν τρωκτικά ή παράσιτα στο σπίτι;",
    fr: "Y a-t-il des parasites dans la maison ?",
    de: "",
    ka: "არიან  თუ არა სახლში სხვადასხვა მავნებლები?",
    fi: "Onko talossa tuholaisia? ",
  },
  choices: [yes, no],
};

const s6q17 = {
  type: "checkbox",
  name: "q1s6q17",
  colCount: 5,
  visibleIf: "{q1s6q16} == 'yes' ",
  title: {
    default: "Specify",
    gr: "Εάν ναι προσδιορίστε:",
    fr: "Si oui, précisez :",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, გთხოვთ დააკონკრეტოთ: ",
    fi: "Jos kyllä, tarkenna",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "rats_mice",
      text: {
        default: "Rats, mice",
        gr: "Αρουραίοι, ποντίκια",
        fr: "Rats, souris",
        de: "",
        ka: "ვირთაგვა, თაგვი",
        fi: "Rottia, hiiriä",
      },
    },
    {
      value: "cockroaches",
      text: {
        default: "Cockroaches",
        gr: "Κατσαρίδες",
        fr: "Cafards",
        de: "",
        ka: "ტარაკანი",
        fi: "Torakoita",
      },
    },
    {
      value: "woodworms",
      text: {
        default: "Woodworms",
        gr: "Σαράκι/Ξυλοσκώληκες",
        fr: "Vers à bois",
        de: "",
        ka: "ჩრჩილი",
        fi: "Puumatoja",
      },
    },
    {
      value: "fleas",
      text: {
        default: "Fleas",
        gr: "Ψύλλοι",
        fr: "Puces",
        de: "",
        ka: "რწყილები",
        fi: "Kirppuja",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλο",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muita",
      },
    },
  ],
};

const s6q18 = {
  type: "matrixdropdown",
  name: "q1s6q18",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default:
      "What kind of floor covering is or was there in your child’s bedroom? ",
    gr: "Με τι καλύπτεται το πάτωμα στο υπνοδωμάτιο του παιδιού σας;",
    fr: "Quel type de revêtement de sol y a-t-il ou y avait-il dans la chambre de votre enfant ?",
    de: "",
    ka: "რა მასალით იყო ან არის დაფარული თქვენი შვილის საძინებლის იატაკი?",
    fi: "Minkälainen lattiapäällyste on tai oli lapsenne makuuhuoneessa?",
  },
  columns: [
    {
      choices: [
        {
          value: "fitted_carpets",
          text: {
            default: "Fitted carpets",
            gr: "Μοκέτα",
            fr: "Tapis posés",
            de: "",
            ka: "ფიქსირებული ხალიჩა",
            fi: "Sovitettu matto",
          },
        },
        {
          value: "loose_carpets",
          text: {
            default: "Loose carpets",
            gr: "Χαλί",
            fr: "Tapis décollés",
            de: "",
            ka: "დასაფენი  ხალიჩა",
            fi: "Irtonainen matto",
          },
        },
        {
          value: "bare_floor",
          text: {
            default: "Bare floor",
            gr: "Ακάλυπτο",
            fr: "Sol nu",
            de: "",
            ka: "შიშველი იატაკი",
            fi: "Paljas lattia",
          },
        },
      ],
      cellType: "radiogroup",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q19 = {
  type: "matrixdropdown",
  name: "q1s6q19",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default: "What kind of windows are or were there in your child’s bedroom?",
    gr: "Τι είδους παράθυρα υπάρχουν ή υπήρχαν στο υπνοδωμάτιο του παιδιού σας;",
    fr: "Quel type de fenêtres y a-t-il ou y avait-il dans la chambre de votre enfant?",
    de: "",
    ka: "რა  ტიპის ფანჯრები იყო ან არის თქვენი შვილის საძინებელში?",
    fi: "Millaisia ikkunoita lapsenne makuuhuoneessa on tai oli?",
  },
  description: selectMultiple,
  columns: [
    {
      choices: [
        {
          value: "single_glazing",
          text: {
            default: "Single glazing",
            gr: "Μονό τζάμι",
            fr: "Simple vitrage",
            de: "",
            ka: "ჩვეულებრივი ფანჯარაერთი შუშით",
            fi: "Yksittäiset lasit",
          },
        },
        {
          value: "secondary_window",
          text: {
            default: "Secondary window",
            gr: "Διπλά παράθυρα",
            fr: "Fenêtre secondaire",
            de: "",
            ka: "ორმაგი ფანჯარა",
            fi: "Toissijainen ikkuna",
          },
        },
        {
          value: "sealed_unit_double_glazing",
          text: {
            default: "Sealed unit/double glazing",
            gr: "Σφραγισμένη μονάδα/διπλό τζάμι",
            fr: "Unité scellée/double vitrage",
            de: "",
            ka: "მინა-პაკეტით აწყობილი ფანჯარა",
            fi: "Eristetty kokonaisuus/tuplalasitus",
          },
        },
        {
          value: "no_windows",
          text: {
            default: "No windows",
            gr: "Δεν υπάρχουν παράθυρα",
            fr: "Pas de fenêtres",
            de: "",
            ka: "ფანჯრის გარეშე",
            fi: "Ei ikkunoita",
          },
        },
      ],
      cellType: "checkbox",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q20 = {
  type: "matrixdropdown",
  name: "q1s6q20",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default: "What kind of pillow does or did your child use?",
    gr: "Τι είδους μαξιλάρι χρησιμοποιεί ή χρησιμοποιούσε το παιδί σας;",
    fr: "Quel type d’oreiller votre enfant utilise-t-il ou a-t-il utilisé ? ",
    de: "",
    ka: "რა სახის ბალიშს იყენებდა ან იყენებს თქვენი შვილი?",
    fi: "Millaista tyynyä lapsenne käyttää tai käytti? ",
  },
  description: selectMultiple,
  columns: [
    {
      choices: [
        {
          value: "foam",
          text: {
            default: "Foam",
            gr: "Αφρού",
            fr: "Mousse",
            de: "",
            ka: "ხელოვნური ქაფით/ღრუბელით",
            fi: "Vaahtomuovi",
          },
        },
        {
          value: "synthetic_fiber",
          text: {
            default: "Synthetic fiber",
            gr: "Συνθετικό",
            fr: "Fibre synthétique",
            de: "",
            ka: "სინთეზური ბოჭკოთი",
            fi: "Synteettinen kuitu",
          },
        },
        {
          value: "feather",
          text: {
            default: "Feather",
            gr: "Πουπουλένιο",
            fr: "Plume",
            de: "",
            ka: "ბუმბულით",
            fi: "Höyhen",
          },
        },
        {
          value: "other",
          text: {
            default: "Other",
            gr: "Άλλο",
            fr: "Autres",
            de: "",
            ka: "სხვა",
            fi: "Muu",
          },
        },
        {
          value: "no_pillow",
          text: {
            default: "Does not use pillow",
            gr: "Δεν χρησιμοποιεί μαξιλάρι ",
            fr: "N’utilise pas d’oreiller",
            de: "",
            ka: "ბალიშს  არ იყენებს",
            fi: "Ei käytä tyynyä",
          },
        },
      ],
      cellType: "checkbox",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q21 = {
  type: "matrixdropdown",
  name: "q1s6q21",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default: "What kind of bedding does or did your child use?",
    gr: "Τι είδους κλινοσκεπάσματα χρησιμοποιεί ή χρησιμοποιούσε το παιδί σας;",
    fr: "Quel type de literie votre enfant utilise-t-il ou a-t-il utilisé ?",
    de: "",
    ka: "რა სახის საწოლ კომპლექტს იყენებდა ან იყენებს თქვენი შვილი?",
    fi: "Millaisia vuodevaatteita lapsenne käyttää tai käytti?",
  },
  description: selectMultiple,
  columns: [
    {
      choices: [
        {
          value: "synthetic_quilt ",
          text: {
            default: "Synthetic quilt ",
            gr: "Συνθετικό πάπλωμα",
            fr: "Couette synthétique",
            de: "",
            ka: "სინთეზური მასალით შევსებულს",
            fi: "Synteettinen peitto",
          },
        },
        {
          value: "feather_quilt",
          text: {
            default: "Feather quilt",
            gr: "Πουπουλένιο πάπλωμα",
            fr: "Couette en plumes",
            de: "",
            ka: "ბუმბულის შიგთავსით",
            fi: "Höyhen peitto",
          },
        },
        {
          value: "blankets",
          text: {
            default: "Blankets",
            gr: "Κουβέρτα",
            fr: "Couvertures",
            de: "",
            ka: "თხელი საბანი შიგთავსის გარეშე",
            fi: "Vilttejä",
          },
        },
        {
          value: "other",
          text: {
            default: "Other materials",
            gr: "Άλλο υλικό",
            fr: "Autres matériaux",
            de: "",
            ka: "სხვა",
            fi: "Muita materiaaleja",
          },
        },
      ],
      cellType: "checkbox",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q22 = {
  type: "matrixdropdown",
  name: "q1s6q22",
  isRequired: isNotRequired,
  title: {
    default:
      "Have you made any changes in your home because your child had asthma or allergic problems?",
    gr: "Έχετε κάνει οποιεσδήποτε αλλαγές στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά συμπτώματα;",
    fr: "Avez-vous fait des changements dans votre maison parce que votre enfant avait de l’asthme ou des problèmes allergiques?",
    de: "",
    ka: "შეცვალეთ  თუ არა სახლში რაიმე იმ მიზეზით, რომ თქვენს შვილს ჰქონდა ასთმა  ან სხვა ალერგიული პრობლემა?",
    fi: "Oletteko tehnyt muutoksia kotonanne, koska lapsellanne oli astma- tai allergiaongelmia?",
  },
  horizontalScroll: true,
  colCount: 2,
  columnMinWidth: "130px",
  description: selectMultiple,
  columns: [
    {
      name: "yes_no_notRequired",
      title: {
        default: "Yes/No?",
        gr: "Ναι/Όχι?",
        fr: "Oui/Non?",
        de: "",
        ka: "კი/არა?",
        fi: "Kyllä/Ei?",
      },
      choices: [yes, no],
      isRequired: isNotRequired,
      colCount: 2,
      cellType: "radiogroup",
    },
    {
      name: "age",
      title: {
        default: "If yes, at what age of the child? (years)",
        gr: "Εάν Ναι, σε ποια ηλικία του παιδιού; (ετών)",
        fr: "Si oui, à quel âge de l’enfant? (ans)",
        de: "",
        ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
        fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
      },
      cellType: "text",
      inputType: "number",
      min: 0,
      max: 10,
      visibleIf: "{row.yes_no_notRequired} == 'yes'",
      isRequired: isNotRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 10",
          minValue: 0,
          maxValue: 10,
        },
      ],
    },
  ],
  rows: [
    {
      value: "remove_pets",
      text: {
        default: "Removed pets",
        gr: "Απομακρύνατε τα κατοικίδια ζώα",
        fr: "Animaux retirés :",
        de: "",
        ka: "უარი თქვით შინაურ ცხოველებზე",
        fi: "Luovuttu lemmikeistä",
      },
    },
    {
      value: "stopped_reduced_smoking",
      text: {
        default: "Stopped or reduced smoking",
        gr: "Σταματήσατε ή μειώσατε το κάπνισμα",
        fr: "A arrêté ou réduit sa consommation de tabac:",
        de: "",
        ka: "შეწყვიტეთ ან შევამცირეთ სიგარეტის მოხმარება",
        fi: "Lopetettu tai vähennetty tupakointia",
      },
    },
    {
      value: "changed_pillows",
      text: {
        default: "Changed pillows",
        gr: "Αλλάξατε μαξιλάρια",
        fr: "Changement d’oreiller :",
        de: "",
        ka: "შეცვალეთ ბალიშები",
        fi: "Vaihdettu tyynyjä",
      },
    },
    {
      value: "changed_bedding",
      text: {
        default: "Changed bedding",
        gr: "Αλλάξατε κλινοσκεπάσματα",
        fr: "Changement de literie :",
        de: "",
        ka: "შეცვალეთ საბანი ",
        fi: "Vaihdettu vuodevaatteita",
      },
    },
    {
      value: "changed_floor",
      text: {
        default: "Changed floor",
        gr: "Αλλάξατε κάλλυμα πατώματος",
        fr: "Changement d’étage :",
        de: "",
        ka: "შეცვალეთ იატაკი",
        fi: "Vaihdettu lattiaa",
      },
    },
    {
      value: "other",
      text: {
        default: "Other Changes",
        gr: "Άλλες αλλαγές",
        fr: "Autres changements :",
        de: "",
        ka: "სხვა ცვლილებები",
        fi: "Jotain muuta",
      },
    },
  ],
};

const s6q23 = {
  type: "text",
  name: "q1s6q23",
  visibleIf: "{q1s6q22.other.yes_no_notRequired} == 'yes' ",
  title: {
    default: "Please describe the changes:",
    gr: "Εάν Ναι, παρακαλώ περιγράψτε τις αλλαγές:",
    fr: "Si oui, veuillez décrire les changements:",
    de: "",
    ka: "გთხოვთ დააკონკრეტოთ ცვლილება:",
    fi: "Jos kyllä, kuvaile muutokset:",
  },
  maxLength: 100,
  isRequired: isNotRequired,
};

// const s6q22 = {
//   type: "radiogroup",
//   name: "q1s6q22",
//   title: {
//     default:
//       "Have you removed pets in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε αφαιρέσει κατοικίδια από το σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Vous avez retiré des animaux de votre maison parce que votre enfant souffrait d'asthme ou de problèmes allergiques ?",
//     de: "",
//     ka: "მოიცილეთ თუ არა შინაური ცხოველები თქვენს სახლში, რადგან თქვენს შვილს ჰქონდა ასთმა ან ალერგიული პრობლემები?",
//     fi: "Oletko poistanut kotoa lemmikkejä, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q23 = {
//   type: "text",
//   name: "q1s6q23",
//   visibleIf: "{q1s6q22} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
//   validators: [
//     {
//       type: "numeric",
//       text: "Value must be up to 10",
//       minValue: 0,
//       maxValue: 10,
//     },
//   ],
// };

// const s6q24 = {
//   type: "radiogroup",
//   name: "q1s6q24",
//   title: {
//     default:
//       "Have you stopped or reduced smoking in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε σταματήσει ή μειώσει το κάπνισμα στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Avez-vous arrêté ou réduit le tabagisme à la maison parce que votre enfant souffrait d'asthme ou de problèmes allergiques ",
//     de: "",
//     ka: "შეწყვიტეთ თუ შეამცირეთ მოწევა თქვენს სახლში, რადგან თქვენს შვილს ჰქონდა ასთმა ან ალერგიული პრობლემები?",
//     fi: "Oletko lopettanut tai vähentänyt tupakointia kotonasi, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q25 = {
//   type: "text",
//   name: "q1s6q25",
//   visibleIf: "{q1s6q24} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
//   validators: [
//     {
//       type: "numeric",
//       text: "Value must be up to 10",
//       minValue: 0,
//       maxValue: 10,
//     },
//   ],
// };

// const s6q26 = {
//   type: "radiogroup",
//   name: "q1s6q26",
//   title: {
//     default:
//       "Have you changed pillows in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε αλλάξει μαξιλάρια στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Avez-vous changé d'oreiller chez vous parce que votre enfant souffrait d'asthme ou de problèmes allergiques ?",
//     de: "",
//     ka: "გამოუცვალეთ თუ არა ბალიშები თქვენს სახლში იმის გამო, რომ თქვენს შვილს ასთმა ან ალერგიული პრობლემები ჰქონდა?",
//     fi: "Oletko vaihtanut tyynyjä kotonasi, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q27 = {
//   type: "text",
//   name: "q1s6q27",
//   visibleIf: "{q1s6q26} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
//   validators: [
//     {
//       type: "numeric",
//       text: "Value must be up to 10",
//       minValue: 0,
//       maxValue: 10,
//     },
//   ],
// };

// const s6q28 = {
//   type: "radiogroup",
//   name: "q1s6q28",
//   title: {
//     default:
//       "Have you chanegd bedding in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε αλλάξει κλινοσκεπάσματα στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Vous avez changé la literie de votre maison parce que votre enfant souffrait d'asthme ou de problèmes allergiques ?",
//     de: "",
//     ka: "შეცვალეთ თუ არა საწოლები თქვენს სახლში, რადგან თქვენს შვილს ჰქონდა ასთმა ან ალერგიული პრობლემები?",
//     fi: "Oletko vaihtanut vuodevaatteet kotonasi, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q29 = {
//   type: "text",
//   name: "q1s6q29",
//   visibleIf: "{q1s6q28} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
//   validators: [
//     {
//       type: "numeric",
//       text: "Value must be up to 10",
//       minValue: 0,
//       maxValue: 10,
//     },
//   ],
// };

// const s6q30 = {
//   type: "radiogroup",
//   name: "q1s6q30",
//   title: {
//     default:
//       "Have you changed floor in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε αλλάξει κάλλυμα πατώματος στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Vous avez changé d'étage dans votre logement parce que votre enfant souffrait d'asthme ou de problèmes allergiques ?",
//     de: "",
//     ka: "შეცვალეთ თუ არა იატაკი თქვენს სახლში, რადგან თქვენს შვილს ჰქონდა ასთმა ან ალერგიული პრობლემები?",
//     fi: "Oletko vaihtanut lattiaa kotonasi, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q31 = {
//   type: "text",
//   name: "q1s6q31",
//   visibleIf: "{q1s6q30} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
//   validators: [
//     {
//       type: "numeric",
//       text: "Value must be up to 10",
//       minValue: 0,
//       maxValue: 10,
//     },
//   ],
// };

// const s6q32 = {
//   type: "radiogroup",
//   name: "q1s6q32",
//   title: {
//     default:
//       "Have you made any other changes in your home because your child had asthma or allergic problems?",
//     gr: "Έχετε κάνει άλλες αλλαγές στο σπίτι σας επειδή το παιδί σας είχε άσθμα ή αλλεργικά προβλήματα;",
//     fr: "Avez-vous apporté d'autres modifications à votre domicile parce que votre enfant souffrait d'asthme ou de problèmes allergiques ?",
//     de: "",
//     ka: "განახორციელეთ სხვა ცვლილებები თქვენს სახლში, რადგან თქვენს შვილს ჰქონდა ასთმა ან ალერგიული პრობლემები?",
//     fi: "Oletko tehnyt muita muutoksia kodissasi, koska lapsellasi oli astma tai allergisia ongelmia?",
//   },
//   isRequired: isNotRequired,
//   choices: [yes, no],
// };

// const s6q33 = {
//   type: "text",
//   name: "q1s6q33",
//   visibleIf: "{q1s6q32} == 'yes' ",
//   title: {
//     default: "At what age of the child?",
//     gr: "Εάν Ναι, σε ποια ηλικία του παιδιού;",
//     fr: "Si oui, à quel âge de l’enfant ?",
//     de: "",
//     ka: "„კი“-ის შემთხვევაში ბავშვის რა ასაკში?",
//     fi: "Jos kyllä, kuinka vanha lapsi oli silloin?",
//   },
//   inputType: "number",
//   min: 0,
//   isRequired: isNotRequired,
// };

// const s6q34 = {
//   type: "text",
//   name: "q1s6q34",
//   visibleIf: "{q1s6q32} == 'yes' ",
//   title: {
//     default: "Please describe:",
//     gr: "Εάν Ναι, παρακαλώ περιγράψτε:",
//     fr: "Si oui, veuillez décrire:",
//     de: "",
//     ka: "გთხოვთ დააკონკრეტოთ ცვლილება:",
//     fi: "Jos kyllä, kuvaile:",
//   },
//   maxLength: 100,
//   isRequired: isNotRequired,
// };

const s6q24 = {
  type: "matrixdropdown",
  name: "q1s6q24",
  colCount: 4,
  isRequired: isNotRequired,
  title: {
    default: "How would you describe the surroundings of your child’s home?",
    gr: "Πως θα περιγράφατε το περιβάλλον γύρω από το σπίτι που μένει/έμενε το παιδί σας;",
    fr: "Comment décririez-vous l’environnement du foyer de votre enfant ?",
    de: "",
    ka: "როგორ დაახასიათებდით იმ გარემოს, სადაც თქვენი სახლი მდებარეობს? ",
    fi: "Miten kuvailisit lapsesi kodin ympäristöä?",
  },
  columns: [
    {
      choices: [
        {
          value: "rural",
          text: {
            default: "Rural, open spaces or fields nearby",
            gr: "Αγροτικοί, ανοικτοί χώροι ή χωράφια σε κοντινή απόσταση",
            fr: "Zone rurale, espaces ouverts ou champs à proximité",
            de: "",
            ka: "სოფელი, ღია სივრცეებისა და მინდვრების სიახლოვით",
            fi: "Countryside, open spaces or fields nearby",
          },
        },
        {
          value: "suburban_many_parks",
          text: {
            default: "Suburban, with many parks or gardens",
            gr: "Προάστιο, με πολλά πάρκα ή κήπους ",
            fr: "Banlieue, avec de nombreux parcs ou jardins ",
            de: "",
            ka: "ქალაქის გარეუბანი ბევრი პარკითა და ბაღით",
            fi: "Esikaupunki, jossa on paljon puistoja tai puutarhoja",
          },
        },
        {
          value: "suburban_few_parks",
          text: {
            default: "Suburban, with few parks or gardens",
            gr: "Προάστιο, με λίγα πάρκα ή κήπους",
            fr: "Banlieue, avec peu de parcs ou de jardins",
            de: "",
            ka: "ქალაქის გარეუბანი მცირე რაოდენობით  პარკითა და ბაღით",
            fi: "Lähiö, jossa on vähän puistoja tai puutarhoja",
          },
        },
        {
          value: "urban",
          text: {
            default: "Urban with no parks or gardens",
            gr: "Αστικό χωρίς πάρκα ή κήπους",
            fr: "Urbain, sans parcs ni jardins",
            de: "",
            ka: "ქალაქი, პარკისა და ბაღის  გარეშე",
            fi: "Kaupunki, jossa ei puistoja tai puutarhoja",
          },
        },
      ],
      cellType: "radiogroup",
      isRequired: isNotRequired,
    },
  ],
  rows: [
    {
      value: "at_present",
      text: {
        default: "At Present",
        gr: "Σήμερα",
        fr: "Actuellement",
        de: "",
        ka: "ამჟამად",
        fi: "Nykyisessä",
      },
    },
    {
      value: "during_first_year_of_life",
      text: {
        default: "During the child’s first year of life",
        gr: "Τον πρώτο χρόνο της ζωής του",
        fr: "Pendant la première année de vie de l’enfant",
        de: "",
        ka: "დაბადებიდან პირველ წელს",
        fi: "Lapsen ensimmäisen elinvuoden aikana",
      },
    },
  ],
};

const s6q25 = {
  type: "radiogroup",
  name: "q1s6q25",
  title: {
    default:
      "How often do trucks pass through the street where your child lives?",
    gr: "Πόσο συχνά περνάνε φορτηγά από το δρόμο του σπιτιού που διαμένει το παιδί σας;",
    fr: "Combien de fois les camions passent-ils dans la rue où vit votre enfant ?",
    de: "",
    ka: "რამდენად ხშირად დადიან  სატვირთო მანქანები იმ ქუჩაზე, სადაც ბავშვის საცხოვრებელი სახლი მდებარეობს?",
    fi: "Kuinka usein kuorma-autot kulkevat kadulla, jossa lapsenne asuu?",
  },
  isRequired: isNotRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არასოდეს",
        fi: "Ei koskaan",
      },
    },
    {
      value: "seldom",
      text: {
        default: "Seldom",
        gr: "Σπάνια",
        fr: "Rarement",
        de: "",
        ka: "იშვიათად",
        fi: "Harvoin",
      },
    },
    {
      value: "frequently",
      text: {
        default: "Frequently through the day",
        gr: "Συχνά κατά τη διάρκεια της ημέρας",
        fr: "Fréquemment au cours de la journée",
        de: "",
        ka: "დღის მანძილზე ხშირად",
        fi: "Useasti päivässä",
      },
    },
    {
      value: "whole_day",
      text: {
        default: "Almost the whole day",
        gr: "Σχεδόν όλη την ημέρα",
        fr: "Presque toute la journée ",
        de: "",
        ka: "თითქმის მთელი დღის მანძილზე",
        fi: "Lähes koko päivän ajan",
      },
    },
  ],
};

export const elementsS6 = [
  s6q1,
  s6q2,
  s6q3,
  s6q4,
  s6q5,
  s6q6,
  s6q7,
  s6q8,
  s6q9,
  s6q10,
  s6q11,
  s6q12,
  s6q13,
  s6q14,
  s6q15,
  s6q16,
  s6q17,
  s6q18,
  s6q19,
  s6q20,
  s6q21,
  s6q22,
  s6q23,
  s6q24,
  s6q25,
];
