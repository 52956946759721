import { useEffect, useState } from "react";
import { Role } from "../types/user";
import Unauthorised from "./Unauthorised";
import { redirectPaths } from "../utils/permissions";

export const PermissionControl = ({
  permissionPath,
  role,
  route = true,
  children,
}: {
  permissionPath: Role[];
  role: Role | undefined;
  route?: boolean;
  children?: React.ReactNode;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [comp, setComp] = useState(<></>)

  useEffect(() => {
    // console.log(permissionPath);
    if (role && permissionPath.includes(role)) {
      // console.log(role);
      setShow(true);
    }else if (role){
      setComp(<Unauthorised path = {redirectPaths[role]} />);
    }
  }, []);

  return (
    // <>{show && children}</>
    <>{show ? children : <>{route && comp}</>}</>
  );
};
