import { useCallback, useState } from "react";
import { Box, Container, Grid, Tab, Tabs, ThemeProvider } from "@mui/material";
import { theme } from "../../../consts/theme";
import { Person, LockOpen } from "@mui/icons-material";
import AccountPassword from "./privacy/AccountPassword";

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: { xs: "8px", sm: "10px", lg: "15px" },
  fontSize: { xs: "15px", sm: "15px", lg: "20px" },
};

const AccountSettings = () => {
  const [type, setType] = useState<string>("privacy");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
  };

  return (
    <Container sx={styles.tabs} maxWidth={"lg"}>
      <ThemeProvider theme={theme}>
        {/* <Box sx={{ marginBottom: "30px" }}>
          <Tabs centered value={type} onChange={handleChange}>
            <Tab
              value='account'
              label={
                <Box sx={buttonStyle}>
                  <Person fontSize='medium' />
                  Account
                </Box>
              }
            />
            <Tab
              value='privacy'
              label={
                <Box sx={buttonStyle}>
                  <Person fontSize='medium' />
                  Privacy
                </Box>
              }
            />
          </Tabs>
        </Box> */}
        {(() => {
          switch (type) {
            // case "account":
            //   return <AccountDetails />;
            case "privacy":
              return <AccountPassword />;
            default:
              return <></>;
          }
        })()}
      </ThemeProvider>
    </Container>
  );
};

const styles = {
  tabs: {
    position: "relative",
    // zIndex: 20,
    // top: {
    //   xs: `calc(0px - 60px)`,
    //   sm: `calc(0px - 70px)`,
    // },
    top: `calc(0px - 30px)`,
  },
};
export default AccountSettings;
