import { Button  } from "@mui/material";
import { styled } from "@mui/material";
import variables from '../../styles/variables.module.scss';

export const StyledButton = styled(Button)(
  () => {
    return {
      backgroundColor: variables.color_primary,
      transition: "all .15s ease-in-out",
      "&:hover": {
        backgroundColor: variables.color_primary,
        fontWeight: 900,
        transform: "scale(1.10)",
        opacity: 1,
      },
    };
  }
);