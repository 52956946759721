import {
  Box,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { getIcon } from "../../../utils/getNavIcon";
import { getRole } from "../../../services/api/utils.api";
import { ROLES } from "../../../types/user";
import { tabLabelsAdmins, tabLabelsStudent } from "./tutorial.config";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TabPanels = () => {
  const [value, setValue] = React.useState(0);
  const isSmallScreen = useMediaQuery((theme: Theme) => {
    console.log("THEME", theme.breakpoints);
    return theme.breakpoints.down("sm");
  });
  const role = getRole();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabData = role === ROLES.STUDENT ? tabLabelsStudent : tabLabelsAdmins;

  return (
    <Paper elevation={1}>
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          width: "100%",
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: "divider",
            width: "20%",
            height: "300px",
            p: 2,
            alignSelf: "flex-start",
          }}
        >
          {tabData.map((tab, index) => (
            <Tab
              key={tab.label}
              label={isSmallScreen ? getIcon(tab.label) : tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {tabData.map((tab, index) => (
          <TabPanel key={tab.label} value={value} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </Paper>
  );
};

export default TabPanels;
