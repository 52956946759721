import { isRequired } from "../../consts/required";

const s3q1 = {
  type: "radiogroup",
  name: "q4s3q1",
  colCount: 2,
  title: {
    default:
      "On average, during the past week, how often were you woken by your asthma during the night?",
    gr: "Γενικά, την περασμένη εβδομάδα, ποσές φορές ξυπνούσατε από το άσθμα σας μέσα στη νύχτα; ",
    fr: "En moyenne au cours des 7 derniers jours : tu as été réveillé(e) la nuit à cause de ton asthme ?",
    de: "",
    ka: "ბოლო კვირის განმავლობაში, საშუალოდ  რამდენჯერ გაიღვიძეთ  ღამით ასთმის გამო?",
    fi: "Kuinka usein keskimäärin viimeisen viikon aikana heräsit astmaasi yöllä?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "Never",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არასოდეს",
        fi: "Ei koskaan",
      },
      value: "never",
    },
    {
      text: {
        default: "Hardly ever",
        gr: "Σχεδόν ποτέ",
        fr: "Presque jamais",
        de: "",
        ka: "თითქმის  არასოდეს",
        fi: "Tuskin koskaan",
      },
      value: "hardly_ever",
    },
    {
      text: {
        default: "A few times",
        gr: "Λίγες φορές",
        fr: "Quelques fois",
        de: "",
        ka: "იშვიათად",
        fi: "Muutaman kerran",
      },
      value: "few_times",
    },
    {
      text: {
        default: "Several times",
        gr: "Αρκετές φορές",
        fr: "Plusieurs fois",
        de: "",
        ka: "ზოგჯერ",
        fi: "Useita kertoja",
      },
      value: "several_times",
    },
    {
      text: {
        default: "Many times",
        gr: "Πολλές φορές",
        fr: "De nombreuses fois",
        de: "",
        ka: "ხშირად",
        fi: "Monta kertaa",
      },
      value: "many_times",
    },
    {
      text: {
        default: "A great many times",
        gr: "Πάρα πολλές φορές",
        fr: "De très nombreuses fois",
        de: "",
        ka: "ძალიან ხშირად ",
        fi: "Hyvin monta kertaa",
      },
      value: "great_many_times",
    },
    {
      text: {
        default: "Unable to sleep because of asthma",
        gr: "Δεν μπορούσα να κοιμηθώ λόγω του άσθματος",
        fr: "Je n’ai pas pu dormir à cause de mon asthme",
        de: "",
        ka: "ასთმის გამო ვერ ვიძინებ",
        fi: "Ei pysty nukkumaan astman takia",
      },
      value: "uanble_to_sleep",
    },
  ],
};

const s3q2 = {
  type: "radiogroup",
  name: "q4s3q2",
  colCount: 2,
  title: {
    default:
      "On average, during the past week, how bad were your asthma symptoms when you woke up in the morning?",
    gr: "Γενικά, την περασμένη εβδομάδα πόσο άσχημα ήταν τα συμπτώματα του άσθματος σας όταν ξυπνούσατε το πρωί;",
    fr: "En moyenne au cours des 7 derniers jours : comment ont été tes symptômes d’asthme le matin au réveil ?",
    de: "",
    ka: "ბოლო კვირის განმავლობაში, რამდენად მძიმე იყო ასთმის სიმპტომები დილით გაღვიძებისას?",
    fi: "Kuinka pahat astmaoireesi olivat keskimäärin kuluneen viikon aikana, kun heräsit aamulla?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "No symptoms",
        gr: "Κανένα σύμπτωμα",
        fr: "Aucun symptôme",
        de: "",
        ka: "უსიმპტომო",
        fi: "Ei oireita",
      },
      value: "no_symptoms",
    },
    {
      text: {
        default: "Very mild symptoms",
        gr: "Πολύ ελαφρά συμπτώματα",
        fr: "Symptômes très légers",
        de: "",
        ka: "ძალიან მსუბუქი სიმპტომები",
        fi: "Hyvin lievät oireet",
      },
      value: "very_mild_symptoms",
    },
    {
      text: {
        default: "Mild symptoms",
        gr: "Ελαφρά συμπτώματα",
        fr: "Symptômes légers",
        de: "",
        ka: "მსუბუქი სიმპტომები",
        fi: "Lievät oireet",
      },
      value: "mild_symptoms",
    },
    {
      text: {
        default: "Moderate symptoms",
        gr: "Μέτρια συμπτώματα",
        fr: "Symptômes modérés",
        de: "",
        ka: "ზომიერი სიმპტომები",
        fi: "Kohtalaiset oireet",
      },
      value: "moderate_symptoms",
    },
    {
      text: {
        default: "Quite severe symptoms",
        gr: "Αρκετά σοβαρά συμπτώματα",
        fr: "Symptômes assez sévères",
        de: "",
        ka: "საკმაოდ მძიმე სიმპტომები",
        fi: "Melko vakavat oireet",
      },
      value: "quite_severe_symptoms",
    },
    {
      text: {
        default: "Severe symptoms",
        gr: "Σοβαρά συμπτώματα",
        fr: "Symptômes sévères",
        de: "",
        ka: "მძიმე სიმპტომები",
        fi: "Vakavat oireet",
      },
      value: "severe_symptoms",
    },
    {
      text: {
        default: "Very severe symptoms",
        gr: "Πολύ σοβατά συμπτώματα",
        fr: "Symptômes très sévères",
        de: "",
        ka: "ძალიან მძიმე სიმპტომები",
        fi: "Erittäin vakavat oireet",
      },
      value: "very_severe_symptoms",
    },
  ],
};

const s3q3 = {
  type: "radiogroup",
  name: "q4s3q3",
  colCount: 2,
  title: {
    default:
      "In general, during the past week, how limited were you in your activities because of your asthma?",
    gr: "Γενικά την περασμένη εβδομάδα, πόσο περιορισμένες ήταν οι δραστηριότητες σας λόγω του άσθματος σας;",
    fr: "En général, au cours des 7 derniers jours : tu t’es senti(e) limité(e) dans tes activités à cause de ton asthme ?",
    de: "",
    ka: "საშუალოდ, ბოლო კვირის განმავლობაში, რამდენად შეიზღუდა თქვენი აქტიურობა ასთმის გამო?",
    fi: "Yleisesti ottaen, kuinka rajoittunut olit toiminnassa kuluneen viikon aikana astmasi vuoksi?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "Not limited at all",
        gr: "Καθόλου περιορισμένες",
        fr: "Pas limité(e) du tout",
        de: "",
        ka: "საერთოდ არ შეზღუდულა",
        fi: "En ollut rajoitettu lainkaa",
      },
      value: "not_limited",
    },
    {
      text: {
        default: "Very slightly limited",
        gr: "Πολύ λίγο περιορισμένες",
        fr: "Très peu limité(e)",
        de: "",
        ka: "ძალიან უმნიშვნელოდ",
        fi: "En ollut lähes koskaan rajoitettu",
      },
      value: "very_slightly_limited",
    },
    {
      text: {
        default: "Slightly limited",
        gr: "Λίγο περιορισμένες",
        fr: "Un peu limité(e)",
        de: "",
        ka: "უმნიშვნელოდ",
        fi: "Hieman rajoitettu",
      },
      value: "slightly_limited",
    },
    {
      text: {
        default: "Moderately limited",
        gr: "Μέτρια περιορισμένες",
        fr: "Moyennement limité(e)",
        de: "",
        ka: "ზომიერად",
        fi: "Kohtalaisen rajoitettu",
      },
      value: "moderately_limited",
    },
    {
      text: {
        default: "Very limited",
        gr: "Πολύ περιορισμένες",
        fr: "Très limité(e)",
        de: "",
        ka: "ძლიერ შეიზღუდა",
        fi: "Hyvin rajoitettu",
      },
      value: "very_limited",
    },
    {
      text: {
        default: "Extremely limited",
        gr: "Υπερβολικά περιορισμένες",
        fr: "Extrêmement limité(e)",
        de: "",
        ka: "მაქსიმალურად შეიზღუდა",
        fi: "Erittäin rajoitettu",
      },
      value: "extremely_limited",
    },
    {
      text: {
        default: "Totally limited",
        gr: "Τελείως περιορισμένες",
        fr: "Complètement limité(e)",
        de: "",
        ka: "სრულად შეიზღუდა",
        fi: "En kyennyt tekemään mitään",
      },
      value: "totally_limited",
    },
  ],
};

const s3q4 = {
  type: "radiogroup",
  name: "q4s3q4",
  colCount: 2,
  title: {
    default:
      "In general, during the past week, how much shortness of breath did you experience because of your asthma?",
    gr: "Γενικά, την περασμένη εβδομάδα πόσο λαχάνιασμα νιώσατε λόγω του άσθματος σας;",
    fr: "En général, au cours des 7 derniers jours : as-tu été essoufflé(e) à cause de ton asthme ?",
    de: "",
    ka: "საშუალოდ, ბოლო კვირის განმავლობაში, რამდენად ძლიერად  გქონდათ ქოშინი ასთმის გამო?",
    fi: "Yleisesti ottaen, kuinka paljon hengenahdistusta koit viimeisen viikon aikana astmasi vuoksi?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "None",
        gr: "Καθόλου",
        fr: "Pas essoufflé(e)",
        de: "",
        ka: "არ მქონდა",
        fi: "Ei lainkaan",
      },
      value: "none",
    },
    {
      text: {
        default: "A very little",
        gr: "Πολύ λίγο",
        fr: "Presque pas essoufflé(e)",
        de: "",
        ka: "ძალიან უმნიშვნელოდ",
        fi: "Hyvin vähän",
      },
      value: "very_little",
    },
    {
      text: {
        default: "A little",
        gr: "Λίγο",
        fr: "Un peu essoufflé(e)",
        de: "",
        ka: "უმნიშვნელოდ",
        fi: "Hieman",
      },
      value: "little",
    },
    {
      text: {
        default: "A moderate amount",
        gr: "Μέτριο",
        fr: "Moyennement essoufflé(e)",
        de: "",
        ka: "ზომიერად გამოხატული",
        fi: "Maltillinen määrä",
      },
      value: "moderate_ammount",
    },
    {
      text: {
        default: "Quite a lot",
        gr: "Αρκετό",
        fr: "Assez essoufflé(e)",
        de: "",
        ka: "საკმაოდ",
        fi: "Aika paljon",
      },
      value: "quite_a_lot",
    },
    {
      text: {
        default: "A great deal",
        gr: "Πολύ",
        fr: "Très essoufflé(e)",
        de: "",
        ka: "მკვეთრად გამოხატული",
        fi: "Paljon",
      },
      value: "great_deal",
    },
    {
      text: {
        default: "A very great deal",
        gr: "Πάρα πολύ",
        fr: "Extrêmement essoufflé(e)",
        de: "",
        ka: "ძალზე მკვეთრად გამოხატული",
        fi: "Erittäin paljon",
      },
      value: "very_great_deal",
    },
  ],
};

const s3q5 = {
  type: "radiogroup",
  name: "q4s3q5",
  colCount: 2,
  title: {
    default:
      "In general, during the past week, how much of the time did you wheeze?",
    gr: "Γενικά την περασμένη εβδομάδα, πόσο χρόνο είχατε σφύριγμα στο στήθος;",
    fr: "En général, au cours des 7 derniers jours : as-tu noté des sifflements quand tu respires ?",
    de: "",
    ka: "ბოლო კვირის განმავლობაში, საშუალოდ რამდენჯერ გქონდათ ხიხინი ასთმის გამო?",
    fi: " Yleisesti viimeisen viikon aikana, kuinka suuren osan ajasta koit hengenahdistusta",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "Not at all",
        gr: "Ποτέ",
        fr: "Jamais",
        de: "",
        ka: "არ მქონდა",
        fi: "En ollenkaan",
      },
      value: "not_at_all",
    },
    {
      text: {
        default: "Hardly any of the time",
        gr: "Σχεδόν ποτέ",
        fr: "Très rarement/presque jamais",
        de: "",
        ka: "ძალიან იშვიათად",
        fi: "Tuskin ollenkaan",
      },
      value: "hardly_any",
    },
    {
      text: {
        default: "A little of the time",
        gr: "Λίγο από το χρόνο",
        fr: "Rarement",
        de: "",
        ka: "იშვიათად",
        fi: "Vähän aikaa",
      },
      value: "little",
    },
    {
      text: {
        default: "A moderate amount of the time",
        gr: "Μέτριο από το χρόνο",
        fr: "Parfois",
        de: "",
        ka: "ზომიერი სიხშირით",
        fi: "Kohtalaisesti",
      },
      value: "moderate_ammount",
    },
    {
      text: {
        default: "A lot of the time",
        gr: "Αρκετό από το χρόνο",
        fr: "Assez souvent",
        de: "",
        ka: "ხშირად",
        fi: "Paljon",
      },
      value: "a_lot",
    },
    {
      text: {
        default: "Most of the time",
        gr: "Τον περισσότερο χρόνο",
        fr: "Presque tout le temps",
        de: "",
        ka: "კვირის უმეტესი ნაწილი",
        fi: "Suurimman osan ajasta",
      },
      value: "most",
    },
    {
      text: {
        default: "All the time",
        gr: "Συνέχεια",
        fr: "Tout le temps",
        de: "",
        ka: "უწყვეტად",
        fi: "Koko ajan",
      },
      value: "all",
    },
  ],
};

const s3q6 = {
  type: "radiogroup",
  name: "q4s3q6",
  colCount: 2,
  title: {
    default:
      "On average, during the past week, how many puffs of short-acting bronchodilator have you used each day?",
    gr: "Γενικά την περασμένη εβδομάδα πόσες εισπνοές κάνατε κάθε μέρα από το φάρμακο για γρήγορη ανακούφιση (Aerolin);",
    fr: "En moyenne au cours des 7 derniers jours : quelle a été ta dose quotidienne de bronchodilatateur b2 de courte durée d’action ?",
    de: "",
    ka: "ბოლო კვირის განმავლობაში საშუალოდ რამდენჯერ დაგჭირდათ ხანმოკლე მოქმედების ბრონქოდილატატორების გამოყენება ?",
    fi: "Kuinka monta henkäystä lyhytvaikutteista keuhkoputkia laajentavaa lääkettä olet keskimäärin käyttänyt viimeisen viikon aikana päivittäin?",
  },
  isRequired: isRequired,
  choices: [
    {
      text: {
        default: "None",
        gr: "Καμία",
        fr: "Aucune",
        de: "",
        ka: "არ დამჭირდა",
        fi: "En lainkaan",
      },
      value: "none",
    },
    {
      text: {
        default: "1–2 puffs most days",
        gr: "1–2 εισπνοές τις περισσότερες μέρες",
        fr: "1 à 2 inhalations/jour ",
        de: "",
        ka: "1–2 შესხურება დღეში",
        fi: "1–2 henkäystä useimpina päivinä",
      },
      value: "1-2",
    },
    {
      text: {
        default: "3–4 puffs most days",
        gr: "3–4 εισπνοές τις περισσότερες μέρες",
        fr: "3 à 4 inhalations/jour",
        de: "",
        ka: "3–4შესხურება დღეში",
        fi: "3–4 henkäystä useimpina päivinä	",
      },
      value: "3-4",
    },
    {
      text: {
        default: "5–8 puffs most days",
        gr: "5–8 εισπνοές τις περισσότερες μέρες",
        fr: "5 à 8 inhalations/jour",
        de: "",
        ka: "5–8 შესხურება დღეში",
        fi: "5–8 henkäystä useimpina päivinä",
      },
      value: "5-8",
    },
    {
      text: {
        default: "9–12 puffs most days",
        gr: "9–12 εισπνοές τις περισσότερες μέρες",
        fr: "9 à 12 inhalations/jour",
        de: "",
        ka: "9–12 შესხურება დღეში",
        fi: "9–12 henkäystä useimpina päivinä",
      },
      value: "9-12",
    },
    {
      text: {
        default: "13–16 puffs most days",
        gr: "13–16 εισπνοές τις περισσότερες μέρες",
        fr: "13 à 16 inhalations/jour",
        de: "",
        ka: "13–16 შესხურება დღეში",
        fi: "13–16 henkäystä useimpina päivinä",
      },
      value: "13-16",
    },
    {
      text: {
        default: "More than 16 puffs most days",
        gr: "Πάνω από  16 εισπνοές τις περισσότερες μέρες",
        fr: "Plus de 16 inhalations/jour",
        de: "",
        ka: "16-ჯერ მეტი შესხურება დღეში",
        fi: "Yli 16 henkäystä useimpina päivinä",
      },
      value: "more_than_16",
    },
  ],
};

export const elementsS3 = [s3q1, s3q2, s3q3, s3q4, s3q5, s3q6];
