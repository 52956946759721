import {
  QuestionnairePaginationRes,
  TemplateQuestionnaire,
} from "../../types/questionnaire";
import { ApiResponse } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";

export const questionsClient = () => Axios(`${getApiUrl()}`);
export const exportClient = () => Axios(`${getApiUrl()}`, false, true);

export async function fetchQuestionaires() {
  return questionsClient().get<ApiResponse<TemplateQuestionnaire[]>>(
    "/template/questionnaire/all"
  );
}

export async function fetchApiData(
  limit: number,
  page: number,
  username?: string
  // questionnaire_name = "health_questions"
) {
  const params = username ? { limit, page, username } : { limit, page };
  // console.log("P)", params);
  return questionsClient().post<ApiResponse<QuestionnairePaginationRes>>(
    "/all/questionnaires",
    params
  );
}

export async function fetchExportedData() {
  return exportClient().get<any>("/student/questionnaires/export");
}

export async function exportRequest(
  country: string,
  start_date: string,
  end_date: string
  // questionnaire_ids: string[]
) {
  return questionsClient().post<any>("/student/questionnaires/export", {
    start_date,
    end_date,
    country,
    // questionnaire_ids,
  });
}
