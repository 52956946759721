import { Container } from "@mui/material";
import React, { memo } from "react";
import ChartComp from "../Chart";
import { ChartWidget, TableWidget, isChart } from "../../types/widget";
import CustomTable from "../Table/CustomTable";
import { ITableRow } from "../../types/table";
import WidgetToolbar from "./WidgetToolbar";

const Widget = <T extends ITableRow, K extends keyof T>({
  config,
}: {
  config: TableWidget<T, K> | ChartWidget;
}) => {
  return (
    <Container maxWidth={false}>
      {isChart(config) && (
        <Container style={styles.chartContainer}>
          <WidgetToolbar config={config.toolbarConfig} />
          <ChartComp
            key={config.chartConfig.title}
            type={config.chartConfig.type}
            title={config.chartConfig.title}
            data={config.chartConfig.data}
          />
        </Container>
      )}
      {!isChart(config) && (
        // <Container maxWidth={false} >
        <div>
          <WidgetToolbar config={config.toolbarConfig} />
          <CustomTable
            rows={config.tableConfig.rows}
            columns={config.tableConfig.columns}
            toolbar={config.tableConfig.toolbar}
            disablePaginationSelect={false}
          />
        </div>
        //</Container>
      )}
    </Container>
  );
};

const styles = {
  chartContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    background: "rgb(255 255 255 / 75%)",
    borderRadius: "0.5em",
    width: "100%",
    border: "1px solid #eaeaea",
  },
};

export default Widget;
