import React from "react";
import Login from "../../components/Forms/Login";
import LoginLayout from "./LoginLayout";
import { LoginHeader } from "../../components/Header";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import styles from "../../styles/Login.module.scss";
const LoginPage = () => {
  return (
    <LoginLayout>
      <Grid
        container
        flexDirection={"row"}
        height='100vh'
        justifyContent={"space-between"}
        display={"flex"}
        style={{
          background:
            "url(https://synairg.eu/wp-content/uploads/2022/12/AdobeStock_494668734.jpg)",
          backgroundSize: "cover",
        }}
      >
        <Grid
          item
          container
          direction={"column"}
          height='100vh'
          flex={1}
          className={styles.grid}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={10}
          >
            <Typography
              variant='h4'
              bgcolor={"white"}
              p={4}
              letterSpacing={1.25}
            >
              Synair-G Dashboard
            </Typography>
          </Grid>
        </Grid>
        <Grid className={styles.expand}>
          <Login />
        </Grid>
      </Grid>
    </LoginLayout>
  );
};
export default LoginPage;
