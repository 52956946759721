import {
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Container,
  Grid,
  Box,
  Paper,
  Toolbar,
  ListItemText,
} from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { StyledFormControl, menuStyles } from "../styled/StyledFormSelect";
import { Country } from "../../types/country";
import { useAppSelector } from "../../store/hooks";
import { selectCountries } from "../../store/selectors/countries";
import styles from "../../styles/Form.module.scss";
import { TableBar } from "../styled/AppBar";
import { selectQuestionnaires } from "../../store/selectors/questionnaireSelector";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledButton } from "../styled/Button";
import { handleApiError } from "../../utils/handleApiError";
import AlertDialog from "../Alert";
import { StyledCheckbox } from "../styled/StyledCheckbox";
import { exportRequest } from "../../services/api/games.api";

const GameExportForm = ({ modal }: { modal?: () => void }) => {
  const countries = useAppSelector(selectCountries);
  const [country, setCountry] = useState<Country>(countries[0]);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().add(-1, "day"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();

  const changeCountry = (e: SelectChangeEvent) => {
    console.log(e.target.value);
    const selected = countries.find(
      (country) => country._id === e.target.value
    );
    selected && setCountry(selected);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    console.log("Submit");
    exportRequest(
      country.name,
      startDate.format("DD/MM/YYYY"),
      endDate.format("DD/MM/YYYY")
    )
      .then((response) => {
        console.log(response);
        setAlert(response.data.data);
      })
      .catch((e) => {
        handleApiError(e, setError);
      });
  };

  return (
    <Container className={styles.formContainer} maxWidth='md'>
      <Paper elevation={2} className={styles.paper} sx={{ overflow: "hidden" }}>
        <TableBar position='relative'>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>{`Export Game Scores`}</h2>
          </Toolbar>
        </TableBar>
        <Box className={styles.box}>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <Grid container rowSpacing={5} columnSpacing={3} pl={5} pr={5}>
              <Grid container item xs={12}>
                <StyledFormControl fullWidth>
                  <InputLabel id='select-country'>Country</InputLabel>
                  <Select
                    labelId='country'
                    id='country'
                    value={country._id}
                    label='Country'
                    onChange={(e) => changeCountry(e)}
                    size='small'
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country._id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Start Date'
                    format='DD/MM/YYYY'
                    defaultValue={startDate}
                    onChange={(newValue) =>
                      newValue !== null && setStartDate(newValue)
                    }
                    disableFuture
                    maxDate={endDate}
                    // onError={(newError) => setError(newError)}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: { width: "100%" },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='End Date'
                    format='DD/MM/YYYY'
                    defaultValue={endDate}
                    onChange={(newValue) =>
                      newValue !== null && setEndDate(newValue)
                    }
                    disableFuture
                    minDate={startDate}
                    // onError={(newError) => setError(newError)}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: { width: "100%" },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container item xs={12} justifyContent={"center"}>
                <StyledButton
                  className={styles.button}
                  variant='contained'
                  type='submit'
                >
                  Export
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                error && setError(undefined);
                alert && setAlert(undefined);
                modal && modal();
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default GameExportForm;
