import MapView from "../../../components/Map";
import { School, SchoolSchema } from "../../../types/school";
import gjsonAdapter from "../../../utils/gjsonAdapter";
import { Box, Container, Grid, Toolbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Feature } from "geojson";
import CustomTable from "../../../components/Table/CustomTable";
import { Column } from "../../../types/table";
import { TableBar } from "../../../components/styled/AppBar";
import { AddOne, StyledIconButton } from "../../../components/styled/AddButton";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectSchools,
  selectSchoolsLoading,
} from "../../../store/selectors/schoolSelectors";
import { getSchools } from "../../../store/effects/schoolEffects";
import Loading from "../../../components/Loading";
import {
  getActiveSchool,
  removeSchool,
} from "../../../store/actions/schoolActions";
import { deleteSchool } from "../../../services/api/schools.api";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { usePathname } from "../../../hooks/usePathname";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../../components/Alert";
import { round } from "lodash";
import { handleApiError } from "../../../utils/handleApiError";

const SchoolsPage = () => {
  const role = getRole();
  const SCHOOL_TEXT = "School";
  const [geoJsonSchools, setGeoJsonSchools] = useState<Feature[]>([]);
  const schools = useAppSelector(selectSchools);
  const isLoading = useAppSelector(selectSchoolsLoading);
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const router = useNavigate();
  const [alert, setAlert] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [rowDel, setRowDel] = useState<School | undefined>();

  console.log("Schools: ", schools);

  useEffect(() => {
    dispatch(getSchools());
  }, [dispatch]);

  useEffect(() => {
    setGeoJsonSchools(gjsonAdapter(schools));
  }, [schools]);

  const handleDelete = () => {
    console.log("rowDel", rowDel);
    if (rowDel) {
      const row = rowDel;
      console.log(row);
      deleteSchool(row)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeSchool(row.school_id!));
          }
        })
        .catch((e) => {
          handleApiError(e, setError);
        });
    }
  };

  const ProcessRows = (data: School[]): SchoolSchema[] => {
    return data.map((row) => {
      const actions = [
        {
          label: "Edit",
          action: (e: React.MouseEvent<HTMLElement>) => {
            // dispatch(
            //   getActiveSchool(
            //     data.filter((school) => school.school_id === row.school_id)[0]
            //   )
            // );
            router(`${pathname}/${row.school_id}/`);
          },
          show: role ? permissions.pages.schools.update.includes(role) : false,
        },
        {
          label: "Delete",
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row.school_id);
            setRowDel(row);
            setAlert(`${row.title} will be deleted permanently.`);
          },
          show: role ? permissions.pages.schools.delete.includes(role) : false,
        },
      ];

      !actions.some((action) => action.show === true) &&
        actions.push({
          label: "No Available Actions",
          action: (e: React.MouseEvent<HTMLElement>) => {
            return;
          },
          show: true,
        });

      return {
        ...row,
        lat: round(row.lat, 6),
        lon: round(row.lon, 6),
        actions,
      };
    });
  };

  const handleClickToCreate = () => {
    router(`${pathname}/create${SCHOOL_TEXT}`);
  };

  const SchoolsTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>{`${SCHOOL_TEXT}s`}</h2>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <PermissionControl
              permissionPath={permissions.pages.schools.create}
              role={role}
              route={false}
            >
              <Tooltip title={`Create New ${SCHOOL_TEXT}`} placement='bottom'>
                <StyledIconButton
                  aria-label='add'
                  onClick={() => handleClickToCreate()}
                  edge='end'
                >
                  <AddOne />
                </StyledIconButton>
              </Tooltip>
            </PermissionControl>
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  const columns: Column<School, keyof School>[] = [
    // { name: "id", key: "_id" },
    { name: "NAME", key: "title" },
    { name: "SENSOR", key: "sensor_box" },
    { name: "COUNTRY", key: "country" },
    { name: "LAT", key: "lat" },
    { name: "LON", key: "lon" },
  ];

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth='xl'>
      <PermissionControl
        permissionPath={permissions.pages.schools.access}
        role={role}
      >
        <Grid container item spacing={1}>
          <Grid container item xs={12} md={6}>
            <Container maxWidth='xl'>
              <MapView schools={geoJsonSchools}></MapView>
            </Container>
          </Grid>
          <Grid container item xs={12} md={6}>
            <CustomTable
              rows={ProcessRows(schools)}
              columns={columns}
              toolbar={SchoolsTableToolbar}
              disablePaginationSelect
            />
          </Grid>
        </Grid>
      </PermissionControl>
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={
            error
              ? [
                  {
                    label: "Ok",
                    onClick: () => {
                      setError(undefined);
                    },
                  },
                ]
              : [
                  {
                    label: "Ok",
                    onClick: () => {
                      handleDelete();
                      setAlert(undefined);
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {
                      setAlert(undefined);
                      setRowDel(undefined);
                    },
                    outlined: true,
                  },
                ]
          }
        />
      )}
    </Container>
  );
};

export default SchoolsPage;
