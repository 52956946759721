import { IconButton, Theme, styled } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import variables from "../../styles/variables.module.scss";
import { DownloadForOffline, FileUpload, Replay } from "@mui/icons-material";

// const MaterialIcon = (props) => (
//   <i className={`material-icons ${props.className}`}>account_balance</i>
// );

export const AddOne = styled(AddCircleIcon)(() => ({
  fontSize: "38px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const AddMultiple = styled(AddCircleOutlineIcon)(() => ({
  fontSize: "35px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const StyledUplaodIcon = styled(FileUpload)(() => ({
  fontSize: "30px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const StyledDownloadIcon = styled(DownloadForOffline)(() => ({
  fontSize: "38px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(
  ({
    theme,
    background,
    shadow,
  }: StyledIconButtonProps & { theme: Theme }) => ({
    backgroundColor: background || variables.white,
    color: variables.color_primary,
    transition: variables.trans_ease_in_out,
    borderRadius: "99999px",
    borderColor: variables.primary,
    padding: "15px",
    margin: "0px",
    width: "0px",
    height: "0px",
    boxShadow: shadow === "true" ? theme.shadows[0] : theme.shadows[8],
    "&:hover": {
      transform: "scale(1.10)",
    },
  })
);

interface StyledIconButtonProps {
  background?: string;
  shadow?: string;
}
