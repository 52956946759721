import styles from "../../../styles/Survey.module.scss";
import stylesModal from "../../../styles/Form.module.scss";

import {
  Box,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Modal,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Paper,
  Toolbar,
} from "@mui/material";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectDrafts,
  selectQuestionnaires,
} from "../../../store/selectors/questionnaireSelector";
import {
  getDrafts,
  getQuestionnaires,
} from "../../../store/effects/questionnaireEffects";
import { useEffect, useState } from "react";
import { getParentStats } from "../../../services/api/pquestionnaires.api";
import { StyledButton } from "../../../components/styled/Button";
import {
  AllowAnswers,
  Draft,
  TemplateParentQuestionnaire,
} from "../../../types/questionnaire";
import { PermissionControl } from "../../../components/Permission";
import { getRole, getUsername } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";
import { questionnaires as content } from "../../../content/surveys";
import { Link, useNavigate } from "react-router-dom";
import { usePathname } from "../../../hooks/usePathname";
import { setAllowAnswers } from "../../../store/actions/questionnaireActions";
import Loading from "../../../components/Loading";
import { StyledFormControl } from "../../../components/styled/StyledFormSelect";
import { getCountries } from "../../../store/effects/countriesEffects";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import CustomSnackbar from "../../../components/Snackbar";
import { ROLES } from "../../../types/user";
import { selectStudents } from "../../../store/selectors/userSelector";
import { TableBar } from "../../../components/styled/AppBar";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../consts/theme";

type EnrichedTemplate = TemplateParentQuestionnaire & {
  frequency: number;
  dates: string[];
  open: boolean;
  draft: Draft | undefined;
  filter?: boolean;
};

const QuestionnairesPage = () => {
  const questionnaires = useAppSelector(selectQuestionnaires);
  const [isLoading, setIsLoading] = useState(false);
  const drafts = useAppSelector(selectDrafts);
  const [parentStats, setParentStats] = useState<any[]>();
  const [enrichedTemplates, setEnrichedTemplates] = useState<
    EnrichedTemplate[] | undefined
  >();
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const role = getRole();

  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const studentList = useAppSelector(selectStudents).map(
    (student) => student._id
  ) as string[];
  const username = getUsername() as string;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [isAnswerForStudent, setIsAnswerForStudent] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<EnrichedTemplate | null>(null);

  const [lan, setLan] = useState("default");
  const changeLan = (e: SelectChangeEvent) => {
    setLan(e.target.value);
  };

  // console.log("Q: ", questionnaires);
  console.log("Content Qs: ", content);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        await Promise.all([
          dispatch(getQuestionnaires()),
          dispatch(getDrafts()),
          dispatch(getCountries()),
          getParentStats().then((res) => {
            console.log("Data: ", res.data.data);
            setParentStats(res.data.data);
          }),
        ]);
      } catch (e) {
        handleApiError(e, setError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (questionnaires && parentStats) {
      const allow_answers: AllowAnswers = {};
      const enrichedTemplates: EnrichedTemplate[] = questionnaires.map((q) => {
        const id = q._id;
        const response = parentStats;
        const frequency = (response[1][id] || 0) as number;
        const dates = (response[0][id] || []) as string[];
        const filter =
          role === "student" &&
          ((id === "1v2" && "1" in response[1]) ||
            (id === "1" && !("1" in response[1])) ||
            (id === "4v2" && "4" in response[1]) ||
            (id === "4" && !("4" in response[1])));

        // console.log(drafts);
        const draft = drafts.find((draft) => draft.questionnaire_id === q._id);

        allow_answers[id] = frequency < q.answersNum;

        return {
          ...q,
          frequency,
          dates,
          open: false,
          draft: draft,
          filter,
        };
      });
      dispatch(setAllowAnswers(allow_answers));
      console.log("Enriched", enrichedTemplates);

      const filteredEnrichedTemplates = enrichedTemplates.filter(
        (et) => et.filter !== true
      );

      setEnrichedTemplates(filteredEnrichedTemplates);
    }
  }, [questionnaires, parentStats]);

  const getTranslatedText = () => {
    const messages = {
      default:
        "Important Notice: Please do not fill out any questionnaires that you have already completed by phone. Thank you!",
      fi: "Tärkeä ilmoitus: Älä täytä mitään kyselylomakkeita, jotka olet jo täyttänyt puhelimitse. Kiitos!",
      fr: "Avis important : Veuillez ne pas remplir de questionnaires que vous avez déjà complétés par téléphone. Merci !",
      ka: "მნიშვნელოვანი გაფრთხილება: გთხოვთ არ შეავსოთ რომელიმე კითხვარი, რომელიც უკვე ტელეფონით შეავსეთ. მადლობა!",
      gr: "Σημαντική ειδοποίηση: Μην συμπληρώνετε ερωτηματολόγια που έχετε ήδη συμπληρώσει τηλεφωνικά. Ευχαριστούμε!",
    };

    return messages[lan as keyof typeof messages] || messages.default;
  };

  const navigateToQuestionnaireForStudent = (
    questionnaire: EnrichedTemplate,
    student: string
  ) => {
    const path = `${pathname}/${questionnaire._id}/${student}`;
    navigate(path);
  };

  const navigateToQuestionnaire = (questionnaire: EnrichedTemplate) => {
    const path = questionnaire.draft
      ? `${pathname}/${questionnaire._id}/${username}/${questionnaire.draft._id}`
      : `${pathname}/${questionnaire._id}/${username}`;
    navigate(path);
  };

  const handleCardClick = (questionnaire: EnrichedTemplate) => {
    if (role !== ROLES.STUDENT) {
      setSelectedQuestionnaire(questionnaire);
      setIsModalOpen(true);
    } else {
      navigateToQuestionnaire(questionnaire);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Container className={styles.surveyContainer} maxWidth={false}>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Container className={stylesModal.formContainer} maxWidth='md'>
          <Paper
            elevation={2}
            className={stylesModal.paper}
            sx={{ overflow: "hidden" }}
          >
            <ThemeProvider theme={theme}>
              <TableBar position='relative'>
                <Toolbar
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{`Select Answering Option`}</h2>
                </Toolbar>
              </TableBar>
              <Box className={stylesModal.box}>
                <StyledFormControl fullWidth>
                  <RadioGroup
                    value={isAnswerForStudent ? "student" : "self"}
                    onChange={(e) =>
                      setIsAnswerForStudent(e.target.value === "student")
                    }
                  >
                    <FormControlLabel
                      value='self'
                      control={<Radio />}
                      label='Preview for Myself'
                    />
                    <FormControlLabel
                      value='student'
                      control={<Radio />}
                      label='Answer for a Student'
                    />
                  </RadioGroup>
                </StyledFormControl>

                {isAnswerForStudent && (
                  <Autocomplete
                    sx={{ marginTop: 2 }}
                    options={studentList}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => setSelectedStudent(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label='Select Student' />
                    )}
                  />
                )}
                <StyledButton
                  variant='contained'
                  sx={{ marginTop: 2 }}
                  onClick={() => {
                    if (selectedQuestionnaire) {
                      if (isAnswerForStudent && selectedStudent) {
                        navigateToQuestionnaireForStudent(
                          selectedQuestionnaire,
                          selectedStudent
                        );
                      } else {
                        navigateToQuestionnaire(selectedQuestionnaire);
                      }
                      setIsModalOpen(false);
                    }
                  }}
                >
                  Okay
                </StyledButton>
              </Box>
            </ThemeProvider>
          </Paper>
        </Container>
      </Modal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <StyledFormControl sx={{ minWidth: 200, maxWidth: 200 }}>
          <InputLabel id='select-lang'>Language</InputLabel>
          <Select
            labelId='lan'
            id='lan'
            value={lan}
            label='Language'
            onChange={(e) => changeLan(e)}
            size='small'
          >
            <MenuItem value={"default"}>English</MenuItem>
            <MenuItem value={"fi"}>Suomalainen</MenuItem>
            <MenuItem value={"fr"}>Français</MenuItem>
            <MenuItem value={"ka"}>ქართული</MenuItem>
            <MenuItem value={"gr"}>Ελληνικά</MenuItem>
          </Select>
        </StyledFormControl>
      </Box>
      <PermissionControl
        permissionPath={permissions.pages.questionnaires.access}
        role={getRole()}
      >
        <div className={styles.grid}>
          {enrichedTemplates &&
            enrichedTemplates.map((questionnaire, index) => (
              <div key={index} className={styles.card}>
                {/* <Link
                  className={
                    questionnaire.frequency >= questionnaire.answersNum
                      ? styles.disabled
                      : ""
                  }
                  to={
                    questionnaire.draft
                      ? `${pathname}/${questionnaire._id}/${questionnaire.draft._id}`
                      : `${pathname}/${questionnaire._id}`
                  }
                >
                  <h3>
                    {
                      questionnaire.title[
                        lan as keyof typeof questionnaire.title
                      ]
                    }
                    &rarr;
                  </h3>
                  <p>
                    {
                      questionnaire.description[
                        lan as keyof typeof questionnaire.title
                      ]
                    }
                  </p>
                  <img
                    src={questionnaire.preview}
                    width={300}
                    height={200}
                    alt='Preview'
                  />
                </Link> */}
                <Link
                  className={
                    questionnaire.frequency >= questionnaire.answersNum
                      ? styles.disabled
                      : ""
                  }
                  to='#'
                  onClick={(e) => {
                    e.preventDefault();
                    handleCardClick(questionnaire);
                  }}
                >
                  <h3>
                    {
                      questionnaire.title[
                        lan as keyof typeof questionnaire.title
                      ]
                    }{" "}
                    &rarr;
                  </h3>
                  <p>
                    {
                      questionnaire.description[
                        lan as keyof typeof questionnaire.title
                      ]
                    }
                  </p>
                  <img
                    src={questionnaire.preview}
                    width={300}
                    height={200}
                    alt='Preview'
                  />
                </Link>

                <PermissionControl
                  permissionPath={permissions.pages.questionnaires.create}
                  role={getRole()}
                  route={false}
                >
                  <div className={styles.history}>
                    <p>
                      Answered: {questionnaire.frequency} /
                      {questionnaire.answersNum}
                    </p>
                    {questionnaire.dates.length > 0 && (
                      <Tooltip
                        title={
                          <span>
                            {questionnaire.dates.map((d) => (
                              <p key={d}>{d}</p>
                            ))}
                          </span>
                        }
                      >
                        <StyledButton
                          className={styles.button}
                          variant='contained'
                          aria-label='history'
                          sx={{ cursor: "default" }}
                        >
                          History
                        </StyledButton>
                      </Tooltip>
                    )}
                  </div>
                  {questionnaire.draft &&
                    questionnaire.frequency < questionnaire.answersNum && (
                      <span>Continue from previous draft</span>
                    )}
                </PermissionControl>
              </div>
            ))}
        </div>
      </PermissionControl>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
      <CustomSnackbar
        message={getTranslatedText()}
        show={role === ROLES.STUDENT}
        style={{ marginTop: "-100px" }}
      />
    </Container>
  );
};

export default QuestionnairesPage;
