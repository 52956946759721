import { LOGO } from "../consts/title";
import { StyledLayoutBox } from "./styled/LayoutBox";
import styles from "../styles/Header.module.scss";

const Header = () => {
  return (
      <StyledLayoutBox />
  );
};
export default Header;

export const LoginHeader = () => {
  return (
    <header className={styles.login_header}>
      <div className={styles.logo}> 
        {LOGO}
      </div>
    </header>
  );
};
