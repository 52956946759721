import { Container, Grid } from "@mui/material";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectActiveSchool,
  selectSchools,
} from "../../../store/selectors/schoolSelectors";
import { useParams } from "react-router-dom";
import { getActiveSchool } from "../../../store/actions/schoolActions";
import SchoolForm from "../../../components/Forms/SchoolForm";

const SchoolsFormPage = () => {
  const dispatch = useAppThunkDispatch();
  const data = useAppSelector(selectSchools);
  const { id } = useParams();
  console.log(id);

  const activeSchool = data.find(
    (school) => school.school_id === id?.toString()
  );

  activeSchool && dispatch(getActiveSchool(activeSchool));

  console.log(activeSchool);

  return (
    <Container maxWidth='xl'>
      <PermissionControl
        permissionPath={permissions.pages.schools.update}
        role={getRole()}
      >
        <SchoolForm />
      </PermissionControl>
    </Container>
  );
};

export default SchoolsFormPage;
