import { ApiResponse } from "../../types/api";
import { School } from "../../types/school";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { Game, GamePaginationResult } from "../../types/games";

export const gamesClient = () => Axios(`${getApiUrl()}/game-sessions`);
export const gamesExportClient = () =>
  Axios(`${getApiUrl()}/games-export/export`);

export async function fetchGameScores(
  limit?: number,
  page?: number,
  username?: string
) {
  const params = username ? { limit, page, username } : { limit, page };

  return gamesClient().post<ApiResponse<GamePaginationResult>>("", params);
}

export async function exportRequest(
  country: string,
  start_date: string,
  end_date: string
) {
  return gamesExportClient().post<any>("", {
    start_date,
    end_date,
    country,
  });
}
