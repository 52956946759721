export const handleApiError = (
  e: any,
  setError?: (e: string) => void,
  extraMsg?: string
) => {
  console.log(e);
  let message = "Unknown System Error";
  if (e.response) {
    if (e.response.data.errors) {
      message = e.response.data.errors[0];
    } else if (e.response.data.error) {
      message = e.response.data.error;
    } else if (e.response.data.detail && e.response.data.detail[0].msg) {
      message = e.response.data.detail[0].msg;
    }
  } else if (e.name === "AxiosError") {
    message = e.message;
  }

  if (extraMsg) {
    message = message + extraMsg;
  }

  setError && setError(message);
  return message;
};
