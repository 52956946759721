import Form from "../../../components/Forms/UserForm";
import {
  selectActiveUser,
  selectStudents,
  selectUsersLoading,
} from "../../../store/selectors/userSelector";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { ROLES, Student } from "../../../types/user";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { getActiveUser } from "../../../store/actions/userActions";
import { useParams } from "react-router-dom";

const UserFormPage = () => {
  const dispatch = useAppThunkDispatch();
  const data = useAppSelector(selectStudents);
  const { id } = useParams();
  console.log(id);

  const activeUser = data.find((user) => user._id === id?.toString());
  if (activeUser && activeUser.role === undefined) {
    const enrichedStudent: Student = {
      ...activeUser,
      role: "student",
      schools: [activeUser.school!],
    };
    delete enrichedStudent.school;
    // console.log("Student: ", enrichedStudent);
    dispatch(getActiveUser(enrichedStudent));
  } else {
    activeUser && dispatch(getActiveUser(activeUser));
  }

  return (
    <PermissionControl
      permissionPath={permissions.pages.players.update}
      role={getRole()}
    >
      <Form role={ROLES.STUDENT} />
    </PermissionControl>
  );
};
export default UserFormPage;
