import { createReducer } from "@reduxjs/toolkit";
import { getCountries } from "../effects/countriesEffects";
import { Country } from "../../types/country";
import { handleApiError } from "../../utils/handleApiError";

export interface CountryState {
  countries: Country[];
  loading: boolean;
  error: string | undefined;
}

const initialState: CountryState = {
  countries: [],
  loading: false,
  error: undefined,
};

const countriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCountries.fulfilled, (state, { payload }) => {
    console.log("C red: ", payload);
    return { ...state, countries: payload, loading: false };
  });
  builder.addCase(getCountries.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getCountries.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
});

export default countriesReducer;
