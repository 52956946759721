import {
  Draft,
  ParentAnsweredQuestionnaires,
  ParentQuestionnaireAnswers,
  TemplateParentQuestionnaire,
} from "../../types/questionnaire";
import { ApiResponse } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { getHealthTags } from "../../utils/tags-functions";
import { getRole, getUsername } from "./utils.api";

export const questionnaireClient = () => Axios(`${getApiUrl()}/parent`);
export const exportClient = () => Axios(`${getApiUrl()}`, false, true);

export async function createQuestionaire(
  questionnaire: TemplateParentQuestionnaire
) {
  return questionnaireClient().post<ApiResponse<TemplateParentQuestionnaire>>(
    "/template/questionnaire/",
    questionnaire
  );
}

export async function updateQuestionaire(
  questionnaire: TemplateParentQuestionnaire
) {
  return questionnaireClient().put<ApiResponse<TemplateParentQuestionnaire>>(
    "/template/questionnaire/",
    questionnaire
  );
}

export async function fetchQuestionairesAll() {
  return questionnaireClient().get<ApiResponse<TemplateParentQuestionnaire[]>>(
    "/template/questionnaire/all"
  );
}

export async function fetchQuestionaires() {
  return questionnaireClient().get<ApiResponse<TemplateParentQuestionnaire[]>>(
    "/template/questionnaire/active"
  );
}

export async function fetchApiData() {
  return questionnaireClient().get<ApiResponse<ParentQuestionnaireAnswers[]>>(
    "/questionnaire/answers/all"
  );
}

export async function exportData(country: string) {
  return exportClient().get<any>("/parent/questionnaire/export", {
    params: { country },
  });
}

export async function postParentAnswers(answers: ParentQuestionnaireAnswers) {
  console.log("answers: ", answers);
  return questionnaireClient().post<ApiResponse<ParentQuestionnaireAnswers>>(
    "/questionnaire/answers",
    answers
  );
}

// function updateUser(payload only, not username)

export async function getParentStats() {
  return questionnaireClient().get<ApiResponse<Array<any>>>(
    "/questionnaire/stats"
  );
}

export async function getParentDrafts() {
  return questionnaireClient().get<ApiResponse<Draft[]>>(
    "/questionnaire/draft"
  );
}

export async function updateParentAnswers(answers: ParentQuestionnaireAnswers) {
  console.log("answers: ", answers);
  return questionnaireClient().put<ApiResponse<ParentQuestionnaireAnswers>>(
    "/questionnaire/answers",
    answers
  );
}

export async function getParentAnswers() {
  return questionnaireClient().get<ApiResponse<ParentAnsweredQuestionnaires[]>>(
    "/questionnaire/answers/all"
  );
}

export async function exportRequest(
  template_ids: string[],
  country: string,
  start_date: string,
  end_date: string
) {
  return questionnaireClient().post<any>("/questionnaire/export", {
    start_date,
    end_date,
    country,
    template_ids,
  });
}
