import { USER_ACTIONS } from "./../actions/userActions";
import { Admin, Operator, Student, Teacher, User } from "../../types/user";
import { getProfileDetails, getUsers } from "../effects/userEffects";
import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../types/redux";
import { handleApiError } from "../../utils/handleApiError";

export interface UserState {
  users: User[];
  students: Student[];
  caseStudents: Student[];
  activeUser: User | null;
  profile: string;
  profileDetails: User | null;
  loading: boolean;
  error: string | undefined;
}

const initialState: UserState = {
  users: [],
  students: [],
  caseStudents: [],
  activeUser: null,
  profile: "",
  profileDetails: null,
  loading: false,
  error: undefined,
};

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    USER_ACTIONS.GET_ACTIVE_USER,
    (state, action: ActionWithPayload<User>) => {
      // console.log(action.payload);
      console.log(action.type);

      return { ...state, activeUser: action.payload };
    }
  );
  builder.addCase(
    USER_ACTIONS.REMOVE_USER,
    (state, action: ActionWithPayload<string>) => {
      console.log(
        "REM:",
        state.users.filter((user) => user._id !== action.payload)
      );
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
        students: state.students.filter((user) => user._id !== action.payload),
      };
    }
  );
  builder.addCase(
    USER_ACTIONS.GET_PROFILE,
    (state, action: ActionWithPayload<string>) => {
      return { ...state, profile: action.payload };
    }
  );
  builder.addCase(getProfileDetails.fulfilled, (state, { payload }) => {
    console.log("HERE ", payload);
    return { ...state, profileDetails: payload, loading: false };
  });
  builder.addCase(getProfileDetails.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getProfileDetails.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(getUsers.fulfilled, (state, { payload }) => {
    const users: User[] = [];
    const students: Student[] = [];
    const asthmatic: Student[] = [];

    payload.admins.map((admin: Admin) =>
      users.push({ ...admin, role: "admin" })
    );
    payload.operators.map((operator: Operator) =>
      users.push({ ...operator, role: "operator" })
    );
    payload.teachers.map((teacher: Teacher) =>
      users.push({ ...teacher, role: "teacher" })
    );
    payload.students.map((student: Student) => {
      users.push({ ...student, role: "student" });
      students.push({ ...student, role: "student" });
    });

    students.map((student) => {
      if (student.tags?.includes("case")) {
        asthmatic.push(student);
      }
    });

    // console.log("Asthmatic: ", asthmatic);
    // console.log("Reducer:", payload);
    // console.log("Reducer users:", users);
    // console.log("Reducer students:", students);
    return {
      ...state,
      users: users,
      students: students,
      caseStudents: asthmatic,
      loading: false,
    };
  });
  builder.addCase(getUsers.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getUsers.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  // builder.addCase(getStudents.fulfilled, (state, { payload }) => {
  //   return { ...state, students: payload };
  // });
});

export default userReducer;
