import { AllowAnswers, TemplateParentQuestionnaire } from '../../types/questionnaire';
import { createAction } from "@reduxjs/toolkit";

export const QUESTIONNAIRES_PREFIX = "questionnaires";

export const QUESTIONNAIRE_ACTIONS = {
  GET_QUESTIONNAIRES: `${QUESTIONNAIRES_PREFIX}/GET_QUESTIONNAIRES`,
  GET_ACTIVE_QUESTIONNAIRE: `${QUESTIONNAIRES_PREFIX}/GET_ACTIVE_QUESTIONNAIRE`,
  GET_DRAFTS: `${QUESTIONNAIRES_PREFIX}/GET_DRAFTS`,
  SET_ALLOW_ANSWERS: `${QUESTIONNAIRES_PREFIX}/SET_ALLOW_ANSWERS`,
} as const;

export const getActiveQuestionnaire = createAction(
  QUESTIONNAIRE_ACTIONS.GET_ACTIVE_QUESTIONNAIRE,
  (questionnaire: TemplateParentQuestionnaire | null) : { payload: TemplateParentQuestionnaire | null } => {
    // console.log(questionnaire);
    return { payload: questionnaire };
  }
);

export const setAllowAnswers = createAction(
  QUESTIONNAIRE_ACTIONS.SET_ALLOW_ANSWERS,
  (allow: AllowAnswers) : { payload: AllowAnswers} => {
    // console.log(allow);
    return { payload: allow };
  }
);
