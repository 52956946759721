import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { dontknow, no, yes } from "../../consts/yes_no";

// const s1q1 = {
//   type: "multipletext",
//   name: "q1s1q1",
//   title: {
//     default: "Child's Information",
//     gr: "Πληροφορίες παιδιού",
//     fr: "Informations sur l'enfant",
//     de: "",
//     ka: "ბავშვის ინფორმაცია",
//     fi: "Lapsen tiedot",
//   },
//   isRequired: isRequired,
//   items: [
//     {
//       name: "child_age",
//       title: {
//         default: "Age",
//         gr: "Ηλικία",
//         fr: "Âge",
//         de: "",
//         ka: "ასაკი",
//         fi: "Ikä",
//       },
//       isRequired: isRequired,
//       inputType: "number",
//       validators: [
//         {
//           type: "numeric",
//           text: "Value must be within the range of 5 to 12",
//           minValue: 5,
//           maxValue: 12,
//         },
//       ],
//     },
//     {
//       name: "child_sex",
//       title: {
//         default: "Sex",
//         gr: "Φύλο",
//         fr: "Sexe",
//         de: "",
//         ka: "სქესი",
//         fi: "Sukupuoli",
//       },
//       isRequired: isRequired,
//       inputType: "text",
//     },
//     {
//       name: "child_school",
//       title: {
//         default: "School",
//         gr: "Σχολείο",
//         fr: "École",
//         de: "",
//         ka: "სკოლა",
//         fi: "Koulu",
//       },
//       inputType: "text",
//       isRequired: isRequired,
//     },
//     {
//       name: "child_class",
//       title: {
//         default: "Class",
//         gr: "Τάξη",
//         fr: "Classe",
//         de: "",
//         ka: "კლასი",
//         fi: "Luokka",
//       },
//       isRequired: isRequired,
//       inputType: "text",
//     },
//   ],
// };

const s1q1 = {
  type: "panel",
  name: "q1s1q1",
  title: {
    default: "Child's Information",
    gr: "Πληροφορίες παιδιού",
    fr: "Informations sur l'enfant",
    ka: "ბავშვის ინფორმაცია",
    fi: "Lapsen tiedot",
  },
  isRequired: isRequired,
  elements: [
    {
      type: "text",
      name: "child_age",
      title: {
        default: "Age",
        gr: "Ηλικία",
        fr: "Âge",
        ka: "ასაკი",
        fi: "Ikä",
      },
      isRequired: isRequired,
      // colCount: 2,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 5 to 12",
          minValue: 5,
          maxValue: 12,
        },
      ],
      inputType: "number",
    },
    {
      type: "radiogroup",
      name: "sex",
      title: {
        default: "Sex",
        gr: "Φύλλο",
        fr: "Sexe",
        de: "",
        ka: "სქესი",
        fi: "Sukupuoli",
      },
      isRequired: isRequired,
      startWithNewLine: false,
      choices: [
        {
          value: "male",
          text: {
            default: "Male",
            gr: "Άρρεν",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
        {
          value: "female",
          text: {
            default: "Female",
            gr: "Θήλυ",
            fr: "",
            de: "",
            ka: "",
            fi: "",
          },
        },
      ],
      colCount: 0,
    },
    // {
    //   type: "dropdown",
    //   name: "school",
    //   title: "School",
    //   isRequired: true,
    //   choices: ["School1", "School2", "School3"],
    // },
    // {
    //   type: "dropdown",
    //   name: "class",
    //   title: "Class",
    //   isRequired: true,
    //   choices: ["Class1", "Class2", "Class3"],
    // },
  ],
};

const s1q2 = {
  type: "radiogroup",
  name: "q1s1q2",
  title: {
    default: "Does your child have any health problems?",
    gr: "Έχει το παιδί σας προβλήματα υγείας;",
    fr: "Votre enfant a-t-il/elle des problèmes de santé ?",
    de: "",
    ka: "აქვს თუ არა თქვენს შვილს ჯანმრთელობასთან დაკავშირებული რაიმე პრობლემა?",
    fi: "Onko lapsellasi terveysongelmia?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q3 = {
  type: "tagbox",
  name: "q1s1q3",
  title: {
    default: "Which ones?",
    gr: "Εάν Ναι...",
    fr: "Lesquels parmi les suivants?",
    de: "",
    ka: "მონიშნეთ შესაბამისი პარამეტრები ქვემოთ",
    fi: "Alla tarkentavia vaihtoehtoja",
  },
  visibleIf: "{q1s1q2} == 'yes' ",
  isRequired: isRequired,
  choices: [
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic rhinitis/rhinoconjunctivitis/hay fever",
        gr: "Αλλεργική ρινίτιδα/ρινοεπιπεφυκίτιδα/αλλεργικό πυρετό",
        fr: "Rhinite allergique/rhino-conjonctivite/rhume des foins",
        de: "",
        ka: "ალერგიული რინიტი/რინოკონიუქტივიტი/თივის ცხელება",
        fi: "Allerginen nuha/silmän sidekalvontulehdus/heinänuha",
      },
    },
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
    },
    {
      value: "atopic_dermatitis",
      text: {
        default: "Atopic dermatitis/Eczema",
        gr: "Ατοπική Δερματίτιδα/Έκζεμα",
        fr: "Dermatite atopique",
        de: "",
        ka: "ატოპიური დერმატიტი",
        fi: "Atooppinen ihottuma",
      },
    },
    {
      value: "diabetes_melitus",
      text: {
        default: "Diabetes mellitus",
        gr: "Σακχαρώδης διαβήτης",
        fr: "Diabète",
        de: "",
        ka: "შაქრიანი დიაბეტი",
        fi: "Diabetes",
      },
    },
    {
      value: "cardiovascular_diseases",
      text: {
        default: "Cardiovascular diseases",
        gr: "Καρδιαγγειακές παθήσεις",
        fr: "Maladies cardiovasculaires",
        de: "",
        ka: "გულსისხლძარღვთა დაავადებები",
        fi: "Sydän- ja verisuonitaudit",
      },
    },
    {
      value: "chronic_lung_diseases",
      text: {
        default: "Chronic lung diseases",
        gr: "Χρόνια πνευμονολογικά νοσήματα",
        fr: "Maladie pulmonaire chronique",
        de: "",
        ka: "ფილტვის ქრონიკული დაავადებები",
        fi: "Krooniset keuhkosairaudet",
      },
    },
    {
      value: "thyroid_disease",
      text: {
        default: "Thyroid disease",
        gr: "Θυρεοειδική νόσος",
        fr: "Maladie thyroïdienne",
        de: "",
        ka: "ფარისებრი ჯირკვლის დაავადებები",
        fi: "Kilpirauhasen sairaus",
      },
    },
    {
      value: "nephrological_diseases",
      text: {
        default: "Kindney diseases",
        gr: "Παθήσεις νεφρών",
        fr: "Problèmes néphrologiques",
        de: "",
        ka: "თირკმლის დაავადებები",
        fi: "Munuaisongelmia",
      },
    },
    {
      value: "neurodevelopment_problems",
      text: {
        default: "Neurodevelopment problems",
        gr: "Νευροαναπτυξιακά προβλήματα",
        fr: "Troubles du neurodéveloppement",
        de: "",
        ka: "ნეიროგანვითარების პრობლემები",
        fi: "Neurologiset kehitysongelmat",
      },
    },
  ],
  description: selectMultiple,
};

const s1q4 = {
  type: "radiogroup",
  name: "q1s1q4",
  title: {
    default: "Does your child take any medication for any reason? ",
    gr: "Λαμβάνει το παιδί σας φαρμακευτική αγωγή για οποιοδήποτε λόγο;",
    fr: "Votre enfant prend-il/elle des médicaments pour quelque raison que ce soit ?",
    de: "",
    ka: "იღებს თუ არა  თქვენი შვილი რომელიმე  მედიკამეტს?",
    fi: "Käyttääkö lapsenne lääkkeitä jostain syystä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q5 = {
  type: "tagbox",
  name: "q1s1q5",
  isRequired: isRequired,
  showOtherItem: false,
  title: {
    default: "Which ones?",
    gr: "Εάν Ναι...",
    fr: "Lesquels parmi les suivants ?",
    de: "",
    ka: "მონიშნეთ შესაბამისი პარამეტრები ქვემოთ",
    fi: "Alla tarkentavia vaihtoehtoja",
  },
  visibleIf: "{q1s1q4} == 'yes' ",
  choices: [
    {
      value: "inhalers",
      text: {
        default: "Inhalers",
        gr: "Εισπνεόμενα",
        fr: "Inhalateurs pour la respiration",
        de: "",
        ka: "ინჰალატორები",
        fi: "Inhalaattorit",
      },
    },
    {
      value: "insulin",
      text: {
        default: "Insulin",
        gr: "Ινσουλίνη",
        fr: "Insuline",
        de: "",
        ka: "ინსულინი",
        fi: "Insuliini",
      },
    },
    {
      value: "cardiovascular_drugs",
      text: {
        default: "Cardiovascular drugs",
        gr: "Καρδιολογικά φάρμακα",
        fr: "Médicaments pour pathologies cardiaques",
        de: "",
        ka: "კარდიოვასკულარული მედიკამენტები",
        fi: "Sydän- ja verisuonilääkkeet",
      },
    },
    {
      value: "antihistamines_for_rhinitis",
      text: {
        default: "Antihistamines for rhinitis",
        gr: "Αντιισταμινικά για ρινίτιδα",
        fr: "Antihistaminiques pour la rhinite",
        de: "",
        ka: "ანტიჰისტამინები ალერგიული რინიტის სამკურნალოდ",
        fi: "Nuhan hoitoon käytettävät antihistamiinit",
      },
    },
    {
      value: "nasal_sprays",
      text: {
        default: "Nasal sprays",
        gr: "Ρινικά σπρέϊ",
        fr: "Sprays nasaux",
        de: "",
        ka: "ნაზალური სპრეი",
        fi: "Nenäsumutteet",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλα",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muita",
      },
    },
  ],
  description: selectMultiple,
};

const s1q6 = {
  type: "radiogroup",
  name: "q1s1q6",
  colCount: 4,
  title: {
    default:
      "During a normal week, how many hours does your child watch television/play video games a day?",
    gr: "Κατά τη διάρκεια μιας συνηθισμένης εβδομάδας, πόσες ώρες την ημέρα το παιδί σας παρακολουθεί τηλεόραση/παίζει ηλεκτρονικά παιχνίδια;",
    fr: "Au cours d’une semaine normale, combien d’heures votre enfant regarde-t-il/elle la télévision ou joue-t-il à des jeux vidéo par jour?",
    de: "",
    ka: "ჩვეულებრივი კვირის განმავლობაში რამდენ საათს უთმობს თქვენი შვილი ტელევიზორის ყურებას/ვიდეოთამაშებს დღეში?",
    fi: "Kuinka monta tuntia lapsesi katsoo normaalin viikon aikana televisiota / pelaa videopelejä päivässä?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "<1h",
      text: {
        default: "<1h",
        gr: "<1 ώρα",
        fr: "< 1 heure",
        de: "",
        ka: "<1 საათი",
        fi: "< 1 tunti",
      },
    },
    {
      value: "1-3h",
      text: {
        default: ">1h but < than 3h",
        gr: ">1 ώρα αλλά <λιγότερο από 3 ώρες",
        fr: "> 1 heure mais < 3 heures",
        de: "",
        ka: ">1 საათი მაგრამ< ვიდრე 3 საათი",
        fi: "1-3 tuntia",
      },
    },
    {
      value: "3-5h",
      text: {
        default: ">3h but < than 5h",
        gr: ">3 ώρες αλλά < από 5 ώρες",
        fr: ">3 heures mais < 5 heures",
        de: "",
        ka: ">3 საათი მაგრამ <ვიდრე 5 საათი",
        fi: "3-5 tuntia",
      },
    },
    {
      value: ">5h",
      text: {
        default: "≥5",
        gr: "≥5 ώρες",
        fr: "≥5 heures",
        de: "",
        ka: "≥5 საათი",
        fi: "≥ 5 tuntia",
      },
    },
  ],
};

const s1q7 = {
  type: "matrix",
  name: "q1s1q7",
  isRequired: isRequired,
  isAllRowRequired: isRequired,
  title: {
    default:
      "Mark how many hours your child did physical activity each day last week? ",
    gr: "Σημειώστε πόσες ώρες το παιδί σας ασχολήθηκε με κάποια σωματική δραστηριότητα κάθε μέρα την προηγούμενη εβδομάδα;",
    fr: "Cochez le nombre d’heures pendant lesquelles votre enfant a pratiqué une activité physique chaque jour de la semaine dernière.",
    de: "",
    ka: "მონიშნეთ თუ რამდენი საათი დაუთმო თქვენმა შვილმა ფიზიკურ აქტივობას ამ ბოლო კვირის თითოეულ დღეს?",
    fi: "Merkitse, kuinka monta tuntia lapsesi harrasti liikuntaa joka päivä viime viikolla?",
  },
  description: {
    default:
      "Like playing sports, games, dance, or any other physical activity",
    gr: "Όπως αθλήματα, παιχνίδια, χορό ή οποιαδήποτε άλλη σωματική δραστηριότητα ",
    fr: "Sport, jeux, danse ou toute autre activité physique",
    de: "",
    ka: "მაგ: სპორტი, თამაშები, ცეკვა და სხვ.",
    fi: "Kuten urheilua, pelejä, tanssia tai muuta liikuntaa",
  },
  columns: [
    {
      value: "0-1h",
      text: {
        default: "0-1h",
        gr: "0-1 ώρα",
        fr: "0-1h",
        de: "",
        ka: "0-1სთ",
        fi: "0-1h",
      },
    },

    {
      value: "1-2h",
      text: {
        default: "1-2h",
        gr: "1-2 ώρα",
        fr: "1-2h",
        de: "",
        ka: "1-2სთ",
        fi: "1-2h",
      },
    },
    {
      value: "2-3h",
      text: {
        default: "2-3h",
        gr: "2-3 ώρα",
        fr: "2-3h",
        de: "",
        ka: "2-3სთ",
        fi: "2-3h",
      },
    },
    {
      value: ">3h",
      text: {
        default: ">3h",
        gr: ">3 ώρες",
        fr: ">3h",
        de: "",
        ka: ">3სთ",
        fi: ">3h",
      },
    },
  ],
  rows: [
    {
      value: "monday",
      text: {
        default: "Monday",
        gr: "Δευτέρα",
        fr: "Lundi",
        de: "",
        ka: "ორშაბათი",
        fi: "Maanantai",
      },
    },
    {
      value: "tuesday",
      text: {
        default: "Tuesday",
        gr: "Τρίτη",
        fr: "Mardi",
        de: "",
        ka: "სამშაბათი",
        fi: "Tiistai",
      },
    },
    {
      value: "wednesday",
      text: {
        default: "Wednesday",
        gr: "Τετάρτη",
        fr: "Mercredi",
        de: "",
        ka: "ოთხშაბათი",
        fi: "Keskiviikko",
      },
    },
    {
      value: "thursday",
      text: {
        default: "Thursday",
        gr: "Πέμπτη",
        fr: "Jeudi",
        de: "",
        ka: "ხუთშაბათი",
        fi: "Torstai",
      },
    },
    {
      value: "friday",
      text: {
        default: "Friday",
        gr: "Παρασκευή",
        fr: "Vendredi",
        de: "",
        ka: "პარასკევი",
        fi: "Perjantai",
      },
    },
    {
      value: "saturday",
      text: {
        default: "Saturday",
        gr: "Σάββατο",
        fr: "Samedi",
        de: "",
        ka: "შაბათი",
        fi: "Lauantai",
      },
    },
    {
      value: "sunday",
      text: {
        default: "Sunday",
        gr: "Κυριακή",
        fr: "Dimanche",
        de: "",
        ka: "კვირა",
        fi: "Sunnuntai",
      },
    },
  ],
};

const s1q8 = {
  type: "multipletext",
  name: "q1s1q8",
  title: {
    default: "How many hours does your child you usually sleep at night?",
    gr: "Πόσες ώρες κοιμάται το παιδί σας συνήθως το βράδυ;",
    fr: "Combien d’heures votre enfant dort-il/elle habituellement la nuit?",
    de: "",
    ka: "ჩვეულებისამებრ, რამდენი საათი სძინავს თქვენს შვილს ღამით?",
    fi: "Kuinka monta tuntia lapsesi yleensä nukkuu yöllä?",
  },
  isRequired: isRequired,
  items: [
    {
      name: "bedtime",
      title: {
        default: "Time child goes to bed",
        gr: "Ώρα που το παιδί ξαπλώνει στο κρεβάτι",
        fr: "Heure à laquelle l’enfant se couche",
        de: "",
        ka: "დაძინების დრო",
        fi: "Lapsi menee nukkumaan klo",
      },
      inputType: "time",
      isRequired: isRequired,
    },
    {
      name: "wakeuptime",
      title: {
        default: "Time child wakes up",
        gr: "Ώρα που το παιδί ξυπνάει",
        fr: "Heure de réveil de l’enfant",
        de: "",
        ka: "გაღვიძების დრო",
        fi: "Lapsi herää klo",
      },
      inputType: "time",
      isRequired: isRequired,
    },
  ],
};

const s1q9 = {
  type: "matrixdropdown",
  name: "q1s1q9",
  isRequired: isRequired,
  title: {
    default: "Has your child had any Infections in the last 12 months?",
    gr: "Είχε το παιδί σας οποιαδήποτε λοίμωξη του τελευταίους 12 μήνες;",
    fr: "Votre enfant a-t-il/elle eu des infections au cours des 12 derniers mois?",
    de: "",
    ka: "ბოლო 12 თვის მანძილზე გადაიტანა თუ არა თქვენმა შვილმა რომელიმე ინფექცია?",
    fi: "Onko lapsellanne ollut infektioita viimeisten 12 kuukauden aikana?",
  },
  horizontalScroll: true,
  // columnMinWidth: "130px",
  columns: [
    {
      name: "yes_no",
      title: {
        default: "Yes/No",
        gr: "Ναι/Όχι",
        fr: "Oui/Non",
        de: "",
        ka: "კი/არა",
        fi: "Kyllä/Ei",
      },
      choices: [yes, no],
      colCount: 2,
      cellType: "radiogroup",
      isRequired: isRequired,
    },
    {
      name: "infection_times",
      title: {
        default: "Times",
        gr: "Πόσες φορές",
        fr: "Combien d’épisodes",
        de: "",
        ka: "რამდენჯერ",
        fi: "Kuinka monta kertaa",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 20,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 99 to 20",
          minValue: 1,
          maxValue: 20,
        },
      ],
    },
    {
      name: "infection_max_duration",
      title: {
        default: "Maximum Duration (days)",
        gr: "Μέγιστη διάρκεια (ημέρες)",
        fr: "Durée maximale (jours)",
        de: "",
        ka: "მაქს.ხანგრძლივობა(დღეები)",
        fi: "Pisin kesto",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 99,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 99",
          minValue: 1,
          maxValue: 99,
        },
      ],
    },
  ],
  rows: [
    {
      value: "up_resp_infections",
      text: {
        default: "Upper respiratory infections (common colds)",
        gr: "Λοιμώξεις ανώτερου αναπνευστικού (κοινά κρυολογήματα)",
        fr: "Infections des voies respiratoires supérieures (rhumes)",
        de: "",
        ka: "ზედა სასუნთქი გზების ინფექცია (გაციება)",
        fi: "Ylempien hengitysteiden infektiot (flunssat)",
      },
    },
    {
      value: "lower_resp_infections",
      text: {
        default: "Lower respiratory infections (pneumonia, bronchitis)",
        gr: "Λοιμώξεις κατώτερου αναπνευστικού (πνευμονία, βρογχίτιδα)",
        fr: "Infections des voies respiratoires inférieures (pneumopathie, bronchite)",
        de: "",
        ka: "ქვედა სასუნთქი გზების ინფექცია (პნევმონია, ბრონქიტი, გულმკერდის სხვა ინფექციები)",
        fi: "Alempien hengitysteiden infektiot (keuhkokuume, keuhkoputkentulehdus).",
      },
    },
    {
      value: "otitis",
      text: {
        default: "Otitis",
        gr: "Ωτίτιδα",
        fr: "Otite",
        de: "",
        ka: "ყურის ინფექცია",
        fi: "Korvatulehdus",
      },
    },
    {
      value: "gastroenteritis",
      text: {
        default: "Gastroenteritis (diarrhea/vomiting)",
        gr: "Γαστρεντερίτιδα (Διάρροια/Εμετός) ",
        fr: "Gastro-entérite (diarrhée/vomissements)",
        de: "",
        ka: "გასტროენტერიტი (დიარეა/ღებინება)",
        fi: "Gastroenteriitti (ripuli/oksentelu)",
      },
    },
    {
      value: "coronavirus",
      text: {
        default: "Coronavirus infection (COVID-19)",
        gr: "Λοίμωξη από κοροναϊό (COVID-19)",
        fr: "Infection à COVID-19",
        de: "",
        ka: "Covid ინფექცია",
        fi: "Koronavirus",
      },
    },
    {
      value: "staphylococcus",
      text: {
        default: "Staphylococcus diagnosed by nasal swab",
        gr: "Σταφυλόκοκκος που διαγνώστηκε με εξέταση από τη μύτη (ρινικό επίχρισμα)",
        fr: "Staphylocoque diagnostiqué par prélèvement nasal",
        de: "",
        ka: "სტაფილოკოკი, რომელიც დიაგნოზირებულია ცხვირის ნაცხის საშუალებით",
        fi: "Nenäpuikolla diagnosoitu stafylokokki",
      },
    },
    {
      value: "courses_of_antibiotics",
      text: {
        default: "Courses of antibiotics",
        gr: "Αντιβιοτική αγωγή",
        fr: "Cures d’antibiotiques",
        de: "",
        ka: "ანტიბიოტიკებით მკურნალობის კურსი",
        fi: "Antibioottikuurit",
      },
    },
  ],
};

const s1q10 = {
  type: "matrixdropdown",
  name: "q1s1q10",
  isRequired: isRequired,
  title: {
    default: "Write down the corresponding immunizations",
    gr: "Σημειώστε τους αντίστοιχους εμβολιασμούς",
    fr: "Indiquez les vaccinations réalisées",
    de: "",
    ka: "აღნიშნეთ ჩატარებული იმუნიზაცია",
    fi: "Kirjoita tiedot rokotuksista",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    {
      name: "yes_no",
      title: {
        default: "Yes/No?",
        gr: "Ναι/Όχι?",
        fr: "Oui/Non?",
        de: "",
        ka: "კი/არა?",
        fi: "Kyllä/Ei?",
      },
      choices: [yes, no],
      colCount: 2,
      cellType: "radiogroup",
      isRequired: isRequired,
    },
    {
      name: "doses",
      title: {
        default: "Doses",
        gr: "Δόσεις",
        fr: "Combien de doses",
        de: "",
        ka: "დოზები",
        fi: "Annokset",
      },
      cellType: "text",
      inputType: "number",
      min: 0,
      isRequired: false,
      visibleIf: "{row.yes_no} == 'yes'",
    },
  ],
  rows: [
    {
      value: "pneumonococcal",
      text: {
        default: "Pneumonococcal",
        gr: "Πνευμονιόκοκκος",
        fr: "Pneumonocoque",
        de: "",
        ka: "პნევმოკოკის საწინააღმდეგო ვაქცინა (მწარმოებლის სახელწოდება)",
        fi: "Pneumokokki",
      },
    },
    {
      value: "haemophilus_influenza",
      text: {
        default: "Haemophilus influenza",
        gr: "Αιμόφιλος ινφλουένζας",
        fr: "Haemophilus influenza",
        de: "",
        ka: "ჰემოფილუს ინფლუენცას საწინააღმდეგო ვაქცინა(მწარმოებლის სახელწოდება)",
        fi: "Haemophilus influenza",
      },
    },
    {
      value: "influenza_last_year",
      text: {
        default: "Influenza (last year)",
        gr: "Γρίπης (για το προηγούμενο έτος)",
        fr: "Grippe",
        de: "",
        ka: "ბოლო წლის გრიპის ვაქცინა",
        fi: "Influenssa viime vuonna",
      },
    },
    {
      value: "coronavirus",
      text: {
        default: "Coronavirus (COVID-19)",
        gr: "Kοροναϊός COVID-19)",
        fr: "SARS-CoV-2 (COVID-19)",
        de: "",
        ka: "კორონავირუსის საწინააღმდეგო ვაქცინა (COVID-19)",
        fi: "Koronavirus (COVID-19)",
      },
    },
  ],
};

const s1q11 = {
  type: "checkbox",
  name: "q1s1q11",
  isRequired: isRequired,
  colCount: 5,
  title: {
    default: "Does or did the child’s father suffer from:",
    gr: "Έχει ή είχε ο πατέρας του παιδιού:",
    fr: "Le père de l’enfant souffre-t-il ou a-t-il souffert de:",
    de: "",
    ka: "ბავშვის მამას თუ აღენიშნება, ან აღენიშნებოდა: ",
    fi: "Kärsiikö tai onko lapsen isä kärsinyt seuraavista:",
  },
  choices: [
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
    },
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic rhinitis",
        gr: "Αλλεργική ρινίτιδα",
        fr: "Rhinite allergique",
        de: "",
        ka: "ალერგიული რინიტი",
        fi: "Allerginen nuha",
      },
    },
    {
      value: "eczema",
      text: {
        default: "Eczema",
        gr: "Έκζεμα",
        fr: "Eczéma",
        de: "",
        ka: "ეგზემა",
        fi: "Ihottuma",
      },
    },
    {
      value: "food_allergy",
      text: {
        default: "Food allergy",
        gr: "Τροφική αλλεργία",
        fr: "Allergie alimentaire",
        de: "",
        ka: "საკვების ალერგია",
        fi: "Ruoka-allergia",
      },
    },
    dontknow,
    no,
  ],
};

const s1q12 = {
  type: "checkbox",
  name: "q1s1q12",
  colCount: 5,
  title: {
    default: "Does or did the child’s mother suffer from:",
    gr: "Έχει ή είχε η μητέρα του παιδιού:",
    fr: "La mère de l’enfant souffre-t-elle ou a-t-elle souffert de :",
    de: "",
    ka: "ბავშვის დედას თუ აღენიშნება, ან აღენიშნებოდა :",
    fi: "Kärsiikö tai onko lapsen äiti kärsinyt seuraavista:",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
    },
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic rhinitis",
        gr: "Αλλεργική ρινίτιδα",
        fr: "Rhinite allergique",
        de: "",
        ka: "ალერგიული რინიტი",
        fi: "Allerginen nuha",
      },
    },
    {
      value: "eczema",
      text: {
        default: "Eczema",
        gr: "Έκζεμα",
        fr: "Eczéma",
        de: "",
        ka: "ეგზემა",
        fi: "Ihottuma",
      },
    },
    {
      value: " food_allergy",
      text: {
        default: "Food allergy",
        gr: "Τροφική αλλεργία",
        fr: "Réaction indésirable à tout aliment",
        de: "",
        ka: "უკურეაქცია საკვების მიმართ",
        fi: "Haitallinen reaktio jollekin elintarvikkeelle",
      },
    },
    dontknow,
    no,
  ],
};

const s1q13 = {
  type: "checkbox",
  name: "q1s1q13",
  colCount: 5,
  title: {
    default:
      "If there are blood-related siblings have any of these children suffered from:",
    gr: "Αν υπάρχουν αδέρφια εξ’ αίματος, είχε κάποιο από αυτά τα παιδιά",
    fr: "S’il y a des frères et sœurs apparentés, l’un de ces enfants a-t-il souffert de:",
    de: "",
    ka: "ბავშვის გენეტიკურ დედმამიშვილებს (ასეთების არსებობის შემთხვევაში) აღენიშნებათ თუ არა :",
    fi: "Jos on verisukulaisia sisaruksia, onko joku näistä lapsista kärsinyt seuraavista:",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "asthma",
      text: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
    },
    {
      value: "allergic_rhinitis",
      text: {
        default: "Allergic rhinitis",
        gr: "Αλλεργική ρινίτιδα",
        fr: "Rhinite allergique",
        de: "",
        ka: "ალერგიული რინიტი",
        fi: "Allerginen nuha",
      },
    },
    {
      value: "eczema",
      text: {
        default: "Eczema",
        gr: "Έκζεμα",
        fr: "Eczéma",
        de: "",
        ka: "ეგზემა",
        fi: "Ihottuma",
      },
    },
    {
      value: " food_allergy",
      text: {
        default: "Food allergy",
        gr: "Τροφική αλλεργία",
        fr: "Réaction indésirable à tout aliment",
        de: "",
        ka: "უკურეაქცია საკვების მიმართ",
        fi: "Haitallinen reaktio jollekin elintarvikkeelle",
      },
    },
    dontknow,
    no,
  ],
};

const s1q14 = {
  type: "radiogroup",
  name: "q1s1q14",
  colCount: 3,
  title: {
    default: "What level of education has the child’s father received?",
    gr: "Ποιός είναι ο ανώτερος κύκλος σπουδών που έχει ολοκληρώσει ο πατέρας του παιδιού;",
    fr: "Quel est le niveau d’éducation du père de l’enfant",
    de: "",
    ka: "აღნიშნეთ ბავშვის მამის განათლება",
    fi: "Mikä koulutustaso lapsen isällä on?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "primary_school",
      text: {
        default: "Primary school",
        gr: "Πρωτοβάθμια εκπαίδευση (νηπιαγωγείο, δημοτικό)",
        fr: "École primaire",
        de: "",
        ka: "დაწყებითი",
        fi: "Peruskoulu",
      },
    },
    {
      value: "secondary_school",
      text: {
        default: "Secondary school",
        gr: "Δευτεροβάθμια εκπαίδευση (Γυμνάσιο, Λύκειο)",
        fr: "École secondaire",
        de: "",
        ka: "საშუალო სკოლის",
        fi: "Toisen asteen koulu",
      },
    },
    {
      value: "higher_education",
      text: {
        default: "Higher education",
        gr: "Τριτοβάθμια/Ανώτερη εκπαίδευση (ΑΕΙ, ΑΤΕΙ, Μεταπτυχιακό, Διδακτορικό)",
        fr: "Enseignement supérieur",
        de: "",
        ka: "უმაღლესი განათლება",
        fi: "Korkeampi koulutus",
      },
    },
  ],
};

const s1q15 = {
  type: "radiogroup",
  name: "q1s1q15",
  colCount: 3,
  title: {
    default: "What level of education has the child’s mother received?",
    gr: "Ποιός είναι ο ανώτερος κύκλος σπουδών που έχει ολοκληρώσει η μητέρα του παιδιού",
    fr: "Quel est le niveau d’éducation de la mère de l’enfant?",
    de: "",
    ka: "აღნიშნეთ ბავშვის დედის  განათლება",
    fi: "Mikä koulutustaso lapsen äidillä on?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "primary_school",
      text: {
        default: "Primary school",
        gr: "Πρωτοβάθμια εκπαίδευση (νηπιαγωγείο, δημοτικό)",
        fr: "École primaire",
        de: "",
        ka: "დაწყებითი",
        fi: "Peruskoulu",
      },
    },
    {
      value: "secondary_school",
      text: {
        default: "Secondary school",
        gr: "Δευτεροβάθμια εκπαίδευση (Γυμνάσιο, Λύκειο)",
        fr: "École secondaire",
        de: "",
        ka: "საშუალო სკოლის",
        fi: "Toisen asteen koulu",
      },
    },
    {
      value: "higher_education",
      text: {
        default: "Higher education",
        gr: "Τριτοβάθμια/Ανώτερη εκπαίδευση (ΑΕΙ, ΑΤΕΙ, Μεταπτυχιακό, Διδακτορικό)",
        fr: "Enseignement supérieur",
        de: "",
        ka: "უმაღლესი განათლება",
        fi: "Korkeampi koulutus",
      },
    },
  ],
};

export const elementsS1 = [
  s1q1,
  s1q2,
  s1q3,
  s1q4,
  s1q5,
  s1q6,
  s1q7,
  s1q8,
  s1q9,
  s1q10,
  s1q11,
  s1q12,
  s1q13,
  s1q14,
  s1q15,
];
