import { Typography } from "@mui/material";
import Tutorial from "../../../components/turotial/Tutorial";
import { analyticsTutorialData } from "../../../consts/tutorialContent/analytics.tutorial";
import { dashboardTutorialData } from "../../../consts/tutorialContent/dashboard.tutorial";
import { playersTutorialData } from "../../../consts/tutorialContent/players.tutorial";
import {
  questionnairesTutorialData,
  questionnairesTutorialDataStudent,
} from "../../../consts/tutorialContent/questionnaires.tutorial";
import { schoolsTutorialData } from "../../../consts/tutorialContent/schools.tutorial";
import { usersTutorialData } from "../../../consts/tutorialContent/users.tutorial";
import {
  settingsTutorialData,
  settingsTutorialDataStudents,
} from "../../../consts/tutorialContent/settings.tutorial";

export const tabLabelsAdmins = [
  {
    label: "Dashboard",
    content: (
      <Tutorial
        title='Dashboard Page'
        tutorialContents={dashboardTutorialData}
      />
    ),
  },
  {
    label: "Users",
    content: (
      <Tutorial title='Users Page' tutorialContents={usersTutorialData} />
    ),
  },
  {
    label: "Players",
    content: (
      <Tutorial title='Players Page' tutorialContents={playersTutorialData} />
    ),
  },
  {
    label: "Schools",
    content: (
      <Tutorial title='School Page' tutorialContents={schoolsTutorialData} />
    ),
  },
  {
    label: "Analytics",
    content: (
      <Tutorial
        title='Analytics Page'
        tutorialContents={analyticsTutorialData}
      />
    ),
  },
  {
    label: "Questionnaires",
    content: (
      <Tutorial
        title='Questionnaires Page'
        tutorialContents={questionnairesTutorialData}
      />
    ),
  },
  {
    label: "Settings",
    content: (
      <Tutorial title='Settings Page' tutorialContents={settingsTutorialData} />
    ),
  },
];

export const tabLabelsStudent = [
  {
    label: "Questionnaires",
    content: (
      <Tutorial
        title='Questionnaires Page'
        tutorialContents={questionnairesTutorialDataStudent}
      />
    ),
  },
  {
    label: "Settings",
    content: (
      <Tutorial
        title='Settings Page'
        tutorialContents={settingsTutorialDataStudents}
      />
    ),
  },
];
