import { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell } from "../styled/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import { Action, Column, ITableRow, TableProps } from "../../types/table";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Collapse, Container, IconButton } from "@mui/material";
import { CustomTablePagination } from "../styled/TablePagination";
import styles from "../../styles/Table.module.scss";
import SelectPagination from "../styled/SelectPagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row<T extends ITableRow, K extends keyof T>(props: {
  row: T;
  columns: Column<T, K>[];
}) {
  const { row } = props;
  const { columns } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {/*  borderBottom: "unset" */}
      <TableRow sx={{ "& > *": { height: "52px" } }}>
        {columns.map((column, index) => (
          <StyledTableCell
            key={index}
            align='center'
            component='th'
            scope='row'
          >
            {row[column.key]}
          </StyledTableCell>
        ))}
        {row.actions ? (
          <StyledTableCell align='center'>
            <IconButton
              aria-label='edit button'
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup='true'
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className={styles.icon}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {row.actions.map((ac: Action, index: any) => {
                return (
                  ac.show && (
                    <MenuItem
                      key={index}
                      onClick={(e) => {
                        ac.action(e);
                        handleClose();
                      }}
                    >
                      {ac.label}
                    </MenuItem>
                  )
                );
              })}
            </Menu>
          </StyledTableCell>
        ) : row.collapsible ? (
          <></>
        ) : (
          <StyledTableCell />
        )}
        {row.collapsible && (
          <StyledTableCell align='center'>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpenCollapse(!openCollapse)}
              className={styles.icon}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </StyledTableCell>
        )}
      </TableRow>
      {row.collapsible && (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={openCollapse} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size='small' aria-label='collapsible'>
                  <TableHead>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      {row.collapsible.columns.map((column, index) => (
                        <StyledTableCell align='left' key={index}>
                          {column["name"]}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.collapsible.rows.map((r, index) => (
                      <TableRow key={index}>
                        {row.collapsible?.columns.map((column, index) => (
                          <StyledTableCell
                            key={index}
                            align={index === 0 ? "left" : "right"}
                            component='th'
                            scope='row'
                          >
                            {r[column.key]}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      )}
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
          colSpan={columns.length + 1}
        ></StyledTableCell>
      </TableRow>
    </Fragment>
  );
}

const CustomTable = <T extends ITableRow, K extends keyof T>({
  rows,
  columns,
  toolbar,
  currentPage = 1,
  rpp = 5,
  totalRows,
  handlePaginationOptions,
  disablePaginationSelect = false,
}: TableProps<T, K>): JSX.Element => {
  const rowsPerPageD = rpp;
  const rowsPerPageOptions = disablePaginationSelect ? [5] : [3, 5, 10, 25, 50];
  const [page, setPage] = useState(currentPage - 1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageD);
  const Toolbar = toolbar;
  const processedRows = totalRows
    ? rows
    : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // console.log(page, rowsPerPage, totalRows);

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log(newPage);
    handlePaginationOptions
      ? handlePaginationOptions(newPage + 1, rowsPerPage)
      : setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log("EV: ", event.target.value);
    if (event !== null) {
      handlePaginationOptions
        ? handlePaginationOptions(1, parseInt(event.target.innerText))
        : setRowsPerPage(parseInt(event.target.innerText));
    } else {
      handlePaginationOptions
        ? handlePaginationOptions(1, rowsPerPageD)
        : setRowsPerPage(rowsPerPageD);
    }
    setPage(0);
  };

  return (
    <Container className={styles.tableContainer} maxWidth={false}>
      <Paper elevation={2} sx={{ overflowX: "auto", overflowY: "hidden" }}>
        <Toolbar />
        <TableContainer
          sx={{ overflowX: "auto", overflowY: "hidden", borderRadius: "0px" }}
        >
          <Table stickyHeader aria-label='simple table'>
            <TableHead>
              <TableRow sx={{ height: "56px" }}>
                {columns.map((column, index) => (
                  <StyledTableCell align='center' key={index}>
                    {column["name"]}
                  </StyledTableCell>
                ))}
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {processedRows.map((row, index) => (
                <Row key={index} row={row} columns={columns} />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ height: "56px" }}>
                <CustomTablePagination
                  sx={{ position: "relative", zIndex: "2000" }}
                  rowsPerPageOptions={rowsPerPageOptions}
                  colSpan={
                    rows.length > 0 && rows[0].actions
                      ? columns.length + 1
                      : columns.length
                  }
                  count={totalRows ? totalRows : rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  slots={{ select: SelectPagination }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};
export default CustomTable;
