import users from "../../assets/tutorial/users/users.png";
import createUsers1 from "../../assets/tutorial/users/createUser1.png";
import createUsers2 from "../../assets/tutorial/users/createUser2.png";
import createUsers3 from "../../assets/tutorial/users/createUser3.png";

export const usersTutorialData = [
  {
    imageSrc: users,
    subtitle: "Overview",
    description: [
      "This page displays a table listing all users. In the top right corner, you'll find a button to create a new user. Each row has three dots that reveal options for editing, removing, or deleting a user.",
      "Edit: This option allows you to modify the information of an existing user.",
      "Remove: Selecting this option makes the user inaccessible, meaning the user account is deleted, but their data (such as questionnaire responses) remains in the database.",
      "Delete: This option completely removes all information associated with the user.",
    ],
  },
  {
    imageSrc: createUsers1,
    subtitle: "Create Admin",
    description: [
      "By selecting the Admin option, you can create an admin user by filling in the mandatory fields for username and password, as well as optionally providing their email and full name to grant them access to the web application.",
    ],
  },
  {
    imageSrc: createUsers2,
    subtitle: "Create Operator",
    description: [
      "The process for creating an Operator user is similar, but you must also select one or more schools to assign to them. This assignment is essential for fully utilizing the dashboard.",
    ],
  },
  {
    imageSrc: createUsers3,
    subtitle: "Create Student",
    description: [
      "Additionally, you can create a Student user by entering the necessary information.",
      "These credentials will provide students access to the mobile application and the dashboard, enabling their parents to complete the questionnaires.",
    ],
  },
];
