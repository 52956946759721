import { createReducer } from "@reduxjs/toolkit";
import {
  AllowAnswers,
  Draft,
  TemplateParentQuestionnaire,
} from "../../types/questionnaire";
import { getDrafts, getQuestionnaires } from "../effects/questionnaireEffects";
import { QUESTIONNAIRE_ACTIONS } from "../actions/questionnaireActions";
import { ActionWithPayload } from "../../types/redux";
import { handleApiError } from "../../utils/handleApiError";

export interface QuestionnaireState {
  questionnaires: TemplateParentQuestionnaire[];
  allow_answers: AllowAnswers;
  loading: boolean;
  drafts: Draft[];
  error: string | undefined;
}

const initialState: QuestionnaireState = {
  questionnaires: [],
  loading: false,
  allow_answers: {},
  drafts: [],
  error: undefined,
};

const questionnaireReducer = createReducer(initialState, (builder) => {
  builder.addCase(getQuestionnaires.fulfilled, (state, { payload }) => {
    console.log("Reducer Questionnaires: ", payload);
    return { ...state, questionnaires: payload, loading: false };
  });
  builder.addCase(getQuestionnaires.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getQuestionnaires.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(getDrafts.fulfilled, (state, { payload }) => {
    // console.log("Payload: ", payload);
    return { ...state, drafts: payload, loading: false };
  });
  builder.addCase(getDrafts.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getDrafts.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
  builder.addCase(
    QUESTIONNAIRE_ACTIONS.SET_ALLOW_ANSWERS,
    (state, action: ActionWithPayload<AllowAnswers>) => {
      // console.log("REDUCER: ", action.payload);
      return { ...state, allow_answers: action.payload };
    }
  );
});

export default questionnaireReducer;
