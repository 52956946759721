import { Card, CardContent, Container } from "@mui/material";
import styles from "../styles/Card.module.scss";

const CardComponent = ({
  title,
  content,
  suffix,
}: {
  title: string;
  content: string;
  suffix: string;
}) => {
  // console.log(content);
  return (
    <Container maxWidth={"sm"}>
      <Card className={styles.card} elevation={0}>
        <CardContent className={styles.content}>
          <h2>{title}</h2>
          <h3>{content}</h3>
          <p>{suffix}</p>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardComponent;
