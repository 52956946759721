import { Container, Grow } from "@mui/material";
import { useParams } from "react-router-dom";
import variables from "../../../styles/variables.module.scss";
import AccountSettings from "./AccountSettings";

const AccountPage = () => {
  const { profile } = useParams();

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      timeout={1000}
      unmountOnExit
    >
      <Container maxWidth={false} sx={{ paddingTop: variables.padTop }}>
        <AccountSettings />
      </Container>
    </Grow>
  );
};

export default AccountPage;
