import { REPORTING_PREFIX } from './actions/reportingActions';
import { AnyAction, CombinedState, combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer, { UserState } from "./reducers/userReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { USERS_PREFIX, USER_ACTIONS } from "./actions/userActions";
import { SCHOOLS_PREFIX } from "./actions/schoolActions";
import schoolReducer, { SchoolState } from "./reducers/schoolReducer";
import { SENSORS_PREFIX } from "./actions/sensorAcrions";
import sensorReducer, { SensorState } from "./reducers/sensorReducer";
import { UI_PREFIX } from "./actions/uiActions";
import uiReducer, { UiState } from "./reducers/uiReducer";
import reportingReducer, { ReportingState } from "./reducers/reportingReducer";
import { QUESTIONNAIRES_PREFIX } from './actions/questionnaireActions';
import questionnaireReducer, { QuestionnaireState } from './reducers/questionnaireReducer';
import { COUNTRIES_PREFIX } from './actions/countries';
import countriesReducer, { CountryState } from './reducers/countriesReducer';
import { PersistPartial } from 'redux-persist/es/persistReducer';

const rootPersistConfig = {
  key: 'root',
  storage,
}

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['loading']
}

const schoolPersistConfig = {
  key: 'school',
  storage,
  blacklist: ['loading']
}

const questionnairePersistConfig = {
  key: 'questionnaire',
  storage,
  blacklist: ['loading']
}

const countryPersistConfig = {
  key: 'countries',
  storage,
  blacklist: ['loading']
}

const appReducer = combineReducers({
  [COUNTRIES_PREFIX]: persistReducer(countryPersistConfig, countriesReducer),
  [USERS_PREFIX]: persistReducer(userPersistConfig, userReducer),
  [SCHOOLS_PREFIX]: persistReducer(schoolPersistConfig, schoolReducer),
  [QUESTIONNAIRES_PREFIX]: persistReducer(questionnairePersistConfig, questionnaireReducer),
  [SENSORS_PREFIX]: sensorReducer,
  [REPORTING_PREFIX]: reportingReducer,
  // [UI_PREFIX]: uiReducer,
})

const rootReducer = (state: CombinedState<{ countries: CountryState & PersistPartial; users: UserState & PersistPartial; schools: SchoolState & PersistPartial; questionnaires: QuestionnaireState & PersistPartial; sensors: SensorState; reporting: ReportingState; }> | undefined, action: AnyAction) => {
  if (action.type === USER_ACTIONS.RESET) {
    console.log("LOGOUT");
    // storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}


// const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  // reducer: reducers,
  reducer: rootReducer,
  middleware : [thunk] // (getDefaultMiddleware) => getDefaultMiddleware(),//.concat(myLoggingMiddleware) 
  // preloadedState,
  // devTools: process.env.NODE_ENV !== "production",
})
 
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;

// create types for state and dispatch
// export type RootState = ReturnType<typeof store.getState>;
export interface RootState {
  [COUNTRIES_PREFIX]: CountryState,
  [USERS_PREFIX]: UserState,
  [SCHOOLS_PREFIX]: SchoolState,
  [SENSORS_PREFIX] : SensorState,
  [REPORTING_PREFIX]: ReportingState,
  // [UI_PREFIX]: UiState,
  [QUESTIONNAIRES_PREFIX]: QuestionnaireState,
}

/* To check

import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
export const myLoggingMiddleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  // Here you have access to `action.type` and `action.payload`
  console.log('Logging action with type', action.type)
  
  // You should always do this at the end of your middleware
  return next(action)
}


*/