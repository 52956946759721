import { getSensors } from "./../effects/sensorEffects";
import { createReducer } from "@reduxjs/toolkit";
import { Sensor } from "../../types/sensor";
import { handleApiError } from "../../utils/handleApiError";

export interface SensorState {
  sensors: Sensor[];
  loading: boolean;
  error: string | undefined;
}

const initialState: SensorState = {
  sensors: [],
  loading: false,
  error: undefined,
};

const sensorReducer = createReducer(initialState, (builder) => {
  builder.addCase(getSensors.fulfilled, (state, { payload }) => {
    return { ...state, sensors: payload, loading: false };
  });
  builder.addCase(getSensors.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(getSensors.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
      error: handleApiError(payload),
    };
  });
});

export default sensorReducer;
