"use client";

import React from "react";
import { PermissionControl } from "../../../components/Permission";
import { getRole } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { selectAllowAnswers } from "../../../store/selectors/questionnaireSelector";
import SurveyCreator from "../../../components/surveyComponents/SurveyCreator";

const CreateQuestionnairePage = () => {
  const { id } = useParams();
  const allow = id && useAppSelector(selectAllowAnswers)[id];
  console.log("Create: ", allow, id, typeof id);

  return (
    <PermissionControl
      permissionPath={permissions.pages.questionnaires.access}
      role={getRole()}
    >
      {allow && <SurveyCreator id={id} />}
    </PermissionControl>
  );
};

export default CreateQuestionnairePage;
