import {
  Autocomplete,
  Box,
  Container,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import CustomTable from "../../../components/Table/CustomTable";
import { getRole } from "../../../services/api/utils.api";
import { TableBar } from "../../../components/styled/AppBar";
import { Column } from "../../../types/table";
import { Student } from "../../../types/user";
import { SyntheticEvent, useEffect, useState } from "react";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { ParentAnsweredQuestionnaires } from "../../../types/questionnaire";
import Loading from "../../../components/Loading";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import { selectStudents } from "../../../store/selectors/userSelector";
import { fetchGameScores } from "../../../services/api/games.api";
import ReplayIcon from "@mui/icons-material/Replay";
import { Game } from "../../../types/games";
import { processDateTime } from "../../../utils/processDateTime";
import { StyledButton } from "../../../components/styled/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ParentExportForm from "../../../components/Forms/ParentExportForm";
import GameExportForm from "../../../components/Forms/GameExportForm";

const GameAnalyticsPage = () => {
  const role = getRole();
  const GAME_TEXT = "Game Scores";
  const students = useAppSelector(selectStudents);
  const [student, setStudent] = useState<Student | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rpp, setRpp] = useState(5);
  const [totalRows, setTotalRows] = useState<number | undefined>();
  const [data, setData] = useState<Game[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  console.log("GAMES:", data);

  useEffect(() => {
    fetchData(currentPage, rpp);
  }, []);

  const handlePaginationOptions = (page: number, rpp: number) => {
    setCurrentPage(page);
    setRpp(rpp);
    console.log(page, rpp);
    fetchData(page, rpp);
  };

  const fetchData = (page: number, rpp: number, student?: string) => {
    console.log("Here ", student);
    setIsLoading(true);
    fetchGameScores(rpp, page, student)
      .then((res) => {
        console.log("GAMES:", res.data);
        setData(res.data.data.game_sessions);
        setTotalRows(res.data.data.total_count);
      })
      .catch((e) => {
        handleApiError(e, setError);
      })
      .finally(() => setIsLoading(false));
  };

  const ProcessRows = (data: Game[]): Game[] => {
    const filteredData = student
      ? data.filter((item) => item.username === student._id)
      : data;
    return filteredData.map((row) => {
      const start = processDateTime(row.start);
      const end = processDateTime(row.end);

      return { ...row, start, end };
    });
  };

  const columns: Column<Game, keyof Game>[] = [
    // { name: "ID", key: "_id" },
    { name: "USERNAME", key: "username" },
    { name: "GAME", key: "game_name" },
    { name: "SCORE", key: "score" },
    { name: "START", key: "start" },
    { name: "END", key: "end" },
  ];

  const handleStudentChange = (
    event: SyntheticEvent<Element, Event>,
    value: Student | null
  ) => {
    setStudent(value);
    if (value && (event.type === "click" || event.type === "keydown")) {
      fetchData(currentPage, rpp, value._id);
    }
  };

  const handleRevertClick = () => {
    setStudent(null);
    fetchData(currentPage, rpp);
  };

  const GamesTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "10px", md: "20px" },
              marginRight: "10px",
            }}
          >
            <h2>{GAME_TEXT}</h2>
            <Autocomplete
              size='small'
              fullWidth
              sx={styles.autocomplete}
              options={students}
              value={student}
              getOptionLabel={(option) => option._id!}
              renderOption={(props, option) => (
                <li {...props} key={option._id!}>
                  {option._id!}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label='Filter by Student' />
              )}
              onChange={handleStudentChange}
            />
            {student && (
              <Tooltip title={`Show All Students`} placement='bottom'>
                <ReplayIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleRevertClick}
                />
              </Tooltip>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            {data.length > 0 && (
              <Tooltip
                title={`Download Students' Game Scores`}
                placement='bottom'
              >
                <StyledButton
                  sx={{ marginRight: "0px" }}
                  variant='contained'
                  onClick={handleOpen}
                >
                  <DownloadIcon />
                </StyledButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Container max-width='xl'>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <div>
          <GameExportForm modal={handleClose} />
        </div>
      </Modal>
      <PermissionControl
        permissionPath={permissions.pages.analytics.access}
        role={role}
      >
        {!isLoading ? (
          <CustomTable
            toolbar={GamesTableToolbar}
            rows={ProcessRows(data)}
            columns={columns}
            currentPage={currentPage}
            rpp={rpp}
            totalRows={totalRows}
            handlePaginationOptions={handlePaginationOptions}
          />
        ) : (
          <Loading />
        )}
      </PermissionControl>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

const width = {
  xs: "150px",
  sm: "200px",
  md: "250px",
  lg: "250px",
  xl: "300px",
};

const styles = {
  autocomplete: {
    display: "flex",
    flexWrap: "nowrap",
    width: width,
    minWidth: "150px",
    maxWidth: "300px",
    "& .Mui-focused": {
      backgroundColor: "rgb(239, 239, 253)",
    },
  },
  chip: {
    maxWidth: "100px",
  },
};

export default GameAnalyticsPage;
