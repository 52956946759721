import { School } from '../../types/school';
import { createAction } from "@reduxjs/toolkit";

export const SCHOOLS_PREFIX = "schools";

export const SCHOOL_ACTIONS = {
  GET_SCHOOLS: `${SCHOOLS_PREFIX}/GET_SCHOOLS`,
  GET_ACTIVE_SCHOOL: `${SCHOOLS_PREFIX}/GET_ACTIVE_SCHOOL`,
  REMOVE_SCHOOL: `${SCHOOLS_PREFIX}/REMOVE_SCHOOL`,
} as const;

export const getActiveSchool = createAction(
  SCHOOL_ACTIONS.GET_ACTIVE_SCHOOL,
  (school: School | null) : { payload: School | null } => {
    // console.log(school);
    return { payload: school };
  }
);

export const removeSchool = createAction(
  SCHOOL_ACTIONS.REMOVE_SCHOOL,
  (school: string): { payload: string } => {
    return { payload: school };
  }
);