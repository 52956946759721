import { getToken } from "../../services/api/utils.api";
import Cookies from "js-cookie";
import { sendRefreshToken } from "../../services/auth/auth";
import { useAppThunkDispatch } from "../../store/hooks";

export const useLogout = () => {
  const logout = () => {
    const reftoken = Cookies.get("currentUserRef");

    if (reftoken) {
      sendRefreshToken(reftoken);
      Cookies.remove("currentUser");
      Cookies.remove("currentUserRef");
    }
  };

  return { logout };
};
