import students1 from "../../assets/tutorial/students/students1.png";
import students2 from "../../assets/tutorial/students/students2.png";
import createStudent from "../../assets/tutorial/students/createStudent.png";

export const playersTutorialData = [
  {
    imageSrc: students1,
    subtitle: "Overview",
    description: [
      "This page displays a table containing all the students, following a similar structure to the users page but with some additional functionality. ",
      "On the left side of the toolbar, you can search by username or Garmin ID. The Garmin ID must be an exact match, while the username can be searched using either exact or partial matches.",
      "On the right side, there are two toggles that allow you to filter students based on whether they are active or inactive and whether they are associated with a school.",
      "At the far right of the page, you'll find three buttons: Bulk Generate, Bulk Edit, and Create a Student.",
    ],
  },
  {
    imageSrc: students2,
    subtitle: "Generate Students",
    description: [
      "The Bulk Generate button opens a prompt that allows you to specify the number of students you wish to create and the school to which they will be assigned.",
      "Once you've entered this information, a CSV file will be downloaded containing their usernames and passwords.",
    ],
  },
  {
    imageSrc: createStudent,
    subtitle: "Create Student",
    description: [
      "Here, you can create a Student user by entering the necessary information.",
      "These credentials will provide students access to the mobile application and the dashboard, enabling their parents to complete the questionnaires.",
    ],
  },
];
