import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import variables from "../../styles/variables.module.scss";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import { Divider } from "@mui/material";

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  background: variables.sidenav_background,
  // color: variables.color_primary,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: variables.sidenav_background,
  // color: variables.color_primary,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  color: variables.sidenav_item_icon_color,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer)<Drawer>(
  ({ theme, open, drawerwidth }) => ({
    width: drawerwidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, drawerwidth),
      "& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

interface Drawer extends DrawerProps {
  drawerwidth: number;
}

export const StyledDivider = styled(Divider)(() => {
  return {
    background: variables.sidenav_item_icon_color,
  };
});
