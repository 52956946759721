import Upload from "../../../components/Upload";
import { PermissionControl } from "../../../components/Permission";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";

const UploadPage = () => {
  return (
    <PermissionControl
      permissionPath={permissions.pages.players.access}
      role={getRole()}
    >
      <Upload />
    </PermissionControl>
  );
};

export default UploadPage;
