import { Draft, TemplateParentQuestionnaire } from "../../types//questionnaire";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { QUESTIONNAIRE_ACTIONS } from "../actions/questionnaireActions";
import {
  fetchQuestionaires,
  fetchQuestionairesAll,
  getParentDrafts,
} from "../../services/api/pquestionnaires.api";
import { getRole } from "../../services/api/utils.api";
import { ROLES } from "../../types/user";

export const getQuestionnaires = createAsyncThunk<
  TemplateParentQuestionnaire[]
>(QUESTIONNAIRE_ACTIONS.GET_QUESTIONNAIRES, async (params, thunkAPI) => {
  try {
    const role = getRole();

    if (role !== ROLES.STUDENT) {
      const { data: responseData } = await fetchQuestionairesAll();
      return responseData.data;
    } else {
      const { data: responseData } = await fetchQuestionaires();
      return responseData.data;
    }
  } catch (error) {
    console.log("Questionnaire effects error: ", error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getDrafts = createAsyncThunk<Draft[]>(
  QUESTIONNAIRE_ACTIONS.GET_DRAFTS,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await getParentDrafts();

      return responseData.data;
    } catch (error) {
      console.log("Drafts effects error: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
