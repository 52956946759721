import { RootState } from "../store";

export const selectBarChartWidget = (state: RootState) =>
  state.reporting.barChartQuestionnairesData;
export const selectLineChartWidget = (state: RootState) =>
  state.reporting.lineChartData;
export const selectLineChartGamesWidget = (state: RootState) =>
  state.reporting.lineChartDataGames;
export const selectNoAnswersStudents = (state: RootState) =>
  state.reporting.tableNoAnswersStudentsData;
export const selectNoAnswersStudents24h = (state: RootState) =>
  state.reporting.noAnswersStudentsData24h;
export const selectBarChartWidgetQs = (state: RootState) =>
  state.reporting.barChartQuestionnairesData;
export const selectReportingError = (state: RootState) => state.reporting.error;
