import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRole, getToken } from "../services/api/utils.api";
import { redirectPaths } from "../utils/permissions";
import AuthCheck from "../AuthCheck";
import { useAppThunkDispatch } from "../store/hooks";
import { getCountries } from "../store/effects/countriesEffects";
import { getSchools } from "../store/effects/schoolEffects";
import {
  getLineChartQuestionnaires,
  getBarChartQuestionnaires,
  getNoAnswersStudentsTable,
  getNoAnswersStudents24h,
} from "../store/effects/reportingEffects";
import { getUsers } from "../store/effects/userEffects";
import Loading from "../components/Loading";
import { getQuestionnaires } from "../store/effects/questionnaireEffects";

const HomePage = () => {
  // const navigate = useNavigate();
  // const token = getToken();

  // useEffect(() => {
  //   if (token) {
  //     console.log("Auth: ", token);
  //     const role = getRole();
  //     role && navigate(redirectPaths[role]);
  //   }else{
  //     navigate('/login');
  //   }
  // }, [token]);
  const dispatch = useAppThunkDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getQuestionnaires());
    dispatch(getCountries());
    dispatch(getSchools());
    dispatch(getUsers());
    dispatch(getLineChartQuestionnaires());
    dispatch(getBarChartQuestionnaires());
    dispatch(getNoAnswersStudentsTable());
    dispatch(getNoAnswersStudents24h());

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <AuthCheck>
      <></>
    </AuthCheck>
  );
};
export default HomePage;
