import { questionnaire1 } from "./questionnaire1/questionnaire1";
import { questionnaire1_old } from "./questionnaire1_old/questionnaire1";
import { questionnaire4 } from "./questionnaire4/questionnaire4";
import { questionnaire4_old } from "./questionnaire4_old/questionnaire4";
import { questionnaire7 } from "./questionnaire7/questionnaire7";

export const questionnaires = [
  questionnaire1_old,
  questionnaire1,
  // questionnaire2,
  // questionnaire3,
  questionnaire4_old,
  questionnaire4,
  // questionnaire5,
  // questionnaire6,
  questionnaire7,
];

// export const parentTemplateClient = () => Axios(`${getApiUrl()}/parent/template/questionnaire`);

// export async function updateUser(values: any) {
//   return await parentTemplateClient().put("", values);
// }
