import { isRequired } from "../../consts/required";
import { no, yes } from "../../consts/yes_no";

const s2q1 = {
  type: "radiogroup",
  name: "q1s2q1",
  title: {
    default:
      "Has your child ever had wheezing or whistling in the chest at any time in the past?",
    gr: "Είχε ποτέ το παιδί σας στο παρελθόν «βράσιμο» ή σφύριγμα στο στήθος;",
    fr: "Votre enfant a-t-il/elle déjà eu une respiration sifflante ou des sifflements?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე ხიხინი ან სტვენა გულმკერდის არეში? ",
    fi: "Onko lapsellanne ollut joskus aiemmin hengityksen vinkumista tai rintakehän viheltelyä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s2q2 = {
  type: "radiogroup",
  name: "q1s2q2",
  visibleIf: "{q1s2q1} == 'yes' ",
  title: {
    default:
      "Has your child had wheezing or whistling in the chest in the past 12 months?",
    gr: "Είχε το παιδί σας «βράσιμο»  ή σφύριγμα στο στήθος, τους τελευταίους 12 μήνες; ",
    fr: "Votre enfant a-t-il/elle eu une respiration sifflante ou des sifflements au cours des 12 derniers mois ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ხიხინი ან სტვენა გულმკერდში ბოლო 12 თვის განმავლობაში? ",
    fi: "Onko lapsellanne ollut hengityksen vinkumista tai rintakehä viheltelyä viimeisen 12 kk aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s2q3 = {
  type: "radiogroup",
  name: "q1s2q3",
  visibleIf: "{q1s2q1} == 'yes' and {q1s2q2} == 'yes'",
  title: {
    default:
      "How many attacks of wheezing has your child had in the past 12 month?",
    gr: "Πόσα επεισόδια με «βράσιμο» ή σφύριγμα στο στήθος είχε το παιδί σας τους τελευταίους 12 μήνες;",
    fr: "Combien de crises de respiration sifflante votre enfant a-t-il/elle eues au cours des 12 derniers mois ?",
    de: "",
    ka: "ხიხინის რამდენი შეტევა ჰქონდა თქვენს ბავშვს ბოლო 12 თვის განმავლობაში?",
    fi: "Kuinka monta hengityksen vinkumiskohtausta lapsellanne on ollut viimeisen 12 kk aikana?",
  },
  isRequired: isRequired,
  choices: ["0", "1-3", "4-12", ">12"],
};

const s2q4 = {
  type: "radiogroup",
  name: "q1s2q4",
  visibleIf: "{q1s2q1} == 'yes' and {q1s2q2} == 'yes'",
  title: {
    default:
      "In the past 12 months, how often, on average, has your child’s sleep been disturbed due to wheezing?",
    gr: "Τους τελευταίους 12 μήνες, πόσο συχνά κατά μέσο όρο, ξύπνησε το παιδί σας από «βράσιμο» ή σφύριγμα στο στήθος;",
    fr: "Au cours des 12 derniers mois, à quelle fréquence, en moyenne, le sommeil de votre enfant a-t-il été perturbé en raison d’une respiration sifflante?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში საშუალოდ რა სიხშირით ჰქონდა თქვენს ბავშვს ხიხინით გამოწვეული ძილის დარღვევა?",
    fi: "Kuinka usein keskimäärin lapsenne uni on viimeisen 12 kk aikana häiriintynyt hengityksen vinkumisen vuoksi?",
  },
  isRequired: isRequired,
  choices: [
    {
      value: "never",
      text: {
        default: "Never woken with wheezing",
        gr: "Ποτέ",
        fr: "Jamais réveillé avec une respiration sifflante ",
        de: "",
        ka: "ხიხინს არასოდეს გაუღვიძებია ",
        fi: "Ei ole koskaan herännyt hengityksen vinkumiseen",
      },
    },
    {
      value: "less_than_one_pw",
      text: {
        default: "Less than one night per week",
        gr: "Λιγότερο από μία νύχτα την εβδομάδα",
        fr: "Moins d’une nuit par semaine",
        de: "",
        ka: "უფრო იშვიათად, ვიდრე კვირაში ერთხელ",
        fi: "Vähemmän kuin kerran viikossa",
      },
    },
    {
      value: "more_than_one_pw",
      text: {
        default: "One or more nights per week",
        gr: "Μια ή περισσότερες νύχτες την εβδομάδα",
        fr: "Une ou plusieurs nuits par semaine",
        de: "",
        ka: "კვირაში ერთ ღამეს ან უფრო ხშირად",
        fi: "Yhden tai useamman kerran viikossa",
      },
    },
  ],
};

const s2q5 = {
  type: "radiogroup",
  name: "q1s2q5",
  visibleIf: "{q1s2q1} == 'yes' and {q1s2q2} == 'yes'",
  title: {
    default:
      "In the past 12 months, has wheezing ever been severe enough to limit your child’s speech to only one or two words at a time between breaths?",
    gr: "Τους τελευταίους 12 μήνες, ήταν ποτέ τόσο σοβαρό το «βράσιμο» ή το σφύριγμα ώστε να δυσκολεύεται να μιλήσει;",
    fr: "Au cours des 12 derniers mois, la respiration sifflante a-t-elle déjà été suffisamment grave pour limiter l’élocution de votre enfant à un ou deux mots à la fois entre deux respirations ? ",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში ჰქონია თუ არა თქვენს ბავშვს ოდესმე იმდენად ძლიერი ხიხინი, რომ ლაპარაკში ხელი შეშლოდა და ჩასუთქვებს შორის მხოლოდ 1-2 სიტყვის თქმა შეძლებოდა?",
    fi: "Onko hengityksen vinkuminen ollut viimeisen 12 kk aikana niin voimakasta, että lapsenne puhe on rajoittunut vain yhteen tai kahteen sanaan kerrallaan hengitysten välillä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};


const s2q6 = {
  type: "radiogroup",
  name: "q1s2q6",
  title: {
    default:
      "Has your child ever had asthma?",
    gr: "Είχε ποτέ το παιδί σας άσθμα; ",
    fr: "Votre enfant a-t-il/elle déjà souffert d’asthme ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს ბავშვს ოდესმე დასმული ბრონქული ასთვის დიაგნოზი?",
    fi: "Onko lapsellanne ikinä ollut astmaa?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s2q7 = {
  type: "radiogroup",
  name: "q1s2q7",
  title: {
    default:
      "In the past 12 months, has your child’s chest sounded wheezy during or after exercise?",
    gr: "Τους τελευταίους 12 μήνες ακούστηκε «βράσιμο» ή σφύριγμα στο στήθος του παιδιού σας κατά τη διάρκεια ή μετά από άσκηση (τρέξιμο, παιχνίδι);",
    fr: "Au cours des 12 derniers mois, votre enfant a-t-il/elle eu une respiration sifflante pendant ou après un exercice physique ?",
    de: "",
    ka: "ბოლო 12 თვის განმავლობაში ჰქონია თუ არა თქვენს ბავშვს ხიხინი გულმკერდში ვარჯიშის/ფიზიკური დატვირთვის დროს ან მის შემდეგ?",
    fi: "Onko lapsenne rintakehä kuulostanut vinkuvalta liikunnan aikana tai sen jälkeen viimeisten 12 kk aikana?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};


const s2q8 = {
  type: "radiogroup",
  name: "q1s2q8",
  title: {
    default:
      "In the past 12 months, has your child had a dry cough at night, apart from a cough associated with a cold or chest infection?",
    gr: "Τους τελευταίους 12 μήνες είχε ποτέ το παιδί σας ξηρό βήχα της νύχτα, που δεν οφειλόταν σε κρυολόγημα ή λοίμωξη του αναπνευστικού; ",
    fr: "Au cours des 12 derniers mois, votre enfant a-t-il/elle eu une toux sèche la nuit, en dehors d’une toux associée à un rhume ou à une infection thoracique ?",
    de: "",
    ka: "ბოლო12 თვის განმავლობაში ჰქონია თუ არა თქვენს ბავშვს მშრალი ხველა ღამღამობით, გარდა იმ ხველისა, რომელიც გაცივებასთან ან სასუნთქი გზების ინფექციასთანაა დაკავშირებული?",
    fi: "Onko lapsellanne viimeisen 12 kk aikana ollut yöllä kuivaa yskää, lukuun ottamatta flunssaan tai rintatulehdukseen liittyvää yskää? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

export const elementsS2 = [s2q1, s2q2, s2q3, s2q4, s2q5, s2q6, s2q7, s2q8];
