import { PermissionControl } from "../../../components/Permission";
import Form from "../../../components/Forms/UserForm";
import { permissions } from "../../../utils/permissions";
import { getRole } from "../../../services/api/utils.api";
import { useAppThunkDispatch } from "../../../store/hooks";
import { getActiveUser } from "../../../store/actions/userActions";

const CreateNewUserPage = () => {
  const dispatch = useAppThunkDispatch();

  dispatch(getActiveUser(null));

  return (
    <PermissionControl
      permissionPath={permissions.pages.users.create}
      role={getRole()}
    >
      <Form />
    </PermissionControl>
  );
};

export default CreateNewUserPage;
